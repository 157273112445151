export const calculateWinStreak = ({ schedule }) => {
  const teamStreaks = {};
  const completedGames = schedule
    .filter((game) => game.awayGoalie || game.homeGoalie)
    .reverse();
  
  completedGames.forEach((game) => {
    const { homeTeam, awayTeam, homeScore, awayScore } = game;
    const homeId = homeTeam.id;
    const awayId = awayTeam.id;
    const homeWinner = homeScore > awayScore;
    if (homeWinner) {
      if (teamStreaks[homeId]) {
        if(teamStreaks[homeId].l) {
          teamStreaks[homeId].complete = true;
        }
        if (teamStreaks[homeId].w && !teamStreaks[homeId].complete) {
          teamStreaks[homeId].w += 1;
        }
      } else {
        teamStreaks[homeId] = {w: 1}
      }
      if(teamStreaks[awayId]) {
        if(teamStreaks[awayId].w) {
          teamStreaks[awayId].complete = true;
        }
        if (teamStreaks[awayId].l && !teamStreaks[awayId].complete) {
          teamStreaks[awayId].l += 1;
        }
      } else {
        teamStreaks[awayId] = {l: 1}
      }
    }
    if (!homeWinner) {
      if (teamStreaks[homeId]) {
        if(teamStreaks[homeId].w) {
          teamStreaks[homeId].complete = true;
        }
        if (teamStreaks[homeId].l && !teamStreaks[homeId].complete) {
          teamStreaks[homeId].l += 1;
        }
      } else {
        teamStreaks[homeId] = {l: 1}
      }
      if(teamStreaks[awayId]) {
        if(teamStreaks[awayId].l) {
          teamStreaks[awayId].complete = true;
        }
        if (teamStreaks[awayId].w && !teamStreaks[awayId].complete) {
          teamStreaks[awayId].w += 1;
        }
      } else {
        teamStreaks[awayId] = {w: 1}
      }
    }
  });
  return teamStreaks;
};
