import React from 'react';
import { Link } from 'react-router-dom';
import { TeamName } from 'Components';

function createData(
  id,
  seasonId,
  teamId,
  season,
  team,
  gamesPlayed,
  win,
  loss,
  tie,
  otl,
  sol,
  gaa,
  svp,
  shutouts,
  goalsAgainst,
  shotsAgainst,
  saves,
  assists,
  pim,
  league
) {
  return {
    id,
    seasonId,
    teamId,
    season,
    team,
    gamesPlayed: Number(gamesPlayed),
    win: Number(win),
    loss: Number(loss),
    tie: Number(tie),
    otl: Number(otl),
    sol: Number(sol),
    gaa: Number(gaa).toFixed(2),
    svp:
      Number(svp) === 1
        ? Number(svp).toFixed(3)
        : Number(svp).toFixed(3).slice(1),
    shutouts: Number(shutouts),
    goalsAgainst: Number(goalsAgainst),
    shotsAgainst: Number(shotsAgainst),
    saves: Number(saves),
    assists: Number(assists),
    pim: Number(pim),
    league,
  };
}

export const createGoalieStatsCareerSeasons = (goalieStats) =>
  goalieStats
    .map((stat) => {
      const {
        id,
        assists,
        gamesPlayed,
        goalsAgainst,
        loss,
        otl,
        pim,
        seasonBySeason: { id: seasonId, year, season, league },
        shotsAgainst,
        shutouts,
        sol,
        teamByTeam,
        tie,
        win,
      } = stat;
      let color, colorSecondary, teamName, teamId, champion;
      if (teamByTeam) {
        color = teamByTeam.color;
        colorSecondary = teamByTeam.colorSecondary;
        teamName = teamByTeam.name;
        teamId = teamByTeam.id;
        champion = teamByTeam.champion;
      }

      const teamLink =
        league === 'GBHPC'
          ? `/team/${seasonId}/${teamId}`
          : `/archives/team/${teamId}`;

      return createData(
        id,
        seasonId,
        teamId,
        `${year} ${season}`,
        <Link to={teamLink}>
          <TeamName
            name={teamName}
            color={color}
            colorSecondary={colorSecondary}
            champion={champion}
          />
        </Link>,
        gamesPlayed,
        win,
        loss,
        tie,
        otl,
        sol,
        goalsAgainst / gamesPlayed, // GAA
        (shotsAgainst - goalsAgainst) / shotsAgainst,
        shutouts,
        goalsAgainst,
        shotsAgainst,
        shotsAgainst - goalsAgainst,
        assists,
        pim,
        league
      );
    })
    .sort((a, b) => {
      if (a.seasonId > b.seasonId) {
        return 1;
      }
      if (a.seasonId < b.seasonId) {
        return -1;
      }
      return 0;
    });

export const createGoalieStatsCareerTotals = (goalieStats) =>
  goalieStats.reduce(
    (total, current) => {
      total.gamesPlayed += current.gamesPlayed;
      total.win += current.win;
      total.loss += current.loss;
      total.tie += current.tie || 0;
      total.otl += current.otl || 0;
      total.sol += current.sol;
      total.shutouts += current.shutouts;
      total.goalsAgainst += current.goalsAgainst;
      total.shotsAgainst += current.shotsAgainst;
      total.assists += current.assists;
      total.pim += current.pim;
      if (current.teamByTeam?.champion && current.gamesPlayed > 3) {
        total.champion.push(1);
      }
      return total;
    },
    {
      gamesPlayed: 0,
      win: 0,
      loss: 0,
      tie: 0,
      otl: 0,
      sol: 0,
      shutouts: 0,
      goalsAgainst: 0,
      shotsAgainst: 0,
      assists: 0,
      pim: 0,
      champion: [],
    }
  );
