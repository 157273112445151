import React from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  ChampionBlock,
  LoadingPage,
  PageContainer,
  ScheduleTable,
  StandingsBlock,
  Table,
} from 'Components';
import { GET_HOMEPAGE_DATA } from 'services/queries';
import {
  calculateWinStreak,
  createGoalieStatsLeague,
  createSeasonStatData,
  createStandingsData,
  CURRENT_SEASON,
  design,
  getLastWeekDate,
  getNextWeekDate,
} from 'utils';
import {
  getGoalieSingleSeasonHeadCells,
  getSkaterSingleSeasonHeadCells,
} from 'tableHeadCells';

const ScheduleLink = styled.p`
  font-size: ${design.fontSizes[1]};
  text-align: center;
`;
const SectionWrap = styled.section`
  margin-top: ${design.spacing[6]};
`;

const HomePage = () => {
  const { loading, error, data } = useQuery(GET_HOMEPAGE_DATA, {
    variables: {
      seasonId: CURRENT_SEASON,
      seasonsArr: [CURRENT_SEASON - 1, CURRENT_SEASON],
    },
  });

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const lastSeason = data.seasons[0];
  const currentSeason = data.seasons[data.seasons.length - 1];

  // Set up stat data
  const skaterData = data.teams
    .map((team) => {
      const { id: teamId, name: teamName, abbreviation, rosters } = team;

      const teamRoster = rosters.reduce(
        (total, current) => [...total, current.players[0]],
        []
      );

      return teamRoster.map((player) => {
        const {
          id,
          firstName,
          lastName,
          jersey,
          jerseySecondary,
          position,
          positionSecondary,
          attendance_aggregate: {
            aggregate: { count: gamesPlayed },
          },
          goals_aggregate: {
            aggregate: { count: goals },
          },
          primaryAssist_aggregate: {
            aggregate: { count: primaryAssists },
          },
          secondaryAssist_aggregate: {
            aggregate: { count: secondaryAssists },
          },
          penalties_aggregate: {
            aggregate: {
              sum: { minutes: pim },
            },
          },
        } = player;

        const positionString = `${position.slice(0, 1)}${
          positionSecondary?.length ? `/${positionSecondary.slice(0, 1)}` : ''
        }`;
        const assists = primaryAssists + secondaryAssists;

        return createSeasonStatData(
          {
            id,
            seasonId: CURRENT_SEASON,
            teamId,
          },
          {
            lastName,
            firstName,
            position: positionString,
            team: abbreviation || teamName.slice(0, 3).toUpperCase(),
            jersey: `${jersey ? jersey : ''}${
              jerseySecondary ? ` / ${jerseySecondary}` : ''
            }`,
            gamesPlayed: gamesPlayed || 0,
            goals: goals || 0,
            assists: assists || 0,
            points: goals + assists || 0,
            pim: pim || 0,
            gpg: gamesPlayed ? Number(goals / gamesPlayed).toFixed(2) : 0,
            apg: gamesPlayed ? Number(assists / gamesPlayed).toFixed(2) : 0,
            ppg: gamesPlayed
              ? Number((goals + assists) / gamesPlayed).toFixed(2)
              : 0,
            pimpg: gamesPlayed ? Number(pim / gamesPlayed).toFixed(2) : 0,
          }
        );
      });
    })
    .flat()
    .filter(
      (entry) =>
        !entry.position.includes('G') ||
        (entry.position.includes('G') && entry.points > 0)
    );

  const skaterRowLinks = {
    firstName: {
      page: '/player',
      param: 'id',
    },
    lastName: {
      page: '/player',
      param: 'id',
    },
    team: {
      page: '/team',
      param: ['seasonId', 'teamId'],
    },
  };

  const goalieStats = createGoalieStatsLeague(data.schedule);
  const goalieData = Object.entries(goalieStats)
    .map(([teamId, teamGoalies]) => {
      return Object.values(teamGoalies).map((goalie) => {
        const {
          firstName,
          lastName,
          jersey,
          jerseySecondary,
          gamesPlayed,
          goalsAgainst,
          id,
          losses,
          otl,
          shotsAgainst,
          shutouts,
          team,
          wins,
        } = goalie;
        const saves = shotsAgainst - goalsAgainst;
        const gaa = (goalsAgainst / gamesPlayed).toFixed(2);
        let svp = saves / shotsAgainst;
        svp = svp === 1 ? svp.toFixed(3) : svp.toFixed(3).slice(1);
        return createSeasonStatData(
          { id, seasonId: CURRENT_SEASON, teamId },
          {
            lastName,
            firstName,
            team,
            jersey: `${jersey ? jersey : ''}${
              jerseySecondary ? ` / ${jerseySecondary}` : ''
            }`,
            gamesPlayed,
            wins,
            losses,
            otl,
            gaa,
            svp,
            shutouts,
            goalsAgainst,
            shotsAgainst,
            saves,
          }
        );
      });
    })
    .flat();

  // Set up Schedule
  const gamesByWeek = {};
  const lastWeek = getLastWeekDate().toISOString();
  const showChampionAtTop = true;
  // new Date([...data.schedule][3].date).toISOString() > lastWeek;

  const upcomingGames = [...data.schedule].filter(
    (game) => new Date(game.date).toISOString() > lastWeek
  );
  const playoffGames = [...data.schedule].filter((game) => game.playoffs);
  const isPlayoffs =
    getNextWeekDate().toISOString() >
    new Date(playoffGames[0].date).toISOString();

  if (isPlayoffs) {
    playoffGames.forEach((game) => {
      if (gamesByWeek[game.date]) {
        gamesByWeek[game.date].push(game);
      } else {
        gamesByWeek[game.date] = [game];
      }
    });
  } else {
    upcomingGames.slice(0, 6).forEach((game) => {
      if (gamesByWeek[game.date]) {
        gamesByWeek[game.date].push(game);
      } else {
        gamesByWeek[game.date] = [game];
      }
    });
  }

  // Set up Standings
  const standings = createStandingsData(
    [...data.schedule].filter((game) => !game.playoffs)
  );
  const teamStreaks = calculateWinStreak({
    schedule: [...data.schedule],
  });
  const showStandings = Object.keys(standings).length > 0;

  const championsBlockProps = {
    seasonId: lastSeason.id,
    seasonName: lastSeason.season,
    showImage: true,
    teamId: lastSeason.teams[0].id,
    teamName: lastSeason.teams[0].name,
    year: lastSeason.year,
  };
  // const championsBlockProps = {
  //   seasonId: currentSeason.id,
  //   seasonName: currentSeason.season,
  //   showImage: true,
  //   teamId: currentSeason.teams[0].id,
  //   teamName: currentSeason.teams[0].name,
  //   year: currentSeason.year,
  // };

  return (
    <PageContainer>
      <h1>
        {currentSeason.year} {currentSeason.season} Season
      </h1>
      {showChampionAtTop && <ChampionBlock {...championsBlockProps} />}
      {showStandings && !isPlayoffs && (
        <SectionWrap>
          <StandingsBlock
            seasonId={CURRENT_SEASON}
            standings={standings}
            teamStreaks={teamStreaks}
          />
        </SectionWrap>
      )}
      {Object.keys(gamesByWeek).length > 0 && (
        <SectionWrap>
          {isPlayoffs ? (
            <h2>Playoff Schedule & Results</h2>
          ) : (
            <h2>Recent Scores & Upcoming Games</h2>
          )}
          <ScheduleLink>
            <Link to={`/schedule/${CURRENT_SEASON}`}>
              View Complete Schedule &raquo;
            </Link>
          </ScheduleLink>
          <ScheduleTable seasonId={CURRENT_SEASON} gamesByWeek={gamesByWeek} />
        </SectionWrap>
      )}
      {showStandings && isPlayoffs && (
        <SectionWrap>
          <StandingsBlock
            seasonId={CURRENT_SEASON}
            standings={standings}
            teamStreaks={teamStreaks}
          />
        </SectionWrap>
      )}
      {skaterData.length > 0 && (
        <SectionWrap>
          <h2>Player Leaders</h2>
          <Table
            defaultOrder="desc"
            defaultOrderBy="points"
            headCells={getSkaterSingleSeasonHeadCells({
              showJersey: true,
              showTeam: true,
            })}
            rowLinks={skaterRowLinks}
            rows={skaterData}
            size="small"
            tableTitle="Leaders"
          />
        </SectionWrap>
      )}
      {goalieData.length > 0 && (
        <SectionWrap>
          <h2>Goalie Leaders</h2>
          <Table
            defaultOrder="desc"
            defaultOrderBy="svp"
            enablePagination={false}
            enableToolbar={false}
            headCells={getGoalieSingleSeasonHeadCells({
              showJersey: true,
              showTeam: true,
            })}
            rowLinks={skaterRowLinks}
            rows={[...new Set(goalieData)]}
            size="small"
            tableTitle="Leaders"
          />
        </SectionWrap>
      )}
      {!showChampionAtTop && <ChampionBlock {...championsBlockProps} />}
    </PageContainer>
  );
};

export default HomePage;
