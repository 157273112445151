export const getWinner = ({ awayScore, homeScore }) => {
  let homeWinner = false;
  let awayWinner = false;

  if ((awayScore || awayScore === 0) && (homeScore || homeScore === 0)) {
    const homeGoalDiff = homeScore - awayScore;
    homeWinner = homeGoalDiff > 0;
    awayWinner = homeGoalDiff < 0;
  }

  return { homeWinner, awayWinner };
};
