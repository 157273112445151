import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  AdminDraftOrder,
  AdminGame,
  // AdminHome,
  AdminLogin,
  AdminPlayerExport,
  AdminRosterLanding,
  AdminRosterTeam,
  AdminSchedule,
  PageContainer,
} from 'Components';

const { REACT_APP_ADMIN_COOKIE, REACT_APP_ADMIN_TOKEN } = process.env;

const Admin = () => {
  const { path } = useRouteMatch();
  const adminCookie = Cookies.get(REACT_APP_ADMIN_COOKIE);

  if (!adminCookie || adminCookie !== REACT_APP_ADMIN_TOKEN) {
    return <AdminLogin />;
  }

  return (
    <PageContainer isAdmin>
      <Switch>
        <Route exact path={path}>
          <AdminSchedule />
          {/* <AdminHome /> */}
        </Route>
        <Route path={`${path}/schedule`}>
          <AdminSchedule />
        </Route>
        <Route path={`${path}/roster`}>
          <AdminRosterLanding />
        </Route>
        <Route exact path={`${path}/roster/:id`}>
          <AdminRosterTeam />
        </Route>
        <Route path={`${path}/login`}>
          <AdminLogin />
        </Route>
        <Route path={`${path}/draft`}>
          <AdminDraftOrder />
        </Route>
        <Route exact path={`${path}/game/:id`}>
          <AdminGame />
        </Route>
        <Route path={`${path}/export`}>
          <AdminPlayerExport />
        </Route>
      </Switch>
    </PageContainer>
  );
};

export default Admin;
