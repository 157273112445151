import React from 'react'
import { Toggle } from 'styles';

const GoalieSkaterToggle = ({statType, setStatType}) => {
  return (
    <Toggle>
      <button
        onClick={() => {
          setStatType('skater');
        }}
        disabled={statType === 'skater'}
      >
        Skater Stats
      </button>
      <button
        onClick={() => {
          setStatType('goalie');
        }}
        disabled={statType === 'goalie'}
      >
        Goalie Stats
      </button>
    </Toggle>
  )
}

export default GoalieSkaterToggle