import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import {
  ArchiveSeasonTeamsTable,
  baseRowLinks,
  ChampionBlock,
  LoadingPage,
  PageContainer,
  ScheduleTable,
  SeasonSelect,
  StandingsBlock,
  StatsBlock,
} from 'Components';
import { HeadWrap, ScrollWrapper } from 'styles';
import { getSkaterSingleSeasonHeadCells } from 'tableHeadCells';
import { GET_ARCHIVE_SINGLE_SEASON_INFO } from 'services/queries';
import {
  calculateWinStreak,
  createStandingsData,
  design,
  makeTitle,
} from 'utils';

const SectionWrapper = styled.div`
  margin-bottom: ${design.spacing[4]};
`;

const ArchiveSeason = () => {
  const history = useHistory();
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_ARCHIVE_SINGLE_SEASON_INFO, {
    variables: { seasonId: id },
  });

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const currentSeason = data.seasons[0];
  const { league, season, teams, year } = currentSeason;
  const gbhpc = league === 'GBHPC';

  const handleChangeSeason = (e) => {
    history.push(`/archives/season/${e.target.value}`);
  };

  let teamsByDivision = {};
  if (teams[0].division) {
    teams.forEach((team) => {
      if (Object.keys(teamsByDivision).includes(team.division)) {
        const divisionTeams = teamsByDivision[team.division];
        teamsByDivision[team.division] = [...divisionTeams, team];
      } else {
        teamsByDivision[team.division] = [team];
      }
    });
  } else {
    teamsByDivision[0] = teams;
  }
  const teamsByDivisionArr = Object.entries(teamsByDivision);
  const splitByDivisions = teamsByDivisionArr.length > 1;
  const singleDivisionTitle = teamsByDivisionArr[0][0];

  const headCells = getSkaterSingleSeasonHeadCells({ showTeam: true });

  const teamRowLink = gbhpc ? {
    page: '/team',
    param: ['seasonId', 'teamId'],
  } : {
    page: '/archives/team',
    param: 'teamId'
  }
  const rowLinks = {
    ...baseRowLinks,
    team: teamRowLink,
  };

  // Set up Schedule
  const hasSchedule = data.schedule?.length;
  const gamesByWeek = {};
  if (hasSchedule) {
    [...data.schedule].forEach((game) => {
      if (gamesByWeek[game.date]) {
        gamesByWeek[game.date].push(game);
      } else {
        gamesByWeek[game.date] = [game];
      }
    });
  }

  // Set up Standings
  const standings = hasSchedule
    ? createStandingsData([...data.schedule].filter((game) => !game.playoffs))
    : null;
  const teamStreaks = hasSchedule
    ? calculateWinStreak({
        schedule: [...data.schedule],
      })
    : null;
  const showStandings = hasSchedule ? Object.keys(standings).length > 0 : false;

  return (
    <PageContainer
      pageHeading={
        <>
          <HeadWrap>
            <h1>
              {season} {year}
            </h1>
            <SeasonSelect hideLatest onChange={handleChangeSeason} value={id} />
          </HeadWrap>
          <h2>
            {league}
            {!splitByDivisions &&
              singleDivisionTitle !== '0' &&
              ` - ${makeTitle({ title: singleDivisionTitle })}`}
          </h2>
        </>
      }
    >
      <Helmet>
        <title>{`Archives - ${season} ${year} Season | GBH Players Club`}</title>
      </Helmet>
      <ChampionBlock
        isArchive={!gbhpc}
        seasonId={id}
        seasonName={season}
        shortTitle
        showImage={gbhpc}
        teamId={teams[0].id}
        teamName={teams[0].name}
        year={year}
      />
      {teamsByDivisionArr.map(([division, teams]) => (
        <SectionWrapper key={division}>
          <ArchiveSeasonTeamsTable
            division={splitByDivisions ? division : null}
            gbhpc={gbhpc}
            seasonId={id}
            teams={teams}
          />
        </SectionWrapper>
      ))}
      {showStandings && (
        <ScrollWrapper>
          <StandingsBlock
            isArchive={!gbhpc}
            seasonId={id}
            standings={standings}
            teamStreaks={teamStreaks}
          />
        </ScrollWrapper>
      )}
      <SectionWrapper>
        <h2>Player Stats</h2>
        <StatsBlock
          headCells={headCells}
          rowLinks={rowLinks}
          seasonId={id}
          showTeam
        />
      </SectionWrapper>
      <SectionWrapper>
        <h2>Goalie Stats</h2>
        <StatsBlock
          headCells={headCells}
          rowLinks={rowLinks}
          seasonId={id}
          showGoalieAssists
          showGoaliePim
          showTeam
          statType="goalie"
        />
      </SectionWrapper>
      {Object.keys(gamesByWeek).length > 0 && (
        <SectionWrapper>
          <h2>Schedule & Results</h2>
          <ScheduleTable seasonId={id} gamesByWeek={gamesByWeek} />
        </SectionWrapper>
      )}
    </PageContainer>
  );
};

export default ArchiveSeason;
