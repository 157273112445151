import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Input, Label, Option, Select } from 'styles';
import { design } from 'utils';

const SectionWrap = styled.div`
  text-align: center;
  margin: ${design.spacing[4]} 0;
`;
const ButtonWrap = styled(SectionWrap)`
  display: flex;
  justify-content: center;
  button {
    margin: 0 ${design.spacing[4]};
  }
`;
const InputWrapper = styled.div`
  margin-bottom: ${design.spacing[1]};
`;
const InputMargin = styled(Input)`
  margin-left: ${design.spacing[1]};
`;
const OrderWrap = styled.dl`
  margin: auto;
  max-width: 40%;
  text-align: left;
  div {
    display: grid;
    grid-template-columns: 20% 80%;
    border-bottom: 1px solid ${design.colors.secondary};
    &:first-child {
      border-top: 1px solid ${design.colors.secondary};
    }
    &:nth-child(2n + 1) {
      background-color: ${design.colors.tertiary};
    }
  }
  dt,
  dd {
    margin: 0;
    padding: ${design.spacing[1]} ${design.spacing[2]};
  }
`;

const AdminDraftOrder = () => {
  const [draftOrder, setDraftOrder] = useState([]);
  const [numberOfTeams, setNumberOfTeams] = useState(0);
  const [teams, setTeams] = useState({});

  const handleSetTeams = (index) => (e) => {
    const teamsList = { ...teams };
    teamsList[index] = e.target.value;
    setTeams(teamsList);
  };

  const handleSetOrder = () => {
    const randomizedTeams = Object.values(teams).sort(
      (a, b) => 0.5 - Math.random()
    );
    setDraftOrder(randomizedTeams);
  };

  const resetFields = () => {
    setDraftOrder([]);
    setNumberOfTeams(0);
    setTeams({});
  };

  return (
    <main>
      <h1>Draft Order</h1>
      {draftOrder.length === 0 ? (
        <>
          <SectionWrap>
            <Label htmlFor="numTeams">
              Number of Teams:
              <Select
                data-int
                defaultValue={0}
                id="numTeams"
                onChange={(e) => setNumberOfTeams(e.target.value)}
                required
              >
                <Option value={0} disabled>
                  Select a Number
                </Option>
                {Array.from({ length: 8 }, (_, index) => index).map((i) => (
                  <Option value={Number(i + 1)} key={i}>
                    {i + 1}
                  </Option>
                ))}
              </Select>
            </Label>
          </SectionWrap>
          {numberOfTeams > 0 && (
            <>
              <SectionWrap>
                {Array.from({ length: numberOfTeams }, (_, index) => (
                  <InputWrapper key={index}>
                    <Label htmlFor={`teamName-${index}`}>
                      Team {index + 1}:
                      <InputMargin
                        onChange={handleSetTeams(index)}
                        value={teams[index]}
                      />
                    </Label>
                  </InputWrapper>
                ))}
              </SectionWrap>
              <SectionWrap>
                <Button
                  onClick={handleSetOrder}
                  disabled={
                    Object.values(teams).length !== Number(numberOfTeams)
                  }
                >
                  Generate Draft Order
                </Button>
              </SectionWrap>
            </>
          )}
        </>
      ) : (
        <>
          <SectionWrap>
            <OrderWrap>
              {draftOrder.map((team, i) => (
                <div key={team}>
                  <dt>{i + 1}</dt>
                  <dd>{team}</dd>
                </div>
              ))}
            </OrderWrap>
          </SectionWrap>
          <ButtonWrap>
            <Button onClick={handleSetOrder}>Re-Roll Order</Button>
            <Button invert onClick={resetFields}>Reset Fields</Button>
          </ButtonWrap>
        </>
      )}
    </main>
  );
};

export default AdminDraftOrder;
