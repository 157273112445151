import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import { Loading } from 'Components';
import { Option, Select } from 'styles';
import { GET_LEAGUE_SEASONS } from 'services/queries';

const SeasonSelect = ({ hideLatest, league, onChange, value }) => {
  const { loading, error, data } = useQuery(GET_LEAGUE_SEASONS, {
    variables: {
      league,
    },
  });
  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  return (
    <Select id="seasonId" data-int onChange={onChange} value={value}>
      {data.seasons.map((season, i) =>
        hideLatest && i === 0 ? (
          <Fragment key={season.id} />
        ) : (
          <Option value={Number(season.id)} key={season.id}>
            {season.year} {season.season} ({season.league})
          </Option>
        )
      )}
    </Select>
  );
};

export default SeasonSelect;
