import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { design, formatTime, CURRENT_SEASON } from 'utils';

const BracketGame = styled.section`
  text-align: center;
  max-width: ${design.breakpoints[2]};
  width: 100%;
  margin: auto;
  @media screen and (min-width: ${design.breakpoints[4]}) {
    &:nth-child(2) {
      grid-area: 1 / 3 / 1 / 3;
    }
    &:nth-child(3) {
      grid-area: 1 / 2 / 1 / 2;
    }
  }
  h3 {
    ${(props) =>
      props.isChampionship
        ? `
    background-color: ${design.colors.primary};
    `
        : `
    background-color: ${design.colors.secondary};
    `}
    color: ${design.colors.white};
    font-weight: bold;
    margin: 0;
    padding: ${design.spacing[1]};
  }
`;
const GameContent = styled.div`
  border: solid ${design.colors.secondary};
  border-width: 0 1px 1px;
  padding: ${design.spacing[2]};
`;
const TeamWrap = styled.div`
  position: relative;
`;
const MatchupHeadings = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${design.fontSizes[1]};
  background-color: ${design.colors.secondary};
  color: ${design.colors.white};
  padding: ${design.spacing[0.5]} ${design.spacing[1]};
`;
const TeamName = styled.p`
  ${(props) =>
    props.isHome
      ? `
  background-color: ${design.colors.gbhpcHome};
  `
      : `
  background-color: ${design.colors.gbhpcAway};
  `}
  ${(props) => (props.isWinner ? `font-weight: bold;` : '')}
  ${(props) => (props.isLoser ? `opacity: 0.4;` : '')}
  color: ${design.colors.white};
  padding: ${design.spacing[2]};
  display: flex;
  justify-content: space-between;
  position: relative;
  &:first-child {
    z-index: 1;
  }
  a {
    color: ${design.colors.white};
  }
`;
const VsText = styled.span`
  position: absolute;
  margin: auto;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 2;
  color: ${design.colors.black};
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: -25%;
    top: -25%;
    z-index: -1;
    width: ${design.spacing[3]};
    height: ${design.spacing[3]};
    background-color: ${design.colors.gray[2]};
    transform: rotate(45deg);
  }
`;
const GameInfo = styled.div`
  margin: ${design.spacing[1]} 0 0;
  p {
    margin-top: ${design.spacing[1]};
  }
`;

const PlayoffBracketGame = ({
  awayScore,
  awaySeed,
  awayTeam,
  date,
  homeScore,
  homeSeed,
  homeTeam,
  id,
  isChampionship,
  roundLabel,
  time,
  rinkLocation,
}) => {
  const day = new Date(date);
  const nextDay = day.setDate(day.getDate() + 1);
  const isoString = new Date(nextDay).toISOString();
  const dateArr = new Date(isoString).toDateString().split(' ');
  const homeWinner = homeScore > awayScore;
  const awayWinner = awayScore > homeScore;

  const readableDate = `
    ${dateArr[0]}day ${dateArr[1]}. ${dateArr[2]}, ${dateArr[3]}
  `;
  return (
    <BracketGame isChampionship={isChampionship}>
      <h3>{roundLabel}</h3>
      <GameContent>
        <MatchupHeadings>
          <span>Seed</span>
          <span>Team</span>
          <span>Score</span>
        </MatchupHeadings>
        <TeamWrap>
          <TeamName isHome isWinner={homeWinner} isLoser={awayWinner}>
            <span>{homeSeed}</span>
            {homeTeam?.name ? (
              <Link to={`/team/${CURRENT_SEASON}/${homeTeam.id}`}>
                {homeTeam.name}
              </Link>
            ) : (
              'TBD'
            )}
            <span>{homeScore}</span>
          </TeamName>
          <VsText>vs</VsText>

          <TeamName isWinner={awayWinner} isLoser={homeWinner}>
            <span>{awaySeed}</span>
            {awayTeam?.name ? (
              <Link to={`/team/${CURRENT_SEASON}/${awayTeam.id}`}>
                {awayTeam.name}
              </Link>
            ) : (
              'TBD'
            )}
            <span>{awayScore}</span>
          </TeamName>
        </TeamWrap>
        <GameInfo>
          {(homeWinner || awayWinner) && (
            <p>
              <Link to={`/game/${id}`}>Game Summary</Link>
            </p>
          )}
          <p>{formatTime(time)}</p>
          <p>{readableDate}</p>
          <p>
            <Link to={`/rink/${rinkLocation.id}`}>
              {rinkLocation.name}, {rinkLocation.city}
            </Link>
          </p>
        </GameInfo>
      </GameContent>
    </BracketGame>
  );
};

PlayoffBracketGame.propTypes = {
  awayScore: PropTypes.number,
  awaySeed: PropTypes.number,
  awayTeam: PropTypes.object,
  date: PropTypes.string.isRequired,
  homeScore: PropTypes.number,
  homeSeed: PropTypes.number,
  homeTeam: PropTypes.object,
  id: PropTypes.number.isRequired,
  isChampionship: PropTypes.bool,
  roundLabel: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  rinkLocation: PropTypes.object.isRequired,
};

PlayoffBracketGame.defaultProps = {
  awayScore: null,
  awaySeed: null,
  awayTeam: {},
  homeScore: null,
  homeSeed: null,
  homeTeam: {},
  isChampionship: false,
};

export default PlayoffBracketGame;
