import React from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { DeleteButton, TeamStatTable } from 'styles';
import { DELETE_PENALTY } from 'services/mutations';
import { convertTimestampToSeconds, makeTitle } from 'utils';

const MinutesWrap = styled.td`
  display: flex;
  align-items: center;
  span {
    width: 100%;
  }
  button {
    flex-grow: 0;
  }
`;

const PenaltyBlock = ({ isAdmin, isHome, penalties, refetchGameData }) => {
  const [deletePenalty] = useMutation(DELETE_PENALTY);
  const handleDelete = (id) => () => {
    deletePenalty({variables: { id }}).then(() => refetchGameData());
  };

  penalties.sort((penaltyA, penaltyB) => {
    if (penaltyA.period > penaltyB.period) {
      return 1;
    }
    if (penaltyA.period < penaltyB.period) {
      return -1;
    }
    const penaltyASeconds = convertTimestampToSeconds(penaltyA.time);
    const penaltyBSeconds = convertTimestampToSeconds(penaltyB.time);
    if (penaltyASeconds > penaltyBSeconds) {
      return -1;
    }
    if (penaltyASeconds < penaltyBSeconds) {
      return 1;
    }
    return 0;
  });
  return (
    <div>
      <TeamStatTable isHome={isHome}>
        <thead>
          <tr>
            <th>Player</th>
            <th>Period</th>
            <th>Time</th>
            <th>Penalty</th>
            <th>Minutes</th>
          </tr>
        </thead>
        <tbody>
          {penalties.map((penalty) => (
            <tr key={penalty.id}>
              <td>
                <Link to={`/player/${penalty.player.id}`}>
                  {penalty.player.firstName} {penalty.player.lastName}
                </Link>
              </td>
              <td style={{ textAlign: 'center' }}>{penalty.period}</td>
              <td>{penalty.time}</td>
              <td style={{ textAlign: 'left' }}>
                {makeTitle({ title: penalty.penalty, separator: '_' })}
              </td>
              <MinutesWrap>
                <span>{penalty.minutes}</span>
                {isAdmin && (
                  <DeleteButton onClick={handleDelete(penalty.id)}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </DeleteButton>
                )}
              </MinutesWrap>
            </tr>
          ))}
        </tbody>
      </TeamStatTable>
    </div>
  );
};

export default PenaltyBlock;
