import React from 'react';
import PropTypes from 'prop-types';
import { SummaryTable } from 'styles';

const GoalieStatSummary = ({ stats }) => {
  const {
    assists,
    gamesPlayed,
    goalsAgainst,
    loss,
    otl,
    pim,
    shotsAgainst,
    shutouts,
    sol,
    tie,
    win,
  } = stats;
  const showOtl = otl > 0;
  const showSol = sol > 0;
  const showTies = tie > 0;
  let svp = (shotsAgainst - goalsAgainst) / shotsAgainst;
  svp = svp === 1 ? svp.toFixed(3) : svp.toFixed(3).slice(1);
  return (
    <SummaryTable isGoalie>
      <table>
        <thead>
          <tr>
            <th>GP</th>
            <th>W</th>
            <th>L</th>
            {showTies && <th className="hide-sm">T</th>}
            {showOtl && <th className="hide-sm">OTL</th>}
            {showSol && <th className="hide-sm">SOL</th>}
            <th>GAA</th>
            <th>SV%</th>
            <th className="hide-sm">SO</th>
            <th className="hide-sm">GA</th>
            <th className="hide-sm">SA</th>
            <th className="hide-sm">SV</th>
            <th className="hide-sm">A</th>
            <th className="hide-sm">PIM</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{gamesPlayed}</td>
            <td>{win}</td>
            <td>{loss}</td>
            {showTies && <td className="hide-sm">{tie}</td>}
            {showOtl && <td className="hide-sm">{otl}</td>}
            {showSol && <td className="hide-sm">{sol}</td>}
            <td>{(goalsAgainst / gamesPlayed).toFixed(2)}</td>
            <td>{svp}</td>
            <td className="hide-sm">{shutouts}</td>
            <td className="hide-sm">{goalsAgainst}</td>
            <td className="hide-sm">{shotsAgainst}</td>
            <td className="hide-sm">{shotsAgainst - goalsAgainst}</td>
            <td className="hide-sm">{assists}</td>
            <td className="hide-sm">{pim}</td>
          </tr>
        </tbody>
      </table>
    </SummaryTable>
  );
};

GoalieStatSummary.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default GoalieStatSummary;
