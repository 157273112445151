import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { EditButton, Input, Option, Select } from 'styles';
import {
  SET_AWAY_SCORE,
  SET_HOME_SCORE,
  SET_GAME_OT,
} from 'services/mutations';
import { design } from 'utils';

const ScoreRow = styled.h2`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
`;
const ScoreInput = styled(Input)`
  width: 50px;
`;
const TeamScore = styled.span`
  font-size: ${design.fontSizes[4]};
  ${(props) =>
    props.$isWinner &&
    `
    font-weight: bold;
  `}
`;

const GameScoreHeading = ({
  awayWinner,
  gameData,
  homeWinner,
  isAdmin,
  refetchGameData,
}) => {
  const [awayTeamScore, setAwayTeamScore] = useState(0);
  const [editAwayScore, setEditAwayScore] = useState(false);
  const [editHomeScore, setEditHomeScore] = useState(false);
  const [homeTeamScore, setHomeTeamScore] = useState(0);

  const [setAwayScore] = useMutation(SET_AWAY_SCORE);
  const [setHomeScore] = useMutation(SET_HOME_SCORE);
  const [setGameOt] = useMutation(SET_GAME_OT);

  const { awayScore, homeScore, id: gameId, ot } = gameData;

  useEffect(() => {
    if (awayScore && awayTeamScore === 0) {
      setAwayTeamScore(awayScore);
    }
    if (homeScore && homeTeamScore === 0) {
      setHomeTeamScore(homeScore);
    }
  }, [awayScore, awayTeamScore, homeScore, homeTeamScore]);

  const handleEdit = (team) => () => {
    if (team === 'away') {
      setEditAwayScore(true);
    }
    if (team === 'home') {
      setEditHomeScore(true);
    }
  };
  const handleInput = (team) => (e) => {
    const { value } = e.target;
    if (team === 'away') {
      setAwayTeamScore(value);
    }
    if (team === 'home') {
      setHomeTeamScore(value);
    }
  };
  const handleUpdateOt = (e) => {
    const { value } = e.target;
    let otValue = value;
    if(value === 'null') {
      otValue = null;
    }
    setGameOt({ variables: { gameId, ot: otValue } }).then(() =>
      refetchGameData()
    );
  };
  const submitScore = (team) => (e) => {
    const score = Number(e.target.value);
    if (team === 'away') {
      setAwayScore({ variables: { gameId, awayScore: score } }).then(() =>
        refetchGameData()
      );
      setEditAwayScore(false);
    }
    if (team === 'home') {
      setHomeScore({ variables: { gameId, homeScore: score } }).then(() =>
        refetchGameData()
      );
      setEditHomeScore(false);
    }
  };

  return (
    <ScoreRow>
      <TeamScore $isWinner={homeWinner}>
        {editHomeScore ? (
          <ScoreInput
            type="number"
            value={homeTeamScore}
            onChange={handleInput('home')}
            onBlur={submitScore('home')}
          />
        ) : isAdmin ? (
          <EditButton onClick={handleEdit('home')} $isWinner={homeWinner}>
            {homeScore || 0}
            <FontAwesomeIcon icon={faEdit} />
          </EditButton>
        ) : (
          homeScore || 0
        )}
      </TeamScore>
      <span>
        {isAdmin ? (
          <Select defaultValue={ot || null} id="ot" onChange={handleUpdateOt}>
            <Option value={'null'}>N/A</Option>
            <Option value={'SO'}>SO</Option>
            <Option value={'OT'}>OT</Option>
          </Select>
        ) : (
          ot && ` (${ot.toUpperCase()})`
        )}
      </span>
      <TeamScore $isWinner={awayWinner}>
        {editAwayScore ? (
          <ScoreInput
            type="number"
            value={awayTeamScore}
            onChange={handleInput('away')}
            onBlur={submitScore('away')}
          />
        ) : isAdmin ? (
          <EditButton onClick={handleEdit('away')} $isWinner={awayWinner}>
            <FontAwesomeIcon icon={faEdit} />
            {awayScore || 0}
          </EditButton>
        ) : (
          awayScore || 0
        )}
      </TeamScore>
    </ScoreRow>
  );
};

export default GameScoreHeading;
