import React from 'react';
import styled from 'styled-components';
import { PenaltySummary, GoalieGameStats, GameStats } from 'Components';
import { TeamStatWrapper } from 'styles';
import { design, getWinner, processAttendance } from 'utils';

const StatsTitle = styled.h2`
  margin-top: ${design.spacing[4]};
`;

const GameSummary = ({ gameData, isAdmin, refetchGameData }) => {
  const {
    attendance,
    awayGoalie,
    awayScore,
    awayShots,
    awayTeam: { id: awayTeamId },
    goals,
    homeGoalie,
    homeScore,
    homeShots,
    homeTeam: { id: homeTeamId },
    ot,
    penalties,
  } = gameData;

  const {
    awayTeamAttendance,
    awayTeamGoalie,
    awayTeamPlayers,
    homeTeamAttendance,
    homeTeamGoalie,
    homeTeamPlayers,
  } = processAttendance({
    attendance,
    awayGoalie,
    awayTeamId,
    homeGoalie,
    homeTeamId,
  });

  const { awayWinner, homeWinner } = getWinner({ awayScore, homeScore });

  return (
    <>
      {penalties.length > 0 && (
        <PenaltySummary
          awayTeamId={awayTeamId}
          homeTeamId={homeTeamId}
          isAdmin={isAdmin}
          penalties={penalties}
          refetchGameData={refetchGameData}
        />
      )}
      <StatsTitle>Team Stats</StatsTitle>
      <TeamStatWrapper>
        {(awayTeamGoalie || homeTeamGoalie) && (
          <GoalieGameStats
            awayScore={awayScore}
            awayShots={awayShots}
            awayWinner={awayWinner}
            goals={goals}
            goalies={[...awayTeamGoalie, ...homeTeamGoalie]}
            homeScore={homeScore}
            homeShots={homeShots}
            homeTeamId={homeTeamId}
            homeWinner={homeWinner}
            ot={ot}
          />
        )}
        {awayTeamAttendance || homeTeamAttendance ? (
          <>
            {homeTeamAttendance ? (
              <GameStats
                attendance={homeTeamPlayers}
                goals={goals}
                penalties={penalties}
                isHome
              />
            ) : (
              <div />
            )}
            {awayTeamAttendance && (
              <GameStats
                attendance={awayTeamPlayers}
                goals={goals}
                penalties={penalties}
              />
            )}
          </>
        ) : (
          <></>
        )}
      </TeamStatWrapper>
    </>
  );
};

export default GameSummary;
