export const getSkaterSingleSeasonHeadCells = ({
  showJersey = false,
  showTeam = false,
}) => {
  const skaterHeadCells = [
    { id: 'lastName', numeric: false, label: 'Last Name' },
    { id: 'firstName', numeric: false, label: 'First Name' },
    { id: 'position', numeric: false, label: 'Pos.' },
    { id: 'gamesPlayed', numeric: true, label: 'GP' },
    { id: 'goals', numeric: true, label: 'G' },
    { id: 'assists', numeric: true, label: 'A' },
    { id: 'points', numeric: true, label: 'PTS' },
    { id: 'pim', numeric: true, label: 'PIM' },
    { id: 'gpg', numeric: true, label: 'G/GM' },
    { id: 'apg', numeric: true, label: 'A/GM' },
    { id: 'ppg', numeric: true, label: 'PTS/GM' },
    { id: 'pimpg', numeric: true, label: 'PIM/GM' },
  ];
  if (showJersey) {
    skaterHeadCells.splice(3, 0, {
      id: 'jersey',
      numeric: false,
      label: 'No.',
    });
  }
  if (showTeam) {
    skaterHeadCells.splice(3, 0, { id: 'team', numeric: false, label: 'Team' });
  }
  return skaterHeadCells;
};