export const createGoalieStatsSeason = (games, playerId) =>
  games.reduce(
    (total, current) => {
      const {
        awayScore,
        awayShots,
        awayTeam,
        homeGoalie,
        homeScore,
        homeShots,
        homeTeam,
        eng,
        ot,
        penalties_aggregate,
        primaryAssists,
        secondaryAssists,
      } = current.schedule;
      const isHome = homeGoalie === playerId;
      const currentTeam = isHome ? homeTeam.id : awayTeam.id;
      const homeWinner = homeScore > awayScore;
      let result =
        isHome && homeWinner ? 'W' : !isHome && !homeWinner ? 'W' : 'L';
      if (ot === 'SO' && result === 'L') {
        result = 'SOL';
      }
      let shotsAgainst = isHome ? awayShots : homeShots;
      let goalsAgainst = isHome ? awayScore : homeScore;
      if (result === 'SOL') {
        goalsAgainst -= 1;
      }
      const emptyNetters = eng.filter(
        (goal) => goal.teamId !== currentTeam
      ).length;
      goalsAgainst -= emptyNetters;
      shotsAgainst -= emptyNetters;
      const shutout = goalsAgainst === 0;
      const primary = primaryAssists.aggregate.count;
      const secondary = secondaryAssists.aggregate.count;
      const assists = primary + secondary;
      total.gamesPlayed += 1;
      total.win += result === 'W' ? 1 : 0;
      total.loss += result === 'L' ? 1 : 0;
      total.sol += result === 'SOL' ? 1 : 0;
      total.goalsAgainst += goalsAgainst;
      total.shotsAgainst += shotsAgainst;
      total.shutouts += shutout ? 1 : 0;
      total.assists += assists;
      total.pim += penalties_aggregate.aggregate.sum.minutes || 0;
      return total;
    },
    {
      gamesPlayed: 0,
      win: 0,
      loss: 0,
      sol: 0,
      goalsAgainst: 0,
      shotsAgainst: 0,
      shutouts: 0,
      assists: 0,
      pim: 0,
    }
  );
