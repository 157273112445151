import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { design } from 'utils';

const Header = styled.header`
  text-align: center;
  margin: 0 0 ${design.spacing[4]};
`;

const PageHeading = (props) => {
  const { children } = props;
  return <Header>{children}</Header>;
};

PageHeading.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeading;
