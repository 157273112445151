import React from 'react';
import styled from 'styled-components';
import { PenaltyBlock } from 'Components';
import { TeamStatWrapper } from 'styles';
import { design } from 'utils';

const Title = styled.h2`
  margin-top: ${design.spacing[4]};
`;

const PenaltySummary = ({
  awayTeamId,
  homeTeamId,
  isAdmin,
  penalties,
  refetchGameData,
}) => {
  const homePenalties = penalties.filter(
    (penalty) => penalty.teamId === homeTeamId
  );
  const awayPenalties = penalties.filter(
    (penalty) => penalty.teamId === awayTeamId
  );
  return (
    <div>
      <Title>Penalty Summary</Title>
      <TeamStatWrapper>
        {homePenalties.length > 0 ? (
          <PenaltyBlock
            isAdmin={isAdmin}
            isHome
            penalties={homePenalties}
            refetchGameData={refetchGameData}
          />
        ) : (
          <div />
        )}
        {awayPenalties.length > 0 && (
          <PenaltyBlock
            isAdmin={isAdmin}
            penalties={awayPenalties}
            refetchGameData={refetchGameData}
          />
        )}
      </TeamStatWrapper>
    </div>
  );
};

export default PenaltySummary;
