export function createStatTotalData(
  id,
  lastName,
  firstName,
  position,
  gamesPlayed,
  goals,
  assists,
  points,
  pim,
  gpg,
  apg,
  ppg,
  pimpg
) {
  return {
    id,
    lastName,
    firstName,
    position: position.slice(0, 1),
    gamesPlayed: Number(gamesPlayed),
    goals: Number(goals),
    assists: Number(assists),
    points: Number(points),
    pim: Number(pim),
    gpg: Number(gpg),
    apg: Number(apg),
    ppg: Number(ppg),
    pimpg: Number(pimpg),
  };
}
export function createStatTotalDataWithTeam(
  id,
  seasonId,
  teamId,
  lastName,
  firstName,
  position,
  team,
  gamesPlayed,
  goals,
  assists,
  points,
  pim,
  gpg,
  apg,
  ppg,
  pimpg
) {
  return {
    id,
    seasonId,
    teamId,
    lastName,
    firstName,
    position: position.slice(0, 1),
    team,
    gamesPlayed: Number(gamesPlayed),
    goals: Number(goals),
    assists: Number(assists),
    points: Number(points),
    pim: Number(pim),
    gpg: Number(gpg),
    apg: Number(apg),
    ppg: Number(ppg),
    pimpg: Number(pimpg),
  };
}
