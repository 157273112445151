import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet';
import { LoadingPage, PageContainer } from 'Components';
import { GET_RINK_INFO } from 'services/queries';
import { design } from 'utils';

const RinkPage = () => {
  const { id } = useParams();
  const rinkId = Number(id);
  const { loading, error, data } = useQuery(GET_RINK_INFO, {
    variables: { rinkId },
  });

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const { address, city, mapString, name, website } = data.rinks[0];

  return (
    <PageContainer
      pageHeading={
        <>
          <h1>{name}</h1>
          <h3>
            {address}, {city}
          </h3>
          <h3>
            <a href={website} rel="noopener noreferrer" target="_blank">
              {website}
            </a>
          </h3>
        </>
      }
      center
    >
      <Helmet>
        <title>{name} | GBH Players Club</title>
      </Helmet>
      {mapString && (
        <iframe
          src={mapString}
          style={{ border: `1px solid ${design.colors.black}` }}
          width="800"
          height="600"
          allowFullScreen=""
          loading="lazy"
          title={name}
        />
      )}
    </PageContainer>
  );
};

export default RinkPage;
