export const formatTime = (time) => {
  let hours = Number(time.slice(0, 2));
  const minutes = time.slice(3, 5);
  let ampm = 'AM';
  if (hours > 12) {
    ampm = 'PM';
    hours = hours - 12;
  }
  return `${hours}:${minutes} ${ampm}`;
};
