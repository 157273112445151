import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { design } from 'utils';

const ScoreWrap = styled.div`
  display: flex;
  div {
    width: 50%;
    padding: ${design.spacing[1]} ${design.spacing[1]};
    text-align: center;
    color: ${design.colors.white};
    font-size: ${design.fontSizes[1]};
  }
`;
const HomeWrap = styled.div`
  background-color: ${design.colors.gbhpcHome};
  ${(props) =>
    props.isWinning &&
    `
    font-weight: bold;
  `}
  }}
`;
const AwayWrap = styled.div`
  background-color: ${design.colors.gbhpcAway};
  ${(props) =>
    props.isWinning &&
    `
    font-weight: bold;
  `}
`;

const ScoreBlock = ({ awayScore, awayTeamAbbr, homeScore, homeTeamAbbr }) => {
  const homeWinning = Number(homeScore) > Number(awayScore);
  const awayWinning = Number(awayScore) > Number(homeScore);
  return (
    <ScoreWrap>
      <AwayWrap isWinning={awayWinning}>
        {awayTeamAbbr}: {awayScore}
      </AwayWrap>
      <HomeWrap isWinning={homeWinning}>
        {homeTeamAbbr}: {homeScore}
      </HomeWrap>
    </ScoreWrap>
  );
};

ScoreBlock.propTypes = {
  awayScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  awayTeamAbbr: PropTypes.string.isRequired,
  homeScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  homeTeamAbbr: PropTypes.string.isRequired,
};

export default ScoreBlock;
