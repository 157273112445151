import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { design } from 'utils';

const ChampBlock = styled.section`
  margin-top: ${design.spacing[4]};
  &:first-child {
    margin-top: 0;
    margin-bottom: ${design.spacing[6]};
  }
`;
const ChampLinkWrap = styled.p`
  margin: ${design.spacing[2]} 0;
`;
const ImgWrap = styled.div`
  max-width: 80%;
  width: 100%;
  margin: 0 auto ${design.spacing[4]};
  img {
    display: block;
    margin: auto;
    max-width: 100%;
    width: auto;
  }
`;

const ChampionBlock = ({
  isArchive,
  seasonId,
  seasonName,
  shortTitle,
  showImage,
  teamId,
  teamName,
  year,
}) => {
  const teamLink = isArchive
    ? `/archives/team/${teamId}`
    : `/team/${seasonId}/${teamId}`;

  return (
    <ChampBlock>
      {shortTitle ? (
        <h2>
          <ChampLinkWrap>
            <Link to={teamLink}>
              {design.trophy} {teamName} {design.trophy}
            </Link>
          </ChampLinkWrap>
        </h2>
      ) : (
        <h2>
          {design.trophy} {year} {seasonName} Season Champions {design.trophy}
          <ChampLinkWrap>
            <Link to={teamLink}>{teamName}</Link>
          </ChampLinkWrap>
        </h2>
      )}
      {showImage && (
        <ImgWrap>
          <img
            src={require(`assets/champs/${seasonId}-${year}-${seasonName.toLowerCase()}.jpg`)}
            alt=""
          />
        </ImgWrap>
      )}
    </ChampBlock>
  );
};

export default ChampionBlock;
