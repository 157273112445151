import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { GoalieSkaterToggle, PageContainer, StatsBlock } from 'Components';
import { HeadWrapGrid } from 'styles';
import { design } from 'utils';

const ArchiveStats = () => {
  const [statType, setStatType] = useState('skater');
  return (
    <PageContainer
      pageHeading={
        <HeadWrapGrid styles={`margin-bottom: ${design.spacing[4]};`}>
          <GoalieSkaterToggle statType={statType} setStatType={setStatType} />
          <h1>Career Statistics</h1>
        </HeadWrapGrid>
      }
    >
      <Helmet>
        <title>Archives - Career Statistics | GBH Players Club</title>
      </Helmet>
      <StatsBlock
        mergeStats
        showGoalieAssists
        showGoaliePim
        showGoalieTies
        statType={statType}
      />
    </PageContainer>
  );
};

export default ArchiveStats;
