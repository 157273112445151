export const getPeriodTitle = (period) => {
  if ([1, '1'].includes(period)) {
    return '1st Period';
  }
  if ([2, '2'].includes(period)) {
    return '2nd Period';
  }
  if ([3, '3'].includes(period)) {
    return '3rd Period';
  }
  if ([4, '4'].includes(period)) {
    return 'Overtime';
  }
};
