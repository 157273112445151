import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { design, formatTime, getWinner } from 'utils';
import { GameScoreHeading, GameShotsHeading } from 'Components';

const TeamHeading = styled.h1`
  display: grid;
  grid-template-columns: 1fr 100px 1fr;
  align-items: center;
`;
const TeamName = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${design.spacing[2]};
  margin: 0 ${design.spacing[4]};
  height: 100%;
  transition: color 0.3s ease-out;
  &::after {
    display: block;
    content: '';
    background-color: ${design.colors.gbhpcHome};
    height: ${design.spacing[1]};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    transition: height 0.2s ease-out;
    ${(props) =>
      props.$awayTeam &&
      `
      background-color: ${design.colors.gbhpcAway};
      `}
    }
  &:hover {
    text-decoration: none;
    color: ${design.colors.white};
    &::after {
    height: 100%;
  }}
  ${(props) =>
    props.$isWinner &&
    `
    font-weight: bold;
  `}
`;
const RinkWrap = styled.p`
  margin: ${design.spacing[2]} 0;
`;

const GameHeading = ({ gameData, isAdmin, refetchGameData }) => {
  const {
    awayScore,
    awayTeam: { name: awayTeamName, id: awayTeamId },
    date,
    homeScore,
    homeTeam: { name: homeTeamName, id: homeTeamId },
    seasonId,
    rinkLocation: { city: rinkCity, name: rinkName },
    time,
  } = gameData;

  const { awayWinner, homeWinner } = getWinner({ awayScore, homeScore });

  return (
    <>
      <TeamHeading>
        <TeamName to={`/team/${seasonId}/${homeTeamId}`} $isWinner={homeWinner}>
          <span>{homeTeamName}</span>
        </TeamName>
        <span>vs</span>
        <TeamName $awayTeam to={`/team/${seasonId}/${awayTeamId}`} $isWinner={awayWinner}>
          <span>{awayTeamName}</span>
        </TeamName>
      </TeamHeading>
      {(isAdmin || homeScore + awayScore > 0) && (
        <GameScoreHeading
          awayWinner={awayWinner}
          gameData={gameData}
          homeWinner={homeWinner}
          isAdmin={isAdmin}
          refetchGameData={refetchGameData}
        />
      )}
      {(isAdmin || homeScore + awayScore > 0) && (
        <GameShotsHeading
          gameData={gameData}
          isAdmin={isAdmin}
          refetchGameData={refetchGameData}
        />
      )}
      <h2>
        {date} - {formatTime(time)}
        <RinkWrap>
          {rinkName}, {rinkCity}
        </RinkWrap>
      </h2>
    </>
  );
};

export default GameHeading;
