import { gql } from '@apollo/client';

export const SIGNUP_MUTATION = gql`
  mutation SignupMutation($email: String!, $password: String!) {
    insert_users_one(
      object: { email: $email, password: $password }
      on_conflict: { update_columns: [], constraint: users_email_key }
    ) {
      token
    }
  }
`;
export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;
export const INSERT_ATTENDANCE = gql`
  mutation InsertAttendance($attendance: [attendance_insert_input!]!) {
    insert_attendance(objects: $attendance) {
      returning {
        gameId
        playerId
        teamId
      }
    }
  }
`;
export const DELETE_ATTENDANCE = gql`
  mutation DeleteAttendance($id: Int!) {
    delete_attendance(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
export const INSERT_GOAL = gql`
  mutation InsertGoal($goal: goals_insert_input!) {
    insert_goals_one(object: $goal) {
      en
      gameId
      goal
      gw
      id
      period
      pp
      primaryAssist
      seasonId
      secondaryAssist
      sh
      teamId
      time
    }
  }
`;
export const DELETE_GOAL = gql`
  mutation DeleteGoal($goalId: Int!) {
    delete_goals_by_pk(id: $goalId) {
      id
    }
  }
`;
export const REMOVE_HOME_GOALIE = gql`
  mutation RemoveHomeGoalie($gameId: Int!) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { homeGoalie: null }
    ) {
      id
    }
  }
`;
export const SET_HOME_GOALIE = gql`
  mutation SetHomeGoalie($gameId: Int!, $playerId: Int!) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { homeGoalie: $playerId }
    ) {
      id
    }
  }
`;
export const REMOVE_AWAY_GOALIE = gql`
  mutation RemoveAwayGoalie($gameId: Int!) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { awayGoalie: null }
    ) {
      id
    }
  }
`;
export const SET_AWAY_GOALIE = gql`
  mutation SetAwayGoalie($gameId: Int!, $playerId: Int!) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { awayGoalie: $playerId }
    ) {
      id
    }
  }
`;
export const INSERT_PENALTY = gql`
  mutation InsertPenalty($penalty: penalties_insert_input!) {
    insert_penalties_one(object: $penalty) {
      id
    }
  }
`;
export const DELETE_PENALTY = gql`
  mutation DeletePenalty($id: Int!) {
    delete_penalties_by_pk(id: $id) {
      id
    }
  }
`;
export const SET_HOME_SCORE = gql`
  mutation SetHomeScore($gameId: Int!, $homeScore: Int!) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { homeScore: $homeScore }
    ) {
      id
    }
  }
`;
export const SET_AWAY_SCORE = gql`
  mutation SetAwayScore($gameId: Int!, $awayScore: Int!) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { awayScore: $awayScore }
    ) {
      id
    }
  }
`;
export const SET_AWAY_SHOTS = gql`
  mutation SetAwayShots($gameId: Int!, $awayShots: Int!) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { awayShots: $awayShots }
    ) {
      id
    }
  }
`;
export const SET_HOME_SHOTS = gql`
  mutation SetHomeShots($gameId: Int!, $homeShots: Int!) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { homeShots: $homeShots }
    ) {
      id
    }
  }
`;
export const SET_GAME_OT = gql`
  mutation SetGameOt($gameId: Int!, $ot: overtime_enum) {
    update_schedule_by_pk(
      pk_columns: { id: $gameId }
      _set: { ot: $ot }
    ) {
      id
    }
  }
`;

export const INSERT_ROSTERS = gql`
  mutation InsertRosters($players: [rosters_insert_input!]!) {
    insert_rosters(objects: $players) {
      returning {
        id
        teamId
        playerId
      }
    }
  }
`;

export const DELETE_ROSTERS = gql`
  mutation DeleteRosters($playerIds: [Int!], $teamId: Int!) {
    delete_rosters(where: {playerId: {_in: $playerIds}, teamId: {_eq: $teamId}}) {
      returning {
        id
      }
    }
  }
`;
