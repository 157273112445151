import React from 'react';
import { useQuery } from '@apollo/client';
import { Loading, Table, TeamName } from 'Components';
import { GET_HOMEPAGE_DATA } from 'services/queries';
import {
  CURRENT_SEASON,
  createSeasonStatData,
  createGoalieStatsLeague,
} from 'utils';
import { getGoalieSingleSeasonHeadCells } from 'tableHeadCells';

const rowLinks = {
  firstName: {
    page: '/player',
    param: 'id',
  },
  lastName: {
    page: '/player',
    param: 'id',
  },
  team: {
    page: '/team',
    param: ['seasonId', 'teamId'],
  },
};

const StatsCurrentSeasonGoalie = () => {
  const { loading, error, data } = useQuery(GET_HOMEPAGE_DATA, {
    variables: {
      seasonId: CURRENT_SEASON,
      seasonsArr: [CURRENT_SEASON - 1, CURRENT_SEASON],
    },
  });

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  const goalieStats = createGoalieStatsLeague(data.schedule);
  const goalieData = Object.entries(goalieStats)
    .map(([teamId, teamGoalies]) => {
      return Object.values(teamGoalies).map((goalie) => {
        const {
          firstName,
          lastName,
          jersey,
          jerseySecondary,
          gamesPlayed,
          goalsAgainst,
          id,
          losses,
          otl,
          shotsAgainst,
          shutouts,
          team,
          wins,
        } = goalie;
        const saves = shotsAgainst - goalsAgainst;
        const gaa = (goalsAgainst / gamesPlayed).toFixed(2);
        let svp = saves / shotsAgainst;
        svp = svp === 1 ? svp.toFixed(3) : svp.toFixed(3).slice(1);
        return createSeasonStatData(
          { id, seasonId: CURRENT_SEASON, teamId },
          {
            lastName,
            firstName,
            team: (
              <TeamName
                name={team}
                color="green"
                colorSecondary="black"
                wrap="true"
              />
            ),
            jersey: `${jersey ? jersey : ''}${
              jerseySecondary ? ` / ${jerseySecondary}` : ''
            }`,
            gamesPlayed,
            wins,
            losses,
            otl,
            gaa,
            svp,
            shutouts,
            goalsAgainst,
            shotsAgainst,
            saves,
          }
        );
      });
    })
    .flat();
  return (
    <Table
      defaultOrder="desc"
      defaultOrderBy="svp"
      enablePagination={false}
      enableToolbar={false}
      headCells={getGoalieSingleSeasonHeadCells({
        showJersey: true,
        showTeam: true,
      })}
      rowLinks={rowLinks}
      rows={[...new Set(goalieData)]}
      size="small"
      tableTitle="Leaders"
    />
  );
};

export default StatsCurrentSeasonGoalie;
