import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const RowLink = (props) => {
  const { linkText, rowData, rowLink } = props;
  const { website, param, page } = rowLink;

  if (website) {
    return (
      <a href={rowData[website]} target="_blank" rel="noopener noreferrer">
        {linkText}
      </a>
    );
  } else if (page && param) {
    let linkTarget = '';

    if (Array.isArray(param)) {
      linkTarget = param.reduce((acc, curr) => {
        if(rowData[curr]) {
          return `${acc}/${rowData[curr]}`;
        }
        return acc;
      }, '');
    } else if (rowData[param]) {
      linkTarget = `/${rowData[param]}`;
    }
    return <Link to={`${page}${linkTarget}`}>{linkText}</Link>;
  }
  return linkText;
};

RowLink.propTypes = {
  linkText: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  rowData: PropTypes.object.isRequired,
  rowLink: PropTypes.shape({
    external: PropTypes.string,
    page: PropTypes.string,
    param: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  }),
};

RowLink.defaultProps = {
  linkText: null,
  rowLink: {},
};

export default RowLink;
