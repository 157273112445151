export const createStandingsData = (schedule) => {
  return schedule.reduce((teamsStandings, curr) => {
    const updatedStandings = { ...teamsStandings };
    const {
      awayScore,
      awayShots,
      awayTeam,
      homeScore,
      homeShots,
      homeTeam,
      ot,
    } = curr;
    if ((homeScore || homeScore === 0) && (awayScore || awayScore === 0)) {
      // One team is designated the winner to make this all easier
      // But ties are sometimes still a thing, so "winner" doesn't necessarily mean they won
      const homeWinner = homeScore > awayScore;
      const isTie = homeScore === awayScore;

      const winner = homeWinner ? homeTeam.name : awayTeam.name;
      const loser = !homeWinner ? homeTeam.name : awayTeam.name;
      const winnerId = homeWinner ? homeTeam.id : awayTeam.id;
      const loserId = !homeWinner ? homeTeam.id : awayTeam.id;

      let winningGoals = homeWinner ? homeScore : awayScore;
      let winningShots = homeWinner ? homeShots : awayShots;
      if (ot && !isTie) {
        winningGoals -= 1;
      }
      let losingGoals = !homeWinner ? homeScore : awayScore;
      let losingShots = !homeWinner ? homeShots : awayShots;

      if (!Object.keys(updatedStandings).includes(winner)) {
        updatedStandings[winner] = { gamesPlayed: 1, teamId: winnerId };
      } else {
        updatedStandings[winner].gamesPlayed += 1;
      }
      if (!Object.keys(updatedStandings).includes(loser)) {
        updatedStandings[loser] = { gamesPlayed: 1, teamId: loserId };
      } else {
        updatedStandings[loser].gamesPlayed += 1;
      }
      // Wins/Losses & Points
      // First for the "winner" team
      if (isTie) {
        updatedStandings[winner].tie = updatedStandings[winner]?.tie + 1 || 1;
        updatedStandings[winner].pts = updatedStandings[winner]?.pts + 1 || 1;
      } else {
        updatedStandings[winner].wins = updatedStandings[winner]?.wins + 1 || 1;
        updatedStandings[winner].pts = updatedStandings[winner]?.pts + 2 || 2;
      }
      // Then for the "loser" (or tied) team
      if (isTie) {
        updatedStandings[loser].tie = updatedStandings[loser]?.tie + 1 || 1;
        updatedStandings[loser].pts = updatedStandings[loser]?.pts + 1 || 1;
      } else if (ot) {
        updatedStandings[loser].otl = updatedStandings[loser]?.otl + 1 || 1;
        updatedStandings[loser].pts = updatedStandings[loser]?.pts + 1 || 1;
      } else {
        updatedStandings[loser].losses =
          updatedStandings[loser]?.losses + 1 || 1;
        updatedStandings[loser].pts = updatedStandings[loser]?.pts || 0;
      }
      // Winner GF/GA/Shots
      updatedStandings[winner].goalsFor =
        updatedStandings[winner]?.goalsFor + winningGoals || winningGoals;
      updatedStandings[winner].goalsAgainst =
        updatedStandings[winner]?.goalsAgainst + losingGoals || losingGoals;
      updatedStandings[winner].shotsFor =
        updatedStandings[winner]?.shotsFor + winningShots || winningShots;
      updatedStandings[winner].shotsAgainst =
        updatedStandings[winner]?.shotsAgainst + losingShots || losingShots;

      // Loser GF/GA/Shots
      updatedStandings[loser].goalsFor =
        updatedStandings[loser]?.goalsFor + losingGoals || losingGoals;
      updatedStandings[loser].goalsAgainst =
        updatedStandings[loser]?.goalsAgainst + winningGoals || winningGoals;
      updatedStandings[loser].shotsFor =
        updatedStandings[loser]?.shotsFor + losingShots || losingShots;
      updatedStandings[loser].shotsAgainst =
        updatedStandings[loser]?.shotsAgainst + winningShots || winningShots;

      // Record vs opponents
      if (!updatedStandings[winner].vs) {
        updatedStandings[winner].vs = {};
      }
      if (!updatedStandings[loser].vs) {
        updatedStandings[loser].vs = {};
      }
      updatedStandings[winner].vs[loserId] = updatedStandings[winner].vs[
        loserId
      ]
        ? updatedStandings[winner].vs[loserId] + 2
        : 2;
      if (ot) {
        updatedStandings[loser].vs[winnerId] = updatedStandings[loser].vs[
          winnerId
        ]
          ? updatedStandings[loser].vs[winnerId] + 1
          : 1;
      } else {
        updatedStandings[loser].vs[winnerId] = updatedStandings[loser].vs[
          winnerId
        ]
          ? updatedStandings[loser].vs[winnerId]
          : 0;
      }

      // Team Colors
      updatedStandings[winner].color = homeWinner
        ? homeTeam.color
        : awayTeam.color;
      updatedStandings[loser].color = !homeWinner
        ? homeTeam.color
        : awayTeam.color;
      updatedStandings[winner].colorSecondary = homeWinner
        ? homeTeam.colorSecondary
        : awayTeam.colorSecondary;
      updatedStandings[loser].colorSecondary = !homeWinner
        ? homeTeam.colorSecondary
        : awayTeam.colorSecondary;
    }
    return updatedStandings;
  }, {});
};
