export function createPlayerExportData(
  id,
  playerId,
  lastName,
  firstName,
  contact,
  gbhpc,
  email,
  lastSeason,
  position,
  gamesPlayed,
  goals,
  assists,
  points,
  pim,
  draftRound,
  seasonId
) {
  return {
    id,
    playerId,
    lastName,
    firstName,
    position: position.slice(0, 1),
    contact,
    gbhpc,
    email,
    lastSeason,
    gamesPlayed: Number(gamesPlayed),
    goals: Number(goals),
    assists: Number(assists),
    points: Number(points),
    pim: Number(pim),
    draftRound,
    seasonId,
  };
}
export function createPlayerExportTotalData(
  id,
  lastName,
  firstName,
  contact,
  gbhpc,
  email,
  lastSeason,
  position,
  gamesPlayed,
  goals,
  assists,
  points,
  pim,
  avgRd,
  lastRd
) {
  return {
    id,
    lastName,
    firstName,
    contact,
    gbhpc,
    email,
    lastSeason,
    position: position.slice(0, 1),
    gamesPlayed: Number(gamesPlayed),
    goals: Number(goals),
    assists: Number(assists),
    points: Number(points),
    pim: Number(pim),
    avgRd: avgRd > 0 ? avgRd : null,
    lastRd,
  };
}
