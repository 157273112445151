import React from 'react';
import logo from 'assets/cheevers-black-border.svg';

const MerchPage = () => {
  // useEffect(() => {
  //   const scriptSrc = document.createElement("script");
  //   const script = document.createElement("script");
  //   scriptSrc.async = true;
  //   script.async = true;
  //   scriptSrc.src = "https://d2c-cta.s3-us-west-2.amazonaws.com/build/sqd/index.js";
  //   script.innerHTML = `
  //   var SQD = new sqd({
  //     container: 'sqd-container',
  //     moduleType: 'standard',
  //     lockerId: '315633',
  //     environment: 'production',
  //     theme: {
  //       fontFamily: "'Lato', sans-serif",
  //       color: '#2D3237',
  //       backgroundColor: '#FFFFFF',
  //       accentColor: '#8BC959'
  //     }
  //   });`;
  //   document.body.appendChild(scriptSrc);
  //   document.body.appendChild(script);
  // })
  return (
    <div id="sqd-container">
      <img src={logo} alt="GBH Players Club" />
    </div>
  );
};

export default MerchPage;
