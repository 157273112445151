import React from 'react';

const Caret = ({ height, fill, width }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 1030 638"
      style={{
        fill: fill ? fill : 'currentcolor',
        height: height,
        width: width,
      }}
    >
      <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" />
    </svg>
  );
};

export default Caret;
