import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { AdminRosters, Loading } from 'Components';
import { GET_ADMIN_ROSTER_DATA } from 'services/queries';
import { CURRENT_SEASON, sortPlayersByName } from 'utils';

const AdminRosterLanding = () => {
  const [seasonId, setSeasonId] = useState(CURRENT_SEASON);

  const {
    loading,
    error,
    data,
    refetch: refetchRosterData,
  } = useQuery(GET_ADMIN_ROSTER_DATA, {
    variables: { seasonId },
  });

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  const { players, teams } = data;

  const handleSelectSeason = (e) => {
    setSeasonId(e.target.value);
  };

  return (
    <div>
      <Helmet>
        <title>Rosters | Admin | GBH Players Club</title>
      </Helmet>
      {players.length > 0 && (
        <AdminRosters
          handleSelectSeason={handleSelectSeason}
          players={[...players].sort(sortPlayersByName)}
          refetchRosterData={refetchRosterData}
          seasonId={seasonId}
          teams={teams}
        />
      )}
    </div>
  );
};

export default AdminRosterLanding;
