export const sortPlayerStats = (playerA, playerB) => {
    const playerAPoints = playerA.goals + playerA.assists;
    const playerBPoints = playerB.goals + playerB.assists;
    if (playerAPoints > playerBPoints) {
      return -1;
    }
    if (playerAPoints < playerBPoints) {
      return 1;
    }
    if (playerA.goals > playerB.goals) {
      return -1;
    }
    if (playerA.goals < playerB.goals) {
      return 1;
    }
    if (playerB.position === 'GOALIE' || playerB.isGoalie) {
      return -1;
    }
    return 0;
  };
