import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { faChartBar, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import HockeySticks from 'assets/HockeySticks';
import { ArchiveLink, PageContainer } from 'Components';

const ArchiveWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const ArchiveHome = () => {
  return (
    <PageContainer pageHeading={<h1>League Archives</h1>}>
      <Helmet>
        <title>League Archives | GBH Players Club</title>
      </Helmet>
      <ArchiveWrap>
        <ArchiveLink
          link="/archives/stats"
          icon={faChartBar}
          text="Career Stats"
        />
        <ArchiveLink
          link="/archives/teams"
          Svg={HockeySticks}
          text="Historical Teams"
        />
        <ArchiveLink
          link="/archives/seasons"
          icon={faCalendarAlt}
          text="Historical Seasons"
        />
      </ArchiveWrap>
    </PageContainer>
  );
};

export default ArchiveHome;
