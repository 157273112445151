export const getPlayerGameStatsGamePage = (attendance, goals, penalties) => {
  const stats = { ...attendance };
  const playerIds = Object.keys(attendance);

  goals.forEach((goal) => {
    const { goalPlayer, primaryAssistPlayer, secondaryAssistPlayer } = goal;
    if (playerIds.includes(String(goalPlayer.id))) {
      const goalId = String(goalPlayer.id);
      stats[goalId].goals = stats[goalId].goals + 1;
    }
    if (
      primaryAssistPlayer &&
      playerIds.includes(String(primaryAssistPlayer.id))
    ) {
      const primaryAssistId = String(primaryAssistPlayer.id);
      stats[primaryAssistId].assists = stats[primaryAssistId].assists + 1;
    }
    if (
      secondaryAssistPlayer &&
      playerIds.includes(String(secondaryAssistPlayer.id))
    ) {
      const secondaryAssistId = String(secondaryAssistPlayer.id);
      stats[secondaryAssistId].assists = stats[secondaryAssistId].assists + 1;
    }
  });
  penalties.forEach((penalty) => {
    const penaltyPlayerId = String(penalty.player.id);
    if (playerIds.includes(penaltyPlayerId)) {
      stats[penaltyPlayerId].pim = stats[penaltyPlayerId].pim + penalty.minutes;
    }
  });

  return stats;
};

export const getPlayerGameStatsSchedulePage = (
  attendance,
  goals,
  penalties
) => {
  const playerIds = attendance.map((player) => player.playerId);
  const stats = playerIds.reduce((obj, playerId) => {
    const player = attendance.find(player => player.playerId === playerId)
    const name = `${player.player.firstName} ${player.player.lastName}`;
    return {
      ...obj,
      [playerId]: {
        playerId,
        name,
        goals: 0,
        assists: 0,
        pim: 0,
        points: 0,
      },
    };
  }, {});

  goals.forEach((currentGoal) => {
    const { goal, primaryAssist, secondaryAssist } = currentGoal;
    if (playerIds.includes(goal)) {
      stats[goal].goals = stats[goal].goals + 1;
      stats[goal].points = stats[goal].points + 1;
    }
    if (primaryAssist && playerIds.includes(primaryAssist)) {
      stats[primaryAssist].assists = stats[primaryAssist].assists + 1;
      stats[primaryAssist].points = stats[primaryAssist].points + 1;
    }
    if (secondaryAssist && playerIds.includes(secondaryAssist)) {
      stats[secondaryAssist].assists = stats[secondaryAssist].assists + 1;
      stats[secondaryAssist].points = stats[secondaryAssist].points + 1;
    }
  });
  penalties.forEach((penalty) => {
    if (playerIds.includes(penalty)) {
      stats[penalty].pim = stats[penalty].pim + penalty.minutes;
    }
  });

  return stats;
};
