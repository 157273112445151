import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { LoadingPage, PlayerPage } from 'Components';
import { GET_PLAYER_INFO } from 'services/queries';
import { CURRENT_SEASON } from 'utils';

const PlayerPageContainer = () => {
  const [seasonId, setSeasonId] = useState(CURRENT_SEASON);
  const { id } = useParams();
  const playerId = Number(id);

  const { loading, error, data } = useQuery(GET_PLAYER_INFO, {
    variables: { playerId, currentSeasonId: seasonId },
  });

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  return (
    <PlayerPage
      playerData={data.players[0]}
      seasonId={seasonId}
      setSeasonId={setSeasonId}
    />
  );
};

export default PlayerPageContainer;
