import { createSeasonStatData } from 'utils';
export const createTeamSkaterData = ({ roster, schedule, teamId }) =>
  roster
    .map((player) => {
      const {
        id: playerId,
        firstName,
        lastName,
        jersey,
        jerseySecondary,
        position,
        positionSecondary,
      } = player;

      let seasonGamesPlayed = 0;
      let seasonGoals = 0;
      let seasonAssists = 0;
      let seasonPim = 0;

      schedule.forEach((game) => {
        const { attendance, awayGoalie, goals, home, homeGoalie, penalties } =
          game;
        const isHome = teamId === home;
        const isGoalie = isHome
          ? homeGoalie === playerId
          : awayGoalie === playerId;
        const gamePlayed = attendance.find(
          (att) => att.teamId === teamId && att.playerId === playerId
        );
        if (isGoalie || !gamePlayed) {
          return;
        }
        seasonGamesPlayed += 1;
        goals.forEach((goal) => {
          if (goal.goal === playerId) {
            seasonGoals += 1;
          }
          if ([goal.primaryAssist, goal.secondaryAssist].includes(playerId)) {
            seasonAssists += 1;
          }
        });
        penalties.forEach((pen) => {
          if (pen.playerId === playerId) {
            seasonPim += pen.minutes;
          }
        });
      });

      const positionString = `${position.slice(0, 1)}${
        positionSecondary?.length ? `/${positionSecondary.slice(0, 1)}` : ''
      }`;

      return createSeasonStatData(
        { id: playerId },
        {
          lastName,
          firstName,
          jersey: jersey
            ? `${jersey}${jerseySecondary ? ` / ${jerseySecondary}` : ''}`
            : null,
          position: positionString,
          gamesPlayed: seasonGamesPlayed,
          goals: seasonGoals,
          assists: seasonAssists || 0,
          points: seasonGoals + seasonAssists || 0,
          pim: seasonPim || 0,
          gpg: seasonGamesPlayed
            ? (seasonGoals / seasonGamesPlayed).toFixed(2)
            : 0,
          apg: seasonGamesPlayed
            ? (seasonAssists / seasonGamesPlayed).toFixed(2)
            : 0,
          ppg: seasonGamesPlayed
            ? ((seasonGoals + seasonAssists) / seasonGamesPlayed).toFixed(2)
            : 0,
          pimpg: seasonGamesPlayed
            ? (seasonPim / seasonGamesPlayed).toFixed(2)
            : 0,
        }
      );
    })
    .filter((entry) => entry.gamesPlayed > 0);
