import React from 'react';
import styled from 'styled-components';
import { Table, TeamName } from 'Components';
import { design, makeTitle } from 'utils';

const SectionHeading = styled.h2`
  margin-top: ${design.spacing[3]};
`;

function createData(
  id,
  seasonId,
  captainId,
  leaderId,
  team,
  captain,
  leadingScorer,
  gamesPlayed,
  goals,
  assists,
  points
) {
  return {
    id,
    seasonId,
    captainId,
    leaderId,
    team,
    captain,
    leadingScorer,
    gamesPlayed,
    goals,
    assists,
    points,
  };
}
const teamsHeadCells = [
  { id: 'team', numeric: false, label: 'Team Name' },
  { id: 'captain', numeric: false, label: 'Captain' },
  { id: 'leadingScorer', numeric: false, label: 'Leading Scorer' },
  { id: 'gamesPlayed', numeric: true, label: 'GP' },
  { id: 'goals', numeric: true, label: 'G' },
  { id: 'assists', numeric: true, label: 'A' },
  { id: 'points', numeric: true, label: 'PTS' },
];

const ArchiveSeasonTeamsTable = ({ division, gbhpc, seasonId, teams }) => {
  const teamData = [...teams]
    .sort((a, b) => {
      if (a.champion) {
        return -1;
      }
      if (b.champion) {
        return 1;
      }
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    })
    .map((team) => {
      const {
        id: teamId,
        champion,
        color,
        colorSecondary,
        name,
        captainPlayer,
        skaterStats,
      } = team;

      const leader = [...skaterStats].sort((a, b) => {
        if (a.assists + a.goals > b.assists + b.goals) {
          return -1;
        }
        if (a.assists + a.goals < b.assists + b.goals) {
          return 1;
        }
        if (a.gamesPlayed > b.gamesPlayed) {
          return 1;
        }
        if (a.gamesPlayed < b.gamesPlayed) {
          return -1;
        }
        return 0;
      })[0];

      return createData(
        teamId,
        seasonId,
        captainPlayer?.id || 0,
        leader.playerByPlayer.id,
        <TeamName
          name={name}
          champion={champion}
          color={color}
          colorSecondary={colorSecondary}
        />,
        captainPlayer
          ? `${captainPlayer.firstName} ${captainPlayer.lastName}`
          : '',
        `${leader.playerByPlayer.firstName} ${leader.playerByPlayer.lastName}`,
        leader.gamesPlayed,
        leader.goals,
        leader.assists,
        leader.goals + leader.assists
      );
    });


  const teamRowLink = gbhpc ? {
    page: '/team',
    param: ['seasonId', 'id'],
  } : {
    page: '/archives/team',
    param: 'id'
  }
  const teamsRowLinks = {
    team: teamRowLink,
    captain: {
      page: '/player',
      param: 'captainId',
    },
    leadingScorer: {
      page: '/player',
      param: 'leaderId',
    },
  };

  return (
    <section>
      <SectionHeading>
        {division && division !== '0'
          ? `${makeTitle({ title: division })}`
          : 'Teams'}
      </SectionHeading>
      <Table
        defaultOrder="desc"
        defaultOrderBy="ppg"
        enablePagination={false}
        headCells={teamsHeadCells}
        rowLinks={teamsRowLinks}
        rows={teamData}
        size="small"
      />
    </section>
  );
};

export default ArchiveSeasonTeamsTable;
