import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { design } from 'utils';
const { REACT_APP_ADMIN_COOKIE, REACT_APP_ADMIN_TOKEN, REACT_APP_ADMIN_KEY } =
  process.env;

const AdminLoginContainer = styled.form`
  margin: calc(${design.spacing[8]} * 2) auto;
  text-align: center;
`;
const ButtonWrap = styled.div`
  margin-top: ${design.spacing[4]};
  button {
    background-color: ${design.colors.primary};
    border: 2px solid ${design.colors.primary};
    border-radius: ${design.radius[1]};
    color: ${design.colors.white};
    cursor: pointer;
    font-size: ${design.fontSizes[2]};
    padding: ${design.spacing[1]} ${design.spacing[3]};
    &:hover {
      background-color: ${design.colors.secondary};
    }
  }
`;

const Input = styled.input`
  -webkit-text-security: disc;
  font-size: ${design.fontSizes[2]};
  padding: ${design.spacing[1]} ${design.spacing[2]};
`;

const LoginPage = () => {
  const [inputValue, setInputValue] = useState('');

  const history = useHistory();

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValue === REACT_APP_ADMIN_KEY) {
      Cookies.set(REACT_APP_ADMIN_COOKIE, REACT_APP_ADMIN_TOKEN, {
        expires: 365,
      });
      history.go(0);
    }
  };

  return (
    <>
      <h1>Admin Login</h1>
      <AdminLoginContainer>
        <Input value={inputValue} onChange={handleInput} type="text" />
        <ButtonWrap>
          <button type="submit" onClick={handleSubmit}>
            Log In
          </button>
        </ButtonWrap>
      </AdminLoginContainer>
    </>
  );
};

export default LoginPage;
