import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import {
  GameHeading,
  GameSummary,
  GoalsBody,
  LoadingPage,
  PageContainer,
} from 'Components';
import { GET_SINGLE_GAME_INFO } from 'services/queries';
import { StyledTable, ScrollWrapper } from 'styles';

const HeaderRow = styled.tr`
  th {
    text-align: left;
  }
`;
const GamePage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_SINGLE_GAME_INFO, {
    variables: { id },
  });

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const gameData = data.schedule[0];

  const {
    awayTeam: { name: awayTeamName, id: awayTeamId, abbreviation: awayAbbr },
    homeTeam: { name: homeTeamName, id: homeTeamId, abbreviation: homeAbbr },
    goals,
  } = gameData;

  const goalsByPeriod = {};
  goals.forEach((goal) => {
    if (Object.keys(goalsByPeriod).includes(String(goal.period))) {
      goalsByPeriod[goal.period].push(goal);
    } else {
      goalsByPeriod[goal.period] = [goal];
    }
  });

  const awayTeamAbbr = awayAbbr
    ? awayAbbr
    : awayTeamName.slice(0, 3).toUpperCase();
  const homeTeamAbbr = homeAbbr
    ? homeAbbr
    : homeTeamName.slice(0, 3).toUpperCase();
  let periodStartScoreAway = 0;
  let periodStartScoreHome = 0;

  return (
    <PageContainer pageHeading={<GameHeading gameData={gameData} />}>
      <Helmet>
        <title>
          {awayTeamName} @ {homeTeamName} | GBH Players Club
        </title>
      </Helmet>
      <ScrollWrapper>
        <StyledTable>
          <thead>
            <HeaderRow>
              <th>Time</th>
              <th>Score</th>
              <th>Goal</th>
              <th>Assist #1</th>
              <th>Assist #2</th>
            </HeaderRow>
          </thead>
          {Object.entries(goalsByPeriod).map(([period, periodGoals]) => {
            if (period > 1 && goalsByPeriod[period - 1]) {
              periodStartScoreAway += goalsByPeriod[period - 1].filter(
                (goal) => goal.teamId === awayTeamId
              ).length;
              periodStartScoreHome += goalsByPeriod[period - 1]?.filter(
                (goal) => goal.teamId === homeTeamId
              ).length;
            }
            return (
              <GoalsBody
                key={period}
                awayTeamAbbr={awayTeamAbbr}
                periodGoals={periodGoals}
                homeTeamAbbr={homeTeamAbbr}
                homeTeamId={homeTeamId}
                period={period}
                periodStartScoreAway={periodStartScoreAway}
                periodStartScoreHome={periodStartScoreHome}
              />
            );
          })}
        </StyledTable>
      </ScrollWrapper>
      <GameSummary gameData={gameData} />
    </PageContainer>
  );
};

export default GamePage;
