import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { ColorBlock, ScoreBlock } from 'Components';
import { DeleteButton } from 'styles';
import { AdminGameContext } from 'services/context';
import { DELETE_GOAL } from 'services/mutations';

const LastCell = styled.td`
  display: flex;
  align-items: center;
  button {
    margin-left: auto;
  }
`;

const GoalRow = ({
  awayScore,
  awayTeamAbbr,
  en,
  goalScorer,
  goalTime,
  gw,
  homeScore,
  homeTeamAbbr,
  id,
  pp,
  primaryAssistPlayer,
  secondaryAssistPlayer,
  sh,
  teamColor,
}) => {
  const { isAdmin, refetchGameData } = useContext(AdminGameContext);
  const [deleteGoal] = useMutation(DELETE_GOAL);

  const handleDeleteButton = () => {
    deleteGoal({ variables: { goalId: id } }).then(() => {
      refetchGameData();
    });
  };

  return (
    <tr>
      <td>{goalTime}</td>
      <td>
        <ScoreBlock
          awayScore={awayScore}
          awayTeamAbbr={awayTeamAbbr}
          homeScore={homeScore}
          homeTeamAbbr={homeTeamAbbr}
        />
      </td>
      <td>
        <ColorBlock color={teamColor} />
        {goalScorer}
        {pp ? ' (PP)' : sh ? ' (SH)' : ''}
        {en ? ' (EN)' : ''}
        {gw ? ' (GWG)' : ''}
      </td>
      <td>
        {primaryAssistPlayer
          ? `${primaryAssistPlayer?.firstName} ${primaryAssistPlayer?.lastName}`
          : ''}
      </td>
      <LastCell>
        {secondaryAssistPlayer
          ? `${secondaryAssistPlayer?.firstName} ${secondaryAssistPlayer?.lastName}`
          : ''}
        {isAdmin && (
          <DeleteButton onClick={handleDeleteButton}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </DeleteButton>
        )}
      </LastCell>
    </tr>
  );
};

GoalRow.propTypes = {
  awayScore: PropTypes.number.isRequired,
  awayTeamAbbr: PropTypes.string.isRequired,
  goalScorer: PropTypes.string.isRequired,
  goalTime: PropTypes.string,
  gw: PropTypes.bool,
  homeScore: PropTypes.number.isRequired,
  homeTeamAbbr: PropTypes.string.isRequired,
  primaryAssistPlayer: PropTypes.object,
  secondaryAssistPlayer: PropTypes.object,
  teamColor: PropTypes.string.isRequired,
};
GoalRow.defaultProps = {
  goalTime: null,
  gw: false,
  primaryAssistPlayer: {},
  secondaryAssistPlayer: {},
};

export default GoalRow;
