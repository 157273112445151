import React from 'react';
import PropTypes from 'prop-types';
import { SummaryTable } from 'styles';

const SkaterStatSummary = ({ stats }) => {
  const { gamesPlayed, goals, assists, pim } = stats;
  return (
    <SummaryTable>
      <table>
        <thead>
          <tr>
            <th>GP</th>
            <th>G</th>
            <th>A</th>
            <th>PTS</th>
            <th>PIM</th>
            <th className='hide-sm'>G/GM</th>
            <th className='hide-sm'>A/GM</th>
            <th className='hide-sm'>PTS/GM</th>
            <th className='hide-sm'>PIM/GM</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{gamesPlayed}</td>
            <td>{goals}</td>
            <td>{assists}</td>
            <td>{goals + assists}</td>
            <td>{pim}</td>
            <td className='hide-sm'>{(goals / gamesPlayed).toFixed(2)}</td>
            <td className='hide-sm'>{(assists / gamesPlayed).toFixed(2)}</td>
            <td className='hide-sm'>{((goals + assists) / gamesPlayed).toFixed(2)}</td>
            <td className='hide-sm'>{(pim / gamesPlayed).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
    </SummaryTable>
  );
};

SkaterStatSummary.propTypes = {
  stats: PropTypes.shape({
    gamesPlayed: PropTypes.number.isRequired,
    goals: PropTypes.number.isRequired,
    assists: PropTypes.number.isRequired,
    pim: PropTypes.number.isRequired,
  }).isRequired,
};

export default SkaterStatSummary;
