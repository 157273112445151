import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageHeading, SiteHeader } from 'Components';
import { GlobalStyles } from 'styles';
import { design } from 'utils';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${design.colors.primary};
  color: ${design.colors.white};
  ${(props) => props.center && `text-align: center;`}
  ${(props) => props.vcenter && `margin: auto;`}
`;

const PageContent = styled.main`
  background-color: ${design.colors.white};
  border: solid ${design.colors.gray[8]};
  border-width: 0 3px;
  color: ${design.colors.black};
  flex-grow: 1;
  margin: 0 auto;
  max-width: ${design.breakpoints[14]};
  padding: ${design.spacing[4]} ${design.spacing[4]} ${design.spacing[8]};
  position: relative;
  width: 100%;
  z-index: 2;
  @media screen and (max-width: ${design.breakpoints[14]}) {
    border-width: 0;
  }
  @media screen and (max-width: ${design.breakpoints[10]}) {
    padding: ${design.spacing[4]} ${design.spacing[2]} ${design.spacing[8]};
  }
`;

const PageContainer = (props) => {
  const { center, children, isAdmin, pageHeading, showHeader, vcenter } = props;
  return (
    <Container center={center} vcenter={vcenter}>
      <GlobalStyles />
      {showHeader && <SiteHeader isAdmin={isAdmin} />}
      <PageContent>
        {pageHeading && <PageHeading>{pageHeading}</PageHeading>}
        {children}
      </PageContent>
    </Container>
  );
};

PageContainer.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  pageHeading: PropTypes.node,
  showHeader: PropTypes.bool,
  vcenter: PropTypes.bool,
};

PageContainer.defaultProps = {
  center: false,
  pageHeading: null,
  showHeader: true,
  vcenter: false,
};

export default PageContainer;
