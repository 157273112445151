import React from 'react';
import { StatChartGoalie, StatChartSkater } from 'Components';

const StatChart = ({ statData, type }) => {
  return (
    <>
      {type === 'skater' && <StatChartSkater statData={statData} />}
      {type === 'goalie' && <StatChartGoalie statData={statData} />}
    </>
  );
};

export default StatChart;
