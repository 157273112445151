import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { AdminGameContext } from 'services/context';
import { AdminAttendanceCheckbox } from 'Components';
import {
  REMOVE_AWAY_GOALIE,
  REMOVE_HOME_GOALIE,
  SET_AWAY_GOALIE,
  SET_HOME_GOALIE,
} from 'services/mutations';
import {
  AddButton,
  AttendanceInner,
  AttendanceList,
  AttendancePlayerName,
  Button,
  ButtonWrap,
  Caption,
  DeleteButton,
  ErrorText,
  GoalieButton,
  SuccessText,
  SubHeading,
  SubList,
} from 'styles';
import goalieMaskAway from 'assets/goalie-away.png';
import goalieMaskHome from 'assets/goalie-home.png';

const AdminAttendanceList = ({
  attendanceData,
  delAttendLoading,
  gameId,
  goalieId,
  handleAddSub,
  handleRemove,
  insertAttendance,
  isHome,
  refetchAttendance,
  roster,
  selectedAttendance,
  setAttendData,
  setAttendError,
  setAttendLoading,
  setSelectedAttendance,
  subGoalies,
  teamId,
  unassignedSubs,
}) => {
  const [removeAwayGoalie] = useMutation(REMOVE_AWAY_GOALIE);
  const [removeHomeGoalie] = useMutation(REMOVE_HOME_GOALIE);
  const [setAwayGoalie] = useMutation(SET_AWAY_GOALIE);
  const [setHomeGoalie] = useMutation(SET_HOME_GOALIE);

  const { refetchGameData } = useContext(AdminGameContext);

  const handleRemoveGoalie = () => {
    const removeMutation = isHome ? removeHomeGoalie : removeAwayGoalie;
    removeMutation({ variables: { gameId } }).then(() => refetchGameData());
  };

  const handleSetGoalie = (playerId) => () => {
    const setMutation = isHome ? setHomeGoalie : setAwayGoalie;
    setMutation({ variables: { gameId, playerId } }).then(() =>
      refetchGameData()
    );
  };

  const handleSaveAttendance = (e) => {
    insertAttendance({ variables: { attendance: selectedAttendance } }).then(
      () => refetchAttendance()
    );
  };

  return (
    <AttendanceInner key={teamId}>
      <AttendanceList>
        {roster.map((player) => {
          const currentPlayer = player.players ? player.players[0] : player;
          const playerId = currentPlayer.id;
          const attended = attendanceData.attendance.find(
            (att) => att.playerId === playerId
          );
          return (
            <li key={playerId}>
              <label htmlFor={playerId}>
                <span>
                  {attended ? (
                    <DeleteButton
                      disabled={delAttendLoading}
                      onClick={handleRemove(attended.id)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </DeleteButton>
                  ) : (
                    <AdminAttendanceCheckbox
                      disabled={attended}
                      gameId={gameId}
                      playerId={playerId}
                      selectedAttendance={selectedAttendance}
                      setSelectedAttendance={setSelectedAttendance}
                      teamId={teamId}
                    />
                  )}
                </span>
                <span>
                  {currentPlayer.jersey}
                  {currentPlayer.jerseySecondary
                    ? ` / ${currentPlayer.jerseySecondary}`
                    : ''}
                </span>
                <AttendancePlayerName>
                  {currentPlayer.firstName} {currentPlayer.lastName}
                  {playerId === goalieId && (
                    <GoalieButton removeButton onClick={handleRemoveGoalie}>
                      <img
                        src={isHome ? goalieMaskHome : goalieMaskAway}
                        alt="Goalie"
                      />
                    </GoalieButton>
                  )}
                  {player.sub && <Caption>[SUB]</Caption>}
                  {!goalieId && attended && (
                    <GoalieButton setButton onClick={handleSetGoalie(playerId)}>
                      <img
                        src={isHome ? goalieMaskHome : goalieMaskAway}
                        alt="Goalie"
                      />
                    </GoalieButton>
                  )}
                </AttendancePlayerName>
              </label>
            </li>
          );
        })}
      </AttendanceList>
      <ButtonWrap>
        <Button
          type="button"
          onClick={handleSaveAttendance}
          disabled={!selectedAttendance.length || setAttendLoading}
        >
          {setAttendLoading ? 'Saving...' : 'Save Attendance'}
        </Button>
        {setAttendData?.length === selectedAttendance.length && (
          <SuccessText>Success!</SuccessText>
        )}
        {setAttendError && <ErrorText>Error! Check the console!</ErrorText>}
      </ButtonWrap>
      <SubHeading>Sub Skaters</SubHeading>
      <SubList>
        {unassignedSubs.map((subPlayer) => {
          const subInfo = subPlayer;
          return (
            <li value={subInfo.id} key={subInfo.id}>
              {subInfo.firstName} {subInfo.lastName}
              <AddButton
                disabled={setAttendLoading}
                onClick={handleAddSub(subInfo.id, teamId)}
              >
                Add Sub
              </AddButton>
            </li>
          );
        })}
      </SubList>
      <SubHeading>Sub Goalies</SubHeading>
      <SubList>
        {subGoalies.map((subGoalie) => {
          const subInfo = subGoalie;
          return (
            <li value={subInfo.id} key={subInfo.id}>
              {subInfo.firstName} {subInfo.lastName}
              <AddButton
                disabled={setAttendLoading}
                onClick={handleAddSub(subInfo.id, teamId)}
              >
                Add Sub
              </AddButton>
            </li>
          );
        })}
      </SubList>
    </AttendanceInner>
  );
};

export default AdminAttendanceList;
