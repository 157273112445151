export const skaterSeasonsHeadCells = [
  { id: 'seasonId', numeric: false, label: 'Season' },
  { id: 'team', numeric: false, label: 'Team' },
  { id: 'gamesPlayed', numeric: true, label: 'GP' },
  { id: 'goals', numeric: true, label: 'G' },
  { id: 'assists', numeric: true, label: 'A' },
  { id: 'points', numeric: true, label: 'PTS' },
  { id: 'pim', numeric: true, label: 'PIM' },
  { id: 'gpg', numeric: true, label: 'G/GM' },
  { id: 'apg', numeric: true, label: 'A/GM' },
  { id: 'ppg', numeric: true, label: 'PTS/GM' },
  { id: 'pimpg', numeric: true, label: 'PIM/GM' },
  { id: 'league', numeric: false, label: 'League' },
];