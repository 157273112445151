import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  ArchiveHome,
  ArchiveSeason,
  ArchiveSeasons,
  ArchiveStats,
  ArchiveTeamPageContainer,
  ArchiveTeams,
} from 'Components';

const Archives = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ArchiveHome />
      </Route>
      <Route path={`${path}/stats`}>
        <ArchiveStats />
      </Route>
      <Route path={`${path}/teams`}>
        <ArchiveTeams />
      </Route>
      <Route path={`${path}/team/:id`}>
        <ArchiveTeamPageContainer />
      </Route>
      <Route path={`${path}/seasons`}>
        <ArchiveSeasons />
      </Route>
      <Route path={`${path}/season/:id`}>
        <ArchiveSeason />
      </Route>
    </Switch>
  );
};

export default Archives;
