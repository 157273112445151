import styled from 'styled-components';
import { design } from 'utils';

export const TeamStatWrapper = styled.section`
  display: grid;
  column-gap: ${design.spacing[2]};
  h2 {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  > div {
    margin-top: ${design.spacing[2]};
    table {
      width: 100%;
    }
    &:nth-child(2) {
      grid-row-start: 3;
    }
  }
  @media screen and (min-width: ${design.breakpoints[5]}) {
    grid-template-columns: 1fr 1fr;
    > div:nth-child(2) {
      grid-row-start: auto;
    }
  }
`;
