import React from 'react';
import styled from 'styled-components';
import { ScrollWrapper } from 'styles';
import { design } from 'utils';

const GoalieSection = styled.table`
  thead {
    border: 2px solid
      ${(props) =>
        props.isHome ? design.colors.gbhpcHome : design.colors.gbhpcAway};
    background-color: ${(props) =>
      props.isHome ? design.colors.gbhpcHome : design.colors.gbhpcAway};
    color: white;
    th {
      ${(props) => props.isWinner && 'font-weight: bold;'}
    }
  }
  th {
    padding: ${design.spacing[1]} ${design.spacing[2]};
    text-align: center;
  }
  tbody {
    border: solid ${design.colors.secondary};
    border-width: 0 2px 2px 2px;
    tr:nth-child(2n + 1) {
      background-color: ${design.colors.gray[1]};
    }
    td {
      padding: ${design.spacing[1]} ${design.spacing[2]};
      text-align: center;
    }
  }
`;

const GoalieGameStats = ({
  awayScore,
  awayShots,
  awayWinner,
  goals,
  goalies,
  homeScore,
  homeShots,
  homeTeamId,
  homeWinner,
  ot,
}) => {
  const sortedGoalies = [...goalies].sort((_goalieA, goalieB) => {
    if (goalieB.teamId === homeTeamId) {
      return 1;
    }
    return -1;
  });

  return sortedGoalies.map((goalie) => {
    const isHome = goalie.teamId === homeTeamId;
    const isWinner = isHome ? homeWinner : awayWinner;

    const eng = goals.filter((goal) =>
      isHome
        ? goal.teamId !== homeTeamId && goal.en
        : goal.teamId === homeTeamId && goal.en
    ).length;
    const shotsAgainst = isHome ? awayShots - eng : homeShots - eng;
    const goalsAgainst = isHome
      ? awayScore - eng - (ot === 'SO' && awayWinner ? 1 : 0)
      : homeScore - eng - (ot === 'SO' && homeWinner ? 1 : 0);
    const saves = shotsAgainst - goalsAgainst;

    const savePerc = saves / shotsAgainst;

    return (
      <ScrollWrapper key={goalie.id}>
        <GoalieSection key={goalie.id} isHome={isHome} isWinner={isWinner}>
          <thead>
            <tr>
              <th colSpan={4}>
                {isWinner ? 'W - ' : 'L - '}
                {goalie.firstName} {goalie.lastName}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Goals Against</th>
              <th>Shots</th>
              <th>Saves</th>
              <th>Save %</th>
            </tr>
            <tr>
              <td>{goalsAgainst}</td>
              <td>{shotsAgainst}</td>
              <td>{saves}</td>
              <td>
                {savePerc === 1
                  ? savePerc.toFixed(3).toString()
                  : savePerc.toFixed(3).toString().slice(1)}
              </td>
            </tr>
          </tbody>
        </GoalieSection>
      </ScrollWrapper>
    );
  });
};

export default GoalieGameStats;
