import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
const { REACT_APP_AUTH_COOKIE, REACT_APP_AUTH_TOKEN, REACT_APP_CLUB_KEY } =
  process.env;

const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
`;
const LoginInner = styled.form`
  margin: auto;
  text-align: center;
`;

const Input = styled.input`
  -webkit-text-security: disc;
`;

const LoginPage = () => {
  const [inputValue, setInputValue] = useState('');

  const history = useHistory();

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.toLowerCase() === REACT_APP_CLUB_KEY) {
      Cookies.set(REACT_APP_AUTH_COOKIE, REACT_APP_AUTH_TOKEN, {
        expires: 365,
      });
      history.go(0);
    }
  };

  return (
    <LoginContainer>
      <LoginInner>
        <Input value={inputValue} onChange={handleInput} type="text" />
        <button type="submit" onClick={handleSubmit}>
          Go
        </button>
      </LoginInner>
    </LoginContainer>
  );
};

export default LoginPage;
