import { getPlayerGameStatsSchedulePage, sortPlayerStats } from './index';

export const getGameStars = (game) => {
  const {
    attendance,
    away,
    awayGoalie,
    awayScore,
    awayShots,
    goals,
    home,
    homeGoalie,
    homeScore,
    homeShots,
    penalties,
  } = game;

  const homeAttendance = attendance.filter((att) => att.teamId === home);
  const awayAttendance = attendance.filter((att) => att.teamId === away);
  const homeSkaterStats = getPlayerGameStatsSchedulePage(
    homeAttendance,
    goals,
    penalties
  );
  const awaySkaterStats = getPlayerGameStatsSchedulePage(
    awayAttendance,
    goals,
    penalties
  );

  const awayEng = goals.filter(
    (goal) => goal.en && goal.teamId === away
  ).length;
  const homeEng = goals.filter(
    (goal) => goal.en && goal.teamId === home
  ).length;

  const topHomeScorer = Object.values(homeSkaterStats).sort(
    (playerA, playerB) => sortPlayerStats(playerA, playerB)
  )[0];
  const topAwayScorer = Object.values(awaySkaterStats).sort(
    (playerA, playerB) => sortPlayerStats(playerA, playerB)
  )[0];

  const awayGoalieInfo = attendance.filter(
    (att) => att.playerId === awayGoalie
  )[0];
  const homeGoalieInfo = attendance.filter(
    (att) => att.playerId === homeGoalie
  )[0];

  const homeStar = calculateStar(
    homeGoalieInfo,
    awayShots,
    awayScore - awayEng,
    topHomeScorer
  );
  const awayStar = calculateStar(
    awayGoalieInfo,
    homeShots,
    homeScore - homeEng,
    topAwayScorer
  );

  return { awayStar, homeStar };
};

const calculateStar = (goalieInfo, shotsAgainst, goalsAgainst, topScorer) => {
  const goalieSvp = (shotsAgainst - goalsAgainst) / shotsAgainst;
  const goalieSvpString =
    goalieSvp === 1 ? goalieSvp.toFixed(3) : goalieSvp.toFixed(3).slice(1);
  const goalieStar = {
    link: `/player/${goalieInfo.player.playerId || goalieInfo.playerId}`,
    name: `${goalieInfo.player.firstName} ${goalieInfo.player.lastName}`,
    stats: `${goalsAgainst}ga — ${
      shotsAgainst - goalsAgainst
    }sv — ${goalieSvpString}sv%`,
  };
  const skaterStar = {
    link: `/player/${topScorer.playerId}`,
    name: topScorer.name,
    stats: `${topScorer.goals}g - ${topScorer.assists}a — ${topScorer.points}p`,
  };

  if (
    (goalsAgainst === 0 && shotsAgainst > 29) ||
    (goalsAgainst === 0 && topScorer.goals <= 5) ||
    (goalieSvp > 0.93 && topScorer.points <= 5) ||
    (goalieSvp > 0.9 && topScorer.points < 4) ||
    (goalieSvp > 0.85 && topScorer.points < 2) ||
    topScorer.points === 0
  ) {
    return goalieStar;
  } else {
    return skaterStar;
  }
};
