import React from 'react';
import PropTypes from 'prop-types';
import { GoalRow } from 'Components';
import { convertTimestampToSeconds, design, getPeriodTitle } from 'utils';

const GoalsBody = ({
  awayTeamAbbr,
  periodGoals,
  homeTeamAbbr,
  homeTeamId,
  period,
  periodStartScoreAway,
  periodStartScoreHome,
}) => {
  let currentAwayScore = periodStartScoreAway;
  let currentHomeScore = periodStartScoreHome;
  return (
    <tbody>
      <tr>
        <th colSpan={5}>{getPeriodTitle(period)}</th>
      </tr>
      {periodGoals
        .sort((goalA, goalB) => {
          if (!goalA.time || !goalB.time) {
            return 0;
          }
          const goalASeconds = convertTimestampToSeconds(goalA.time);
          const goalBSeconds = convertTimestampToSeconds(goalB.time);
          if (goalASeconds > goalBSeconds) {
            return -1;
          }
          if (goalASeconds < goalBSeconds) {
            return 1;
          }
          return 0;
        })
        .map((goal) => {
          const {
            id: goalId,
            time: goalTime,
            teamId,
            goalPlayer: { firstName: goalFirst, lastName: goalLast },
            primaryAssistPlayer,
            secondaryAssistPlayer,
            en,
            pp,
            sh,
            gw,
          } = goal;
          if (teamId === homeTeamId) {
            currentHomeScore++;
          } else {
            currentAwayScore++;
          }
          return (
            <GoalRow
              key={goalId}
              awayScore={currentAwayScore}
              awayTeamAbbr={awayTeamAbbr}
              en={en}
              goalScorer={`${goalFirst} ${goalLast}`}
              goalTime={goalTime}
              gw={gw}
              homeScore={currentHomeScore}
              homeTeamAbbr={homeTeamAbbr}
              id={goalId}
              pp={pp}
              primaryAssistPlayer={primaryAssistPlayer}
              secondaryAssistPlayer={secondaryAssistPlayer}
              sh={sh}
              teamColor={
                teamId === homeTeamId
                  ? design.colors.gbhpcHome
                  : design.colors.gbhpcAway
              }
            />
          );
        })}
    </tbody>
  );
};

GoalsBody.propTypes = {
  awayTeamAbbr: PropTypes.string.isRequired,
  periodGoals: PropTypes.array.isRequired,
  homeTeamAbbr: PropTypes.string.isRequired,
  homeTeamId: PropTypes.number.isRequired,
  period: PropTypes.string.isRequired,
};
GoalsBody.defaultProps = {
  awayAbbr: null,
  homeAbbr: null,
};

export default GoalsBody;
