import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CenterTd } from 'styles';
import { design, formatTime, getGameStars, getWinner } from 'utils';
import starAway from 'assets/starAway.svg';
import starHome from 'assets/starHome.svg';

const ScheduleTbody = styled.tbody`
  tr td {
    padding: ${design.spacing[2]};
  }
  .winner {
    font-weight: bold;
  }
  .homeTeam {
    text-align: right;
  }
  .isPlayoffs {
    background-color: ${design.colors.gbhpcHome};
  }
`;
const GameRow = styled.tr`
  ${(props) =>
    props.cancel &&
    `
    &,
    a {
      color: ${design.colors.gray[5]};
      font-style: italic;
    }
    .awayTeam,
    .homeTeam {
      text-decoration: line-through;
    }
  `}
`;
const ResultTd = styled.td`
  ${(props) =>
    props.cancel &&
    `
  font-weight: bold;
  `}
`;
const PickupCell = styled.td`
  text-align: center;
`;
const ExtraStatsP = styled.p`
  align-items: center;
  display: flex;
  font-size: ${design.fontSizes[1]};
  font-weight: normal;
  margin-top: ${design.spacing[2]};
  ${(props) =>
    props.home &&
    `
  justify-content: flex-end;`}
`;
const GameStarStats = styled.span`
  font-variant-caps: small-caps;
  margin-left: ${design.spacing[1]};
`;

const ScheduleBody = ({ date, games, inlineDate, seasonId, showEdit }) => {
  const isPlayoffs = games.some((game) => game.playoffs);
  const note = games[0].note;

  return (
    <ScheduleTbody>
      {!inlineDate && (
        <tr>
          <th
            colSpan={showEdit ? 10 : 9}
            className={isPlayoffs ? 'isPlayoffs' : ''}
          >
            {isPlayoffs ? 'Playoffs - ' : ''}
            {note ? `${note} - ` : ''}
            {date}
          </th>
        </tr>
      )}
      {games.map((game) => {
        const {
          id,
          awayScore,
          awayShots,
          awayTeam,
          cancel,
          homeScore,
          homeShots,
          homeTeam,
          pickup,
          playoffs,
          ot,
          rinkLocation: { id: rinkId, name: rinkName },
          time,
        } = game;

        const awayTeamName = awayTeam?.name || 'TBD';
        const awayTeamId = awayTeam?.id;
        const homeTeamName = homeTeam?.name || 'TBD';
        const homeTeamId = homeTeam?.id;

        const { awayWinner, homeWinner } = getWinner({ awayScore, homeScore });
        const gameCompleted = homeShots > 0 && awayShots > 0;
        let gameStars;
        if (gameCompleted) {
          gameStars = getGameStars(game);
        }
        return (
          <Fragment key={id}>
            <GameRow cancel={cancel}>
              {inlineDate && (
                <td>
                  {game.date}
                  {playoffs && ' *'}
                </td>
              )}
              <td>{formatTime(time)}</td>
              {pickup ? (
                <PickupCell colSpan={7}>Pickup Skate</PickupCell>
              ) : (
                <>
                  <td className={homeWinner ? 'winner homeTeam' : 'homeTeam'}>
                    {homeTeamId ? (
                      <a href={`/team/${seasonId}/${homeTeamId}`}>
                        {homeTeamName}
                      </a>
                    ) : (
                      homeTeamName
                    )}
                    {gameCompleted && (
                      <ExtraStatsP home>
                        <img
                          src={starHome}
                          alt="Home Star of the Game"
                          style={{ marginRight: design.spacing[1] }}
                        />
                        <a href={gameStars.homeStar.link}>
                          {gameStars.homeStar.name}
                        </a>
                        :{' '}
                        <GameStarStats>
                          {gameStars.homeStar.stats}
                        </GameStarStats>
                      </ExtraStatsP>
                    )}
                  </td>
                  <CenterTd className={homeWinner ? 'winner score' : 'score'}>
                    {homeScore}
                    {gameCompleted && <ExtraStatsP>{homeShots}</ExtraStatsP>}
                  </CenterTd>
                  <CenterTd>
                    -{gameCompleted && <ExtraStatsP>Shots</ExtraStatsP>}
                  </CenterTd>
                  <CenterTd className={awayWinner ? 'winner score' : 'score'}>
                    {awayScore}
                    {gameCompleted && <ExtraStatsP>{awayShots}</ExtraStatsP>}
                  </CenterTd>
                  <td className={awayWinner ? 'winner awayTeam' : 'awayTeam'}>
                    {awayTeamId ? (
                      <a href={`/team/${seasonId}/${awayTeamId}`}>
                        {awayTeamName}
                      </a>
                    ) : (
                      awayTeamName
                    )}
                    {gameCompleted && (
                      <ExtraStatsP>
                        <img
                          src={starAway}
                          alt="Away Star of the Game"
                          style={{ marginRight: design.spacing[1] }}
                        />
                        <a href={gameStars.awayStar.link}>
                          {gameStars.awayStar.name}
                        </a>
                        :{' '}
                        <GameStarStats>
                          {gameStars.awayStar.stats}
                        </GameStarStats>
                      </ExtraStatsP>
                    )}
                  </td>
                  <td>{ot}</td>
                  <ResultTd cancel={cancel}>
                    {cancel && 'CANCELED'}
                    {!cancel &&
                      typeof homeScore === 'number' &&
                      typeof awayScore === 'number' && (
                        <Link to={`/game/${id}`}>Game Summary</Link>
                      )}
                  </ResultTd>
                </>
              )}
              <td>
                <Link to={`/rink/${rinkId}`}>{rinkName}</Link>
              </td>
              {showEdit && (
                <td>
                  <Link to={`/admin/game/${id}`}>Edit Score</Link>
                </td>
              )}
            </GameRow>
          </Fragment>
        );
      })}
    </ScheduleTbody>
  );
};

ScheduleBody.propTypes = {
  date: PropTypes.string,
  games: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      awayScore: PropTypes.number,
      awayTeam: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      date: PropTypes.string.isRequired,
      homeScore: PropTypes.number,
      homeTeam: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
      ot: PropTypes.string,
      playoffs: PropTypes.bool,
      rinkLocation: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      time: PropTypes.string.isRequired,
    })
  ).isRequired,
  inlineDate: PropTypes.bool,
  seasonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  showEdit: PropTypes.bool,
};

ScheduleBody.defaultProps = {
  date: '',
  inlineDate: false,
  showEdit: false,
};

export default ScheduleBody;
