import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  PageContainer,
  StatsBlock,
  StatsCurrentSeason,
  SeasonSelect,
  GoalieSkaterToggle,
} from 'Components';
import { HeadWrapGrid } from 'styles';
import { CURRENT_SEASON, design } from 'utils';

const ArchiveStats = () => {
  const [statType, setStatType] = useState('skater');
  const history = useHistory();
  const { id } = useParams();
  const seasonId = Number(id);

  const handleChangeSeason = (e) => {
    history.push(`/stats/${e.target.value}`);
  };

  return (
    <PageContainer
      pageHeading={
        <HeadWrapGrid styles={`margin-bottom: ${design.spacing[4]};`}>
          <GoalieSkaterToggle statType={statType} setStatType={setStatType} />
          <h1>Season Stats</h1>
          <div>
            <SeasonSelect onChange={handleChangeSeason} value={seasonId} />
          </div>
        </HeadWrapGrid>
      }
    >
      <Helmet>
        <title>Season Stats | GBH Players Club</title>
      </Helmet>
      {seasonId === CURRENT_SEASON ? (
        <StatsCurrentSeason statType={statType} />
      ) : (
        <StatsBlock
          seasonId={seasonId}
          showGoalieAssists
          showGoaliePim
          showGoalieTies
          showTeam
          statType={statType}
        />
      )}
    </PageContainer>
  );
};

export default ArchiveStats;
