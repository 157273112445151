import React from 'react';

export const AdminGameContext = React.createContext({
  attendance: {},
  currentAttendance: {},
  currentRoster: [],
  gameId: null,
  isAdmin: false,
  refetchGameData() {},
  teams: {},
});
