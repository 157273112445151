import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import chartTrendline from 'chartjs-plugin-trendline';
import { Line } from 'react-chartjs-2';
import { StatChartWrapper, Toggle } from 'styles';
import { design } from 'utils';

ChartJS.register(
  chartTrendline,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StatChartGoalie = ({ statData }) => {
  const [perGame, setPerGame] = useState(true);
  const labels = statData.map((stat) => stat.season);
  const win = statData.map((stat) => stat.win);
  const loss = statData.map((stat) => stat.loss);
  const ot = statData.map((stat) => stat.tie + stat.otl + stat.sol);
  const shotsAgainst = statData.map((stat) => stat.shotsAgainst);
  const saves = statData.map((stat) => stat.saves);
  const goalsAgainst = statData.map((stat) => stat.goalsAgainst);

  const winPerGame = statData.map((stat) => stat.win / stat.gamesPlayed);
  const lossPerGame = statData.map((stat) => stat.loss / stat.gamesPlayed);
  const otPerGame = statData.map(
    (stat) => (stat.tie + stat.otl + stat.sol) / stat.gamesPlayed
  );
  const shotsAgainstPerGame = statData.map(
    (stat) => stat.shotsAgainst / stat.gamesPlayed
  );
  const savesPerGame = statData.map((stat) => stat.saves / stat.gamesPlayed);
  const goalsAgainstPerGame = statData.map(
    (stat) => stat.goalsAgainst / stat.gamesPlayed
  );

  const statsTotal = [
    {
      label: 'W',
      backgroundColor: design.colors.gbhpcHome,
      borderColor: design.colors.gbhpcHome,
      data: win,
      trendlineLinear: {
        style: 'rgba(59, 146, 18, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'L',
      backgroundColor: 'rgb(173, 23, 45)',
      borderColor: 'rgb(173, 23, 45)',
      data: loss,
      trendlineLinear: {
        style: 'rgba(173, 23, 45, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'T/OTL/SOL',
      backgroundColor: 'rgb(219, 192, 13)',
      borderColor: 'rgb(219, 192, 13)',
      data: ot,
      trendlineLinear: {
        style: 'rgba(219, 192, 13, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Shots',
      backgroundColor: 'rgb(38, 117, 173)',
      borderColor: 'rgb(38, 117, 173)',
      data: shotsAgainst,
      trendlineLinear: {
        style: 'rgba(38, 117, 173, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Saves',
      backgroundColor: 'rgb(147, 66, 245)',
      borderColor: 'rgb(147, 66, 245)',
      data: saves,
      trendlineLinear: {
        style: 'rgba(147, 66, 245, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Goals Against',
      backgroundColor: 'rgb(252, 98, 15)',
      borderColor: 'rgb(252, 98, 15)',
      data: goalsAgainst,
      trendlineLinear: {
        style: 'rgba(252, 98, 15, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
  ];

  const statsPerGame = [
    {
      label: 'W',
      backgroundColor: design.colors.gbhpcHome,
      borderColor: design.colors.gbhpcHome,
      data: winPerGame,
      trendlineLinear: {
        style: 'rgba(59, 146, 18, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'L',
      backgroundColor: 'rgb(173, 23, 45)',
      borderColor: 'rgb(173, 23, 45)',
      data: lossPerGame,
      trendlineLinear: {
        style: 'rgba(173, 23, 45, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'T/OTL/SOL',
      backgroundColor: 'rgb(219, 192, 13)',
      borderColor: 'rgb(219, 192, 13)',
      data: otPerGame,
      trendlineLinear: {
        style: 'rgba(219, 192, 13, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Shots',
      backgroundColor: 'rgb(38, 117, 173)',
      borderColor: 'rgb(38, 117, 173)',
      data: shotsAgainstPerGame,
      trendlineLinear: {
        style: 'rgba(38, 117, 173, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Saves',
      backgroundColor: 'rgb(147, 66, 245)',
      borderColor: 'rgb(147, 66, 245)',
      data: savesPerGame,
      trendlineLinear: {
        style: 'rgba(147, 66, 245, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Goals Against',
      backgroundColor: 'rgb(252, 98, 15)',
      borderColor: 'rgb(252, 98, 15)',
      data: goalsAgainstPerGame,
      trendlineLinear: {
        style: 'rgba(252, 98, 15, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
  ];

  const data = {
    labels,
    datasets: perGame ? statsPerGame : statsTotal,
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: true,
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const togglePerGame = () => setPerGame(!perGame);
  return (
    <StatChartWrapper>
      <h2>Stats by Season</h2>
      <Toggle>
        <button type="button" onClick={togglePerGame} disabled={perGame}>
          Per Game Averages
        </button>
        <button type="button" onClick={togglePerGame} disabled={!perGame}>
          Stat Totals
        </button>
      </Toggle>
      <Line options={options} data={data} />
    </StatChartWrapper>
  );
};

export default StatChartGoalie;
