import React from 'react';
import PropTypes from 'prop-types';
import { ScheduleBody } from 'Components';
import { StyledTable, TableCaption } from 'styles';

const SingleTeamSchedule = ({ games, seasonId }) => {
  return (
    <>
    <StyledTable solidHeader largeMargins>
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th colSpan={2}>Home</th>
          <th></th>
          <th colSpan={2}>Away</th>
          <th></th>
          <th></th>
          <th>Location</th>
        </tr>
      </thead>
      <ScheduleBody games={games} inlineDate seasonId={seasonId} />
    </StyledTable>
    <TableCaption>* Playoffs</TableCaption>
    </>
  );
};

SingleTeamSchedule.propTypes = {
  games: PropTypes.array.isRequired,
  seasonId: PropTypes.number.isRequired,
};

export default SingleTeamSchedule;
