import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_TEAM_NAMES } from 'services/queries';
import { Dropdown } from 'styles';
import { CURRENT_SEASON } from 'utils';

const TeamsDropdown = () => {
  const { loading, error, data } = useQuery(GET_TEAM_NAMES, {
    variables: { seasonId: CURRENT_SEASON },
  });

  if (error) return '';

  return (
    <Dropdown>
      <h3>Teams</h3>
      {!loading && data && (
        <ul>
          {data.teams.length > 0 &&
            [...data.teams]
              .sort((teamA, teamB) => (teamA?.id > teamB?.id ? 1 : -1))
              .map(({ id, name }) => (
                <li key={id}>
                  <a href={`/team/${CURRENT_SEASON}/${id}`}>{name}</a>
                </li>
              ))}
        </ul>
      )}
    </Dropdown>
  );
};

TeamsDropdown.propTypes = {
  teams: PropTypes.array,
};
TeamsDropdown.defaultProps = {
  teams: [],
};

export default TeamsDropdown;
