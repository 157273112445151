import React from 'react';
import styled, { keyframes } from 'styled-components';
import gbhpcLogo from 'assets/cheevers-black-border.svg';
import { design } from 'utils';

const pulse = keyframes`
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
  }
,
`;
const ellipsis = keyframes`
  to {
    width: 1em;    
  }
`;

const LoadingWrapper = styled.div`
  img {
    transform: scale(0.8);
    max-width: 200px;
    display: block;
    margin: ${design.spacing[4]} auto;
    animation: ${pulse} 2s infinite;
  }
  p {
    font-size: ${design.fontSizes[3]};
    position: relative;
    left: -0.5em;
    display: inline-block;
    &:after {
      overflow: hidden;
      position: absolute;
      left: 100%;
      margin-left: ${design.spacing[0.5]};
      display: inline-block;
      white-space: nowrap;
      animation: ${ellipsis} steps(4, end) 1100ms infinite;
      content: '...';
      width: 0px;
    }
  }
`;

const Loading = () => (
  <LoadingWrapper>
    <img src={gbhpcLogo} alt="" />
    <p>Loading</p>
  </LoadingWrapper>
);

export default Loading;
