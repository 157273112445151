import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { design } from 'utils';

const Block = styled.span`
  height: 0.75em;
  width: 0.75em;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  margin-right: ${design.spacing[1]};
  margin-bottom: -0.03em;
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    border: 1px solid ${design.colors.gray[7]};
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  ${(props) => props.color && `background-color: ${props.color};`}
  ${(props) =>
    props.colorSecondary &&
    `
    &::before {
      content: '';
      background-color: ${props.colorSecondary};
      display: block;
      transform: rotate(45deg);
      z-index: 2;
      width: 100%;
      height: 100%;
      margin: .265em;
    }
  `}
`;

const ColorBlock = ({ color, colorSecondary }) => {
  if (color || colorSecondary) {
    const formattedColor = color.replace(/\s+/g, '').toLowerCase();

    return <Block color={formattedColor} colorSecondary={colorSecondary} />;
  }
  return <></>;
};

ColorBlock.propTypes = {
  color: PropTypes.string,
  colorSecondary: PropTypes.string,
};

ColorBlock.defaultProps = {
  color: null,
  colorSecondary: null,
};

export default ColorBlock;
