import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { design } from 'utils';

const NavWrap = styled.nav`
  display: flex;
  justify-content: center;
  background-color: ${design.colors.secondary};
  border-bottom: 2px solid ${design.colors.gray[8]};
  a {
    display: block;
    padding: ${design.spacing[1]} ${design.spacing[4]};
    margin: 0 ${design.spacing[2]};
    color: ${design.colors.white};
    font-weight: bold;
    &:hover {
      color: ${design.colors.secondary};
      background-color: ${design.colors.white};
      text-decoration: none;
    }
  }
`;

const AdminNav = () => {
  return (
    <NavWrap>
      <Link to="/admin">Admin Home</Link>
      <Link to="/admin/roster">Rosters</Link>
      <Link to="/admin/schedule">Schedule</Link>
      <Link to="/admin/draft">Draft Order</Link>
      <Link to="/admin/export">Player Export</Link>
    </NavWrap>
  );
};

export default AdminNav;
