import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Loading, Table, TeamName } from 'Components';
import { GET_STATS_GOALIE } from 'services/queries';
import { getGoalieSingleSeasonHeadCells } from 'tableHeadCells';
import { createSeasonStatData } from 'utils';

export const baseRowLinks = {
  firstName: {
    page: '/player',
    param: 'playerId',
  },
  lastName: {
    page: '/player',
    param: 'playerId',
  },
  team: {
    page: '/archives/team',
    param: 'teamId',
  },
};

const StatsBlockGoalie = ({
  rowLinks,
  mergeStats,
  seasonId,
  showAssists,
  showJersey,
  showPim,
  showTeam,
  showTies,
}) => {
  const { loading, error, data } = useQuery(GET_STATS_GOALIE, {
    variables: { seasonId },
  });

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  const headCells = getGoalieSingleSeasonHeadCells({
    showAssists,
    showJersey,
    showPim,
    showTeam,
    showTies,
  });

  const statData = data.careerStatsGoalie
    .map((stat) => {
      const {
        assists,
        gamesPlayed,
        goalsAgainst,
        id,
        loss: losses,
        otl,
        pim,
        shotsAgainst,
        shutouts,
        sol,
        tie: ties,
        win: wins,
        playerByPlayer: { firstName, lastName, id: playerId },
        teamByTeam,
      } = stat;
      const team = teamByTeam?.name;
      const teamId = teamByTeam?.id;
      const color = teamByTeam?.color;
      const colorSecondary = teamByTeam?.colorSecondary;
      const abbreviation = teamByTeam?.abbreviation;

      let teamName;
      let teamAbbreviation;
      if (teamByTeam) {
        const teamNameArr = team.split(' ');
        teamName =
          teamNameArr[0] === 'Team'
            ? teamNameArr[1].slice(0, 3).toUpperCase()
            : teamNameArr[0].slice(0, 3).toUpperCase();
        teamAbbreviation = abbreviation || teamName;
      }
      return createSeasonStatData(
        {
          id,
          playerId,
          seasonId,
          teamId,
        },
        {
          lastName,
          firstName,
          team: showTeam ? (
            teamByTeam?.name ? (
              <TeamName
                name={teamAbbreviation}
                color={color}
                colorSecondary={colorSecondary}
                wrap="true"
              />
            ) : (
              ''
            )
          ) : null,
          gamesPlayed,
          wins,
          losses,
          otl: otl + sol,
          ties: showTies ? ties : null,
          shutouts,
          goalsAgainst,
          shotsAgainst,
          assists,
          pim,
        }
      );
    })
    .sort((statA, statB) => statA.playerId - statB.playerId);

  const statsByPlayer = {};
  statData.forEach((stat) => {
    if (statsByPlayer[stat.playerId]) {
      statsByPlayer[stat.playerId].push(stat);
    } else {
      statsByPlayer[stat.playerId] = [stat];
    }
  });
  let statTotals = statData;
  if (mergeStats) {
    statTotals = Object.keys(statsByPlayer).map((player) => {
      const { id, playerId, firstName, lastName, position, team, teamId } =
        statsByPlayer[player][0];
      const totals = statsByPlayer[player].reduce(
        (current, total) => {
          return {
            gamesPlayed: current.gamesPlayed + total.gamesPlayed,
            wins: current.wins + total.wins,
            losses: current.losses + total.losses,
            ties: showTies ? current.ties + total.ties : null,
            otl: current.otl + total.otl,
            shutouts: current.shutouts + total.shutouts,
            goalsAgainst: current.goalsAgainst + total.goalsAgainst,
            shotsAgainst: current.shotsAgainst + total.shotsAgainst,
            assists: showAssists ? current.pim + total.pim : null,
            pim: showPim ? current.pim + total.pim : null,
          };
        },
        {
          gamesPlayed: 0,
          wins: 0,
          losses: 0,
          otl: 0,
          shutouts: 0,
          goalsAgainst: 0,
          shotsAgainst: 0,
          ties: showTies ? 0 : null,
          assists: showAssists ? 0 : null,
          pim: showPim ? 0 : null,
        }
      );
      return {
        id,
        playerId,
        firstName,
        lastName,
        position,
        team,
        teamId,
        stats: totals,
      };
    });
  }

  const totalsData = statTotals.map((stat) => {
    const { playerId, teamId, firstName, lastName, team, stats } = stat;
    let gamesPlayed,
      wins,
      losses,
      ties,
      otl,
      shutouts,
      goalsAgainst,
      shotsAgainst,
      assists,
      pim;
    if (stats) {
      ({
        gamesPlayed,
        wins,
        losses,
        ties,
        otl,
        shutouts,
        goalsAgainst,
        shotsAgainst,
        assists,
        pim,
      } = stats);
    } else {
      ({
        gamesPlayed,
        wins,
        losses,
        ties,
        otl,
        shutouts,
        goalsAgainst,
        shotsAgainst,
        assists,
        pim,
      } = stat);
    }
    let svp = (shotsAgainst - goalsAgainst) / shotsAgainst;
    svp = svp === 1 ? svp.toFixed(3) : svp.toFixed(3).slice(1);
    const seasonStatData = createSeasonStatData(
      {
        id: playerId,
        seasonId,
        teamId: mergeStats ? null : teamId,
      },
      {
        lastName,
        firstName,
        team: showTeam ? team : null,
        gamesPlayed,
        wins,
        losses,
        ties,
        otl,
        gaa: (goalsAgainst / gamesPlayed).toFixed(2),
        svp,
        shutouts,
        goalsAgainst,
        shotsAgainst,
        saves: shotsAgainst - goalsAgainst,
        assists: showAssists ? assists : null,
        pim: showPim ? pim : null,
      }
    );
    if (!showTeam) {
      delete seasonStatData.team;
    }
    if (!showTies) {
      delete seasonStatData.ties;
    }
    return seasonStatData;
  });

  return (
    <Table
      defaultOrder="desc"
      defaultOrderBy="svp"
      enablePagination
      headCells={headCells}
      rowLinks={rowLinks}
      rows={totalsData}
      size="small"
    />
  );
};

StatsBlockGoalie.propTypes = {
  mergeStats: PropTypes.bool,
  rowLinks: PropTypes.object,
  seasonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showAssists: PropTypes.bool,
  showJersey: PropTypes.bool,
  showPim: PropTypes.bool,
  showTeam: PropTypes.bool,
  showTies: PropTypes.bool,
};

StatsBlockGoalie.defaultProps = {
  mergeStats: false,
  rowLinks: baseRowLinks,
  seasonId: null,
  showAssists: false,
  showJersey: false,
  showPim: false,
  showTeam: false,
  showTies: false,
};

export default StatsBlockGoalie;
