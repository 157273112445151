import React from 'react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { LoadingPage, PageContainer, Table, TeamName } from 'Components';
import { GET_TEAMS } from 'services/queries';

function createData(id, seasonId, season, league, team, captain) {
  return { id, seasonId, season, league, team, captain };
}
const headCells = [
  { id: 'season', numeric: false, label: 'Season' },
  { id: 'league', numeric: false, label: 'League' },
  { id: 'team', numeric: false, label: 'Team Name' },
  { id: 'captain', numeric: false, label: 'Captain' },
];

const TeamsList = () => {
  const { loading, error, data } = useQuery(GET_TEAMS);

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const teamData = data.teams.map((team) => {
    const {
      id,
      champion,
      color,
      colorSecondary,
      name,
      seasonBySeason,
      captainPlayer,
    } = team;

    return createData(
      id,
      seasonBySeason.id,
      `${seasonBySeason.year} ${seasonBySeason.season}`,
      seasonBySeason.league,
      <TeamName
        name={name}
        champion={champion}
        color={color}
        colorSecondary={colorSecondary}
      />,
      captainPlayer ? (
        <a href={`/player/${captainPlayer.id}`}>
          {captainPlayer.firstName} {captainPlayer.lastName}
        </a>
      ) : (
        ''
      )
    );
  });

  const rowLinks = {
    season: {
      page: '/archives/season',
      param: 'seasonId',
    },
    team: {
      page: '/archives/team',
      param: 'id',
      alt: {
        condition: {
          league: 'GBHPC',
        },
        page: '/team',
        param: ['seasonId', 'id'],
      },
    },
  };

  return (
    <PageContainer pageHeading={<h1>Teams</h1>}>
      <Helmet>
        <title>Archives - Teams | GBH Players Club</title>
      </Helmet>
      <Table
        defaultOrder="desc"
        defaultOrderBy="ppg"
        enablePagination
        headCells={headCells}
        rowLinks={rowLinks}
        rows={teamData}
        size="small"
      />
    </PageContainer>
  );
};

export default TeamsList;
