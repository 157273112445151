import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ArchiveDropdown, MerchDropdown, TeamsDropdown } from 'Components';
import { CURRENT_SEASON, design } from 'utils';

const NavBar = styled.ul`
  text-align: center;
  background-color: ${design.colors.black};
  margin-top: -${design.spacing[2]};
  white-space: nowrap;
  li {
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    position: relative;
    white-space: nowrap;
    &.is-active,
    &:hover {
      background-color: ${design.colors.white};
      a {
        color: ${design.colors.black};
        text-decoration: none;
      }
    }
    a {
      color: ${design.colors.white};
      display: block;
      padding: ${design.spacing[2]} ${design.spacing[1]};
      font-size: ${design.fontSizes[1]};
      @media screen and (min-width: ${design.breakpoints[2]}) {
        padding: ${design.spacing[2]};
        font-size: ${design.fontSizes[2]};
      }
    }
  }
`;

const Nav = () => {
  return (
    <nav>
      <NavBar>
        <TeamsDropdown />
        <li>
          <Link to={`/schedule/${CURRENT_SEASON}`}>Schedule</Link>
        </li>
        <li>
          <Link to={`/stats/${CURRENT_SEASON}`}>Stats</Link>
        </li>
        <MerchDropdown />
        <ArchiveDropdown />
      </NavBar>
    </nav>
  );
};

export default Nav;
