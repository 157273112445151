import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import { DataGrid } from '@mui/x-data-grid';
import { Loading } from 'Components';
import { GET_PLAYER_EXPORT_INFO } from 'services/queries';
import { Label, Option, Select } from 'styles';
import {
  createPlayerExportData,
  createPlayerExportTotalData,
  design,
} from 'utils';

// todo - fix players with no stats

const tableHeadings = [
  { field: 'lastName', headerName: 'Last Name' },
  { field: 'firstName', headerName: 'First Name' },
  { field: 'contact', headerName: 'Contact' },
  { field: 'gbhpc', headerName: 'GBHPC' },
  { field: 'email', headerName: 'Email' },
  { field: 'lastSeason', headerName: 'Last Season' },
  { field: 'position', headerName: 'Pos.' },
  { field: 'gamesPlayed', type: 'number', headerName: 'GP' },
  { field: 'goals', type: 'number', headerName: 'G' },
  { field: 'assists', type: 'number', headerName: 'A' },
  { field: 'points', type: 'number', headerName: 'PTS' },
  { field: 'pim', type: 'number', headerName: 'PIM' },
  { field: 'avgRd', type: 'number', headerName: 'Avg Rd' },
  { field: 'lastRd', type: 'number', headerName: 'Last Rd' },
];
const exportHeadings = [
  { key: 'lastName', label: 'Last Name' },
  { key: 'firstName', label: 'First Name' },
  { key: 'contact', label: 'Contact' },
  { key: 'gbhpc', label: 'GBHPC' },
  { key: 'email', label: 'Email' },
  { key: 'lastSeason', label: 'Last Season' },
  { key: 'position', label: 'Pos.' },
  { key: 'gamesPlayed', type: 'number', label: 'GP' },
  { key: 'goals', type: 'number', label: 'G' },
  { key: 'assists', type: 'number', label: 'A' },
  { key: 'points', type: 'number', label: 'PTS' },
  { key: 'pim', type: 'number', label: 'PIM' },
  { key: 'avgRd', type: 'number', headerName: 'Avg Rd' },
  { key: 'lastRd', type: 'number', headerName: 'Last Rd' },
];

const Heading = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  > div {
    select {
      padding: ${design.spacing[1]} ${design.spacing[2]};
    }
  }
`;
const ExportWrap = styled.p`
  position: relative;
  text-align: right;
  a {
    border-radius: ${design.radius[0]};
    border: 2px solid ${design.colors.secondary};
    padding: ${design.spacing[1]} ${design.spacing[2]};
    display: inline-block;
    transition: background-color 0.2s, color 0.2s;
    background-color: ${design.colors.white};
    color: ${design.colors.secondary};
    &:active,
    &:focus,
    &:hover {
      background-color: ${design.colors.secondary};
      color: ${design.colors.white};
      text-decoration: none;
    }
  }
  ${(props) =>
    props.disabled &&
    `
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    a {
      border-color: ${design.colors.gray[3]};
      color: ${design.colors.gray[3]};
    }
  `}
`;

const AdminPlayerExport = () => {
  const [playersToExport, setPlayersToExport] = useState([]);
  const [leagues, setLeagues] = useState(['GBHPC', 'GBH', 'MAHL', 'HNE']);

  const { loading, error, data } = useQuery(GET_PLAYER_EXPORT_INFO, {
    variables: { leagues },
  });

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  const handleSelect = (selected) => {
    const selectedPlayers = [...totalsData].filter((item) =>
      selected.includes(item.id)
    );
    setPlayersToExport(selectedPlayers);
  };

  const handleLeagueMenu = (e) => {
    const leaguesArr = e.target.value.split(',');
    setLeagues(leaguesArr);
  };

  const statData = data.careerStats
    .map((stat) => {
      const {
        id,
        gamesPlayed,
        goals,
        assists,
        pim,
        draftRound,
        playerByPlayer: {
          firstName,
          lastName,
          position,
          id: playerId,
          contact,
          gbhpc,
          email,
          lastSeason,
        },
        seasonBySeason: { id: seasonId },
      } = stat;
      return createPlayerExportData(
        id,
        playerId,
        lastName,
        firstName,
        contact,
        gbhpc,
        email,
        lastSeason,
        position,
        gamesPlayed,
        goals,
        assists,
        goals + assists,
        pim,
        draftRound,
        seasonId
      );
    })
    .sort((statA, statB) => statA.seasonId - statB.seasonId);

  const statsByPlayer = {};
  statData.forEach((stat) => {
    if (statsByPlayer[stat.playerId]) {
      statsByPlayer[stat.playerId].push(stat);
    } else {
      statsByPlayer[stat.playerId] = [stat];
    }
  });
  const statTotals = Object.keys(statsByPlayer).map((player) => {
    const { firstName, lastName, position, contact, gbhpc, email, lastSeason } =
      statsByPlayer[player][0];
    const draftRounds = [];
    statsByPlayer[player].forEach((stat) => {
      if (stat.draftRound) {
        draftRounds.push(stat.draftRound);
      }
    });
    const totals = statsByPlayer[player].reduce(
      (current, total) => {
        return {
          gamesPlayed: current.gamesPlayed + total.gamesPlayed,
          goals: current.goals + total.goals,
          assists: current.assists + total.assists,
          pim: current.pim + total.pim,
        };
      },
      { gamesPlayed: 0, goals: 0, assists: 0, pim: 0 }
    );
    return {
      playerId: player,
      firstName,
      lastName,
      contact,
      gbhpc,
      email,
      lastSeason,
      position,
      stats: totals,
      draftRounds,
    };
  });

  const totalsData = statTotals.map((stat) => {
    const {
      playerId,
      firstName,
      lastName,
      contact,
      gbhpc,
      email,
      lastSeason,
      position,
      stats: { gamesPlayed, goals, assists, pim },
      draftRounds,
    } = stat;

    let avgRd = 0,
      lastRd = 0;

    if (draftRounds.length) {
      const totalRounds = draftRounds.reduce(
        (current, total) => current + total,
        0
      );
      avgRd = (totalRounds / draftRounds.length).toFixed(1);
      lastRd = draftRounds[draftRounds.length - 1];
    }

    return createPlayerExportTotalData(
      playerId,
      lastName,
      firstName,
      contact ? 'Y' : 'N',
      gbhpc ? 'Y' : 'N',
      email,
      lastSeason,
      position,
      gamesPlayed,
      goals,
      assists,
      goals + assists,
      pim,
      avgRd,
      lastRd
    );
  });

  const leagueString = leagues.length > 1 ? 'All' : `${leagues[0]}`;
  const dateString = new Date().toISOString().split('.')[0];
  const exportFilename = `GBHPC-Stats-${leagueString}_${dateString}.csv`;

  const disabledLink = playersToExport.length === 0;

  return (
    <>
      <Heading>
        <div>
          <Label htmlFor="leagueSelect">
            League:
            <Select
              id="leagueSelect"
              value={leagues.length > 1 ? leagues : leagues[0]}
              onChange={handleLeagueMenu}
            >
              <Option value={['GBHPC', 'GBH', 'MAHL', 'HNE']}>All</Option>
              <Option value={['GBHPC']}>GBHPC</Option>
              <Option value={['GBH']}>GBH</Option>
              <Option value={['HNE']}>HNE</Option>
              <Option value={['MAHL']}>MAHL</Option>
            </Select>
          </Label>
        </div>
        <h1>Player Export</h1>
        <ExportWrap disabled={disabledLink}>
          <CSVLink
            data={playersToExport}
            headers={exportHeadings}
            filename={exportFilename}
          >
            Export CSV
          </CSVLink>
        </ExportWrap>
      </Heading>
      <DataGrid
        autoHeight
        defaultOrder="desc"
        defaultOrderBy="ppg"
        checkboxSelection
        columns={tableHeadings}
        rows={totalsData}
        rowsPerPageOptions={[100, 250, 500, 1000]}
        onSelectionModelChange={handleSelect}
        density="compact"
      />
    </>
  );
};

export default AdminPlayerExport;
