import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TeamName } from 'Components';
import { StyledTable, ScrollWrapper } from 'styles';
import { design, sortStandings } from 'utils';

const StandingsWrap = styled.div`
  margin-bottom: ${design.spacing[4]};
`;
const TitleTd = styled.td`
  width: 33%;
`;
const CenterTd = styled.td`
  text-align: center;
`;
const Tiebreakers = styled.p`
  font-size: ${design.fontSizes[1]};
  margin-bottom: ${design.spacing[1]};
  text-align: center;
`;

const StandingsBlock = ({ isArchive, seasonId, standings, teamStreaks }) => {
  const standingsToUse = Object.entries(standings).map(([team, values]) => ({
    ...values,
    teamName: team,
  }));
  const sortedStandings = standingsToUse.sort(sortStandings);
  return (
    <StandingsWrap>
      <h2>Standings</h2>
      <ScrollWrapper>
        <StyledTable solidHeader>
          <thead>
            <tr>
              <th>Team</th>
              <th>GP</th>
              <th>W</th>
              <th>L</th>
              <th>SOL</th>
              <th>T</th>
              <th>PTS</th>
              <th>GF</th>
              <th>GA</th>
              <th>Diff</th>
              <th>Streak</th>
            </tr>
          </thead>
          <tbody>
            {sortedStandings.map((team) => {
              const teamId = team.teamId || team.id;
              let streak = 'W0';
              if (
                teamStreaks[teamId] &&
                Object.entries(teamStreaks[teamId])[0]
              ) {
                streak = Object.entries(teamStreaks[teamId])[0]
                  .join('')
                  .toUpperCase();
              }
              const teamLink = isArchive
                ? `/archives/team/${teamId}`
                : `/team/${seasonId}/${teamId}`;
              return (
                <tr key={teamId}>
                  <TitleTd>
                    <Link to={teamLink}>
                      <TeamName name={team.teamName} />
                    </Link>
                  </TitleTd>
                  <CenterTd>{team.gamesPlayed}</CenterTd>
                  <CenterTd>{team.wins || 0}</CenterTd>
                  <CenterTd>{team.losses || 0}</CenterTd>
                  <CenterTd>{team.otl || 0}</CenterTd>
                  <CenterTd>{team.tie || 0}</CenterTd>
                  <CenterTd>{team.pts || 0}</CenterTd>
                  <CenterTd>{team.goalsFor || 0}</CenterTd>
                  <CenterTd>{team.goalsAgainst || 0}</CenterTd>
                  <CenterTd>{team.goalsFor - team.goalsAgainst}</CenterTd>
                  <CenterTd>{streak}</CenterTd>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      </ScrollWrapper>
      <Tiebreakers>
        Tiebreakers: Wins, Head-to-head, Goal Differential, Goals For, Goals
        Against
      </Tiebreakers>
    </StandingsWrap>
  );
};

StandingsBlock.propTypes = {
  isArchive: PropTypes.bool,
  seasonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  standings: PropTypes.shape({
    gamesPlayed: PropTypes.number,
    wins: PropTypes.number,
    losses: PropTypes.number,
    otl: PropTypes.number,
    pts: PropTypes.number,
    goalsFor: PropTypes.number,
    goalsAgainst: PropTypes.number,
  }).isRequired,
  teamStreaks: PropTypes.object,
};

export default StandingsBlock;
