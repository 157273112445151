import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { INSERT_GOAL } from 'services/mutations';
import { AdminGameContext } from 'services/context';
import {
  AdminSectionWrapper,
  Button,
  CenteredWrap,
  CheckboxWrap,
  GoalInfoWrap,
  GoalPlayersWrap,
  Input,
  Label,
  Option,
  Req,
  Select,
} from 'styles';
import { CURRENT_SEASON, sortPlayersByNumber } from 'utils';

const AdminGoals = () => {
  const [goalError, setGoalError] = useState(false);
  const [newGoalData, setNewGoalData] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [timeError, setTimeError] = useState(false);
  const [timeValue, setTimeValue] = useState('');

  const { attendance, gameData, gameId, refetchGameData } =
    useContext(AdminGameContext);

  const [insertGoal, { loading }] = useMutation(INSERT_GOAL);

  const requiredItems = [
    'gameId',
    'goal',
    'period',
    'seasonId',
    'teamId',
    'time',
  ];

  const teams = {
    awayTeam: gameData.awayTeam,
    homeTeam: gameData.homeTeam,
  };

  useEffect(() => {
    if (!newGoalData.seasonId || !newGoalData.gameId) {
      const goalData = {
        ...newGoalData,
        gameId: Number(gameId),
        seasonId: CURRENT_SEASON,
      };
      setNewGoalData(goalData);
    }
  }, [gameId, newGoalData]);

  useEffect(() => {
    const goal = newGoalData?.goal;
    const primaryAssist = newGoalData?.primaryAssist || -1;
    const secondaryAssist = newGoalData?.secondaryAssist || -2;
    if (!goal) {
      setGoalError(false);
      return;
    }

    if (
      goal === primaryAssist ||
      goal === secondaryAssist ||
      primaryAssist === secondaryAssist
    ) {
      setGoalError(true);
    } else {
      setGoalError(false);
    }
  }, [newGoalData]);

  useEffect(() => {
    if (
      requiredItems.every((requiredItem) =>
        Object.keys(newGoalData).includes(requiredItem)
      ) &&
      !timeError &&
      !goalError
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [goalError, newGoalData, requiredItems, timeError]);

  const handleUpdateGoal = (e) => {
    const isInt = e.target.getAttribute('data-int');
    const key = e.target.id;
    const { value } = e.target;
    const goalData = { ...newGoalData };

    if (key === 'teamId') {
      setNewGoalData({
        seasonId: CURRENT_SEASON,
        teamId: Number(value),
        period: 1,
      });
    } else if (value === '0' || value === 'null') {
      delete goalData[key];
      if (key === 'primaryAssist' && goalData.secondaryAssist) {
        delete goalData.secondaryAssist;
      }
      setNewGoalData(goalData);
    } else {
      goalData[key] = isInt ? Number(value) : value;
      setNewGoalData(goalData);
    }
  };

  const handleTimeInput = (e) => {
    const time = e.target.value;
    const isValid = /^(0?\d|1[0-4]):[0-5]\d$/gm.test(time);
    setTimeValue(time);
    setTimeError(!isValid);
    if (isValid) {
      setNewGoalData({ ...newGoalData, time });
    }
  };

  const handleCheckbox = (e) => {
    const { name } = e.target;
    const goalData = { ...newGoalData };
    if (Object.keys(goalData).includes(name)) {
      delete goalData[name];
    } else {
      goalData[name] = true;
    }
    setNewGoalData(goalData);
  };

  const handlePPSH = (e) => {
    const { name } = e.target;
    const goalData = { ...newGoalData };
    if (Object.keys(goalData).includes(name)) {
      delete goalData[name];
    } else {
      delete goalData.pp;
      delete goalData.sh;
      goalData[name] = true;
    }
    setNewGoalData(goalData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    insertGoal({ variables: { goal: newGoalData } }).then(() => {
      refetchGameData();
    });
  };

  const showGoalData = newGoalData?.teamId;
  const currentAttendance =
    attendance?.attendance?.length && showGoalData
      ? attendance.attendance.filter(
          (player) => player.teamId === newGoalData.teamId
        )
      : [];

  let currentRoster = attendance?.players.reduce(
    (teamRoster, currentPlayer) => {
      if (currentAttendance.find((att) => att.playerId === currentPlayer.id)) {
        return [...teamRoster, currentPlayer];
      }
      return teamRoster;
    },
    []
  );
  if (currentRoster?.length) {
    currentRoster = currentRoster.sort(sortPlayersByNumber);
  }

  const goalDataKeys = Object.keys(newGoalData);

  return (
    <AdminSectionWrapper>
      <form onSubmit={handleSubmit}>
        <CenteredWrap>
          <Label htmlFor="teamId">
            <Req>*</Req>Team:
            <Select
              data-int
              defaultValue={0}
              id="teamId"
              onChange={handleUpdateGoal}
              required
            >
              <Option value={0} disabled>
                Select a Team
              </Option>
              {Object.values(teams).map((team) => (
                <Option value={Number(team.id)} key={team.id}>
                  {team.name}
                </Option>
              ))}
            </Select>
          </Label>
        </CenteredWrap>
        {showGoalData && (
          <>
            <GoalPlayersWrap>
              <Label error={goalError} htmlFor="goal">
                <Req>*</Req>Goal:
                <Select
                  data-int
                  defaultValue={0}
                  id="goal"
                  onChange={handleUpdateGoal}
                  required
                >
                  <Option value={0}>Select Player</Option>
                  {currentRoster.map((player) => (
                    <Option value={player.id} key={player.id}>
                      {player.jersey}
                      {player.jerseySecondary
                        ? ` / ${player.jerseySecondary}`
                        : ''}{' '}
                      - {player.firstName} {player.lastName}
                    </Option>
                  ))}
                </Select>
              </Label>
              <Label error={goalError} htmlFor="primaryAssist">
                Assist:
                <Select
                  data-int
                  defaultValue={0}
                  id="primaryAssist"
                  onChange={handleUpdateGoal}
                >
                  <Option value={0}>N/A</Option>
                  {currentRoster.map((player) => (
                    <Option
                      value={player.id}
                      key={player.id}
                      disabled={newGoalData?.goal === player.id}
                    >
                      {player.jersey}
                      {player.jerseySecondary
                        ? ` / ${player.jerseySecondary}`
                        : ''}{' '}
                      - {player.firstName} {player.lastName}
                    </Option>
                  ))}
                </Select>
              </Label>
              <Label error={goalError} htmlFor="secondaryAssist">
                Assist:
                <Select
                  data-int
                  defaultValue={0}
                  disabled={!goalDataKeys.includes('primaryAssist')}
                  id="secondaryAssist"
                  onChange={handleUpdateGoal}
                >
                  <Option value={0}>N/A</Option>
                  {currentRoster.map((player) => (
                    <Option
                      value={player.id}
                      key={player.id}
                      disabled={
                        newGoalData?.goal === player.id ||
                        newGoalData?.primaryAssist === player.id
                      }
                    >
                      {player.jersey}
                      {player.jerseySecondary
                        ? ` / ${player.jerseySecondary}`
                        : ''}{' '}
                      - {player.firstName} {player.lastName}
                    </Option>
                  ))}
                </Select>
              </Label>
            </GoalPlayersWrap>
            <GoalInfoWrap>
              <Label htmlFor="period">
                <Req>*</Req>Period:
                <Select
                  data-int
                  defaultValue={1}
                  id="period"
                  onChange={handleUpdateGoal}
                  required
                >
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>OT</Option>
                </Select>
              </Label>
              <Label htmlFor="time" error={timeError}>
                <Req>*</Req>Time:
                <Input
                  id="time"
                  onChange={handleTimeInput}
                  type="text"
                  value={timeValue}
                />
              </Label>
              <CheckboxWrap>
                <Label htmlFor="en">
                  <Input
                    checked={goalDataKeys.includes('en')}
                    id="en"
                    name="en"
                    onChange={handleCheckbox}
                    type="checkbox"
                  />
                  EN
                </Label>
                <Label htmlFor="gw">
                  <Input
                    checked={goalDataKeys.includes('gw')}
                    id="gw"
                    name="gw"
                    onChange={handleCheckbox}
                    type="checkbox"
                  />
                  GW
                </Label>
                <Label htmlFor="pp">
                  <Input
                    checked={goalDataKeys.includes('pp')}
                    id="pp"
                    name="pp"
                    onChange={handlePPSH}
                    type="checkbox"
                  />
                  PP
                </Label>
                <Label htmlFor="sh">
                  <Input
                    checked={goalDataKeys.includes('sh')}
                    id="sh"
                    name="sh"
                    onChange={handlePPSH}
                    type="checkbox"
                  />
                  SH
                </Label>
              </CheckboxWrap>
            </GoalInfoWrap>
            <CenteredWrap>
              <Button type="submit" disabled={submitDisabled || loading}>
                {loading ? 'Submitting...' : 'Submit'}
              </Button>
            </CenteredWrap>
          </>
        )}
      </form>
    </AdminSectionWrapper>
  );
};

export default AdminGoals;
