import styled, { css } from 'styled-components';
import { design } from 'utils';

const baseInputStyles = css`
  font-size: ${design.fontSizes[2]};
  padding: ${design.spacing[0.5]} ${design.spacing[1]};
  border: 1px solid ${design.colors.gray[5]};
  &:focus {
    outline-color: ${design.colors.gbhpcHome};
  }
`;

export const Input = styled.input`
  ${baseInputStyles}
`;

export const Label = styled.label`
  select {
    margin-left: ${design.spacing[1]};
  }
  ${(props) =>
    props.error &&
    `
    color: ${design.colors.error};
    input,
    select {
      border-color: ${design.colors.error};
      outline-color: ${design.colors.error};
    }
  `}
`;

export const Option = styled.option`
  &:disabled {
    color: ${design.colors.gray[5]};
  }
`;

export const Select = styled.select`
  ${baseInputStyles}
`;
