import styled from 'styled-components';
import { design } from 'utils';

export const Dropdown = styled.li`
  color: ${design.colors.white};
  cursor: pointer;
  display: block;
  position: relative;
  h3 {
    padding: ${design.spacing[2]} ${design.spacing[1]};
    @media screen and (min-width: ${design.breakpoints[1]}) {
      padding: ${design.spacing[2]};
    }
  }
  &:hover {
    color: ${design.colors.black};
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 5px;
      background-color: ${design.colors.white};
    }
    & ul {
      display: block;
    }
  }
  h3 {
    margin: 0;
    font-size: ${design.fontSizes[1]};
    @media screen and (min-width: ${design.breakpoints[2]}) {
      font-size: ${design.fontSizes[2]};
    }
  }
  ul {
    display: none;
    position: absolute;
    top: 100%;
    background-color: ${design.colors.white};
    box-shadow: ${design.shadow[1]};
    ${(props) =>
      props.right
        ? `
    right: 0;
    border-radius: ${design.radius[0]} 0 ${design.radius[0]} ${design.radius[0]};
    `
        : `
    left: 0;
    border-radius: 0 ${design.radius[0]} ${design.radius[0]};
    `}
    li {
      display: block;
      text-align: left;
      font-weight: normal;
      &:first-child {
        ${(props) =>
          props.right
            ? `border-top-left-radius: ${design.radius[0]};`
            : `border-top-right-radius: ${design.radius[0]};`}
      }
      &:last-child {
        border-radius: 0 0 ${design.radius[0]} ${design.radius[0]};
      }
      &:hover {
        background-color: ${design.colors.primary};
        & a {
          color: ${design.colors.white};
        }
      }
    }
  }
`;
