import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ScrollWrapper, TeamStatTable } from 'styles';
import { getPlayerGameStatsGamePage, sortPlayerStats } from 'utils';

const GameStats = ({ attendance, goals, isHome, penalties }) => {
  const [attendanceWithStats, setAttendanceWithStats] = useState({});

  useEffect(() => {
    if (!Object.keys(attendanceWithStats).length) {
      const stats = getPlayerGameStatsGamePage(attendance, goals, penalties)
      setAttendanceWithStats(stats);
    }
  }, [attendance, attendanceWithStats, goals, penalties]);

  return (
    <ScrollWrapper>
      <TeamStatTable isHome={isHome}>
        <thead>
          <tr>
            <th>Player</th>
            <th>No.</th>
            <th>Pos.</th>
            <th>G</th>
            <th>A</th>
            <th>P</th>
            <th>PIM</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(attendanceWithStats)
            .sort((playerA, playerB) => sortPlayerStats(playerA, playerB))
            .map((player) => {
              const playerGoals = player.goals || 0;
              const playerAssists = player.assists || 0;
              const playerPoints = playerGoals + playerAssists;
              const playerPosition =
                player.position === 'GOALIE' && !player.isGoalie
                  ? player.positionSecondary
                  : player.position;
              const position = player.isGoalie
                ? 'G'
                : playerPosition?.slice(0, 1).toUpperCase() || null;
              return (
                <tr key={player.id}>
                  <td>
                    <Link to={`/player/${player.id}`}>
                      {player.firstName} {player.lastName}
                    </Link>
                  </td>
                  <td>
                    {player.jersey}
                    {player.jerseySecondary
                      ? ` / ${player.jerseySecondary}`
                      : ''}
                  </td>
                  <td>{position}</td>
                  <td>{playerGoals}</td>
                  <td>{playerAssists}</td>
                  <td>{playerPoints}</td>
                  <td>{player.pim || 0}</td>
                </tr>
              );
            })}
        </tbody>
      </TeamStatTable>
    </ScrollWrapper>
  );
};

export default GameStats;
