export const sortPlayersByNumber = (playerA, playerB) => {
  if (playerA.jersey > playerB.jersey) {
    return 1;
  }
  if (playerA.jersey < playerB.jersey) {
    return -1;
  }
  return 0;
};

export const sortPlayersByNumberNested = (playerA, playerB) => {
  const playerAInfo = playerA.players ? playerA.players[0] : playerA;
  const playerBInfo = playerB.players ? playerB.players[0] : playerB;

  if (playerAInfo.jersey > playerBInfo.jersey) {
    return 1;
  }
  if (playerAInfo.jersey < playerBInfo.jersey) {
    return -1;
  }
  return 0;
};
