import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { LoadingPage, TeamPage } from 'Components';
import { GET_ARCHIVE_TEAM_INFO } from 'services/queries';
import { createSeasonStatData } from 'utils';
import {
  getGoalieSingleSeasonHeadCells,
  getSkaterSingleSeasonHeadCells,
} from 'tableHeadCells';

const ArchiveTeamPageContainer = () => {
  const { id } = useParams();
  const teamId = Number(id);
  const { loading, error, data } = useQuery(GET_ARCHIVE_TEAM_INFO, {
    variables: { teamId },
  });

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const {
    careerStatsGoalie,
    seasonBySeason: { id: seasonId },
    skaterStats,
  } = data.teams[0];

  const skaterData = skaterStats.map((stat) => {
    const {
      id,
      playerByPlayer: { id: playerId, firstName, lastName, position },
      gamesPlayed,
      goals,
      assists,
      pim,
    } = stat;
    return createSeasonStatData(
      {
        id,
        playerId,
      },
      {
        lastName,
        firstName,
        position: position.slice(0, 1),
        gamesPlayed,
        goals,
        assists,
        points: goals + assists,
        pim,
        gpg: (goals / gamesPlayed).toFixed(2),
        apg: (assists / gamesPlayed).toFixed(2),
        ppg: ((goals + assists) / gamesPlayed).toFixed(2),
        pimpg: (pim / gamesPlayed).toFixed(2),
      }
    );
  });

  const goalieData = careerStatsGoalie.map((stat) => {
    const {
      id,
      playerByPlayer: { id: playerId, firstName, lastName },
      assists,
      gamesPlayed,
      goalsAgainst,
      loss,
      otl,
      pim,
      shotsAgainst,
      shutouts,
      sol,
      tie,
      win,
    } = stat;
    let svp = (shotsAgainst - goalsAgainst) / shotsAgainst;
    svp = svp === 1 ? svp.toFixed(3) : svp.toFixed(3).slice(1);
    return createSeasonStatData(
      {
        id,
        playerId,
      },
      {
        lastName,
        firstName,
        gamesPlayed,
        win,
        loss,
        otl: tie + otl + sol,
        gaa: (goalsAgainst / gamesPlayed).toFixed(2),
        svp,
        shutouts,
        goalsAgainst,
        shotsAgainst,
        saves: shotsAgainst - goalsAgainst,
        assists,
        pim,
      }
    );
  });

  const rowLinks = {
    firstName: {
      page: '/player',
      param: 'playerId',
    },
    lastName: {
      page: '/player',
      param: 'playerId',
    },
  };

  return (
    <TeamPage
      goalieData={goalieData}
      goalieHeadCells={getGoalieSingleSeasonHeadCells({
        showAssists: true,
        showPim: true,
      })}
      goalieRowLinks={rowLinks}
      seasonId={seasonId}
      skaterData={skaterData}
      skaterHeadCells={getSkaterSingleSeasonHeadCells({})}
      skaterRowLinks={rowLinks}
      team={data.teams[0]}
    />
  );
};

export default ArchiveTeamPageContainer;
