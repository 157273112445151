import React, { useEffect, useState } from 'react';

const AdminAttendanceCheckbox = ({
  disabled,
  gameId,
  playerId,
  selectedAttendance,
  setSelectedAttendance,
  teamId,
}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selectedAttendance.find((att) => att.playerId === playerId)) {
      setChecked(true);
    }
  }, [playerId, selectedAttendance]);

  const handleCheckbox = () => {
    const attendance = [...selectedAttendance];
    const player = attendance.find((att) => att.playerId === playerId);
    if (checked) {
      attendance.splice(attendance.indexOf(player), 1);
      setSelectedAttendance(attendance);
    } else {
      attendance.push({ gameId, playerId, teamId });
      setSelectedAttendance(attendance);
    }
    setChecked(!checked);
  };

  return (
    <input
      checked={checked}
      disabled={disabled}
      id={playerId}
      onChange={handleCheckbox}
      type="checkbox"
      value={playerId}
    />
  );
};

export default AdminAttendanceCheckbox;
