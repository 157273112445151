import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { design } from 'utils';

// todo - make this more flexible
const PopoverButton = styled.button`
  font-size: ${design.fontSizes[2]};
  cursor: pointer;
  ${(props) =>
    !props.isText
      ? `
    border-radius: ${design.fontSizes[2]};
    border: 2px solid ${design.colors.primary};
    background-color: ${design.colors.primary};
    color: ${design.colors.white};
    padding: ${design.spacing[1]} ${design.spacing[2]};
    ${
      props.isOpen
        ? `
    background: 0;
    border-color: ${design.colors.secondary};
    color: ${design.colors.secondary};
    `
        : ''
    }
    &:hover {
      background: 0;
      border-color: ${design.colors.secondary};
      color: ${design.colors.secondary};
    }
    `
      : `
      background: 0;
      border: 0;
      color: ${design.colors.primary};
      &:hover {
        text-decoration: underline;
      }
    `}
`;
const StyledPopover = styled.aside`
  position: absolute;
  background-color: ${design.colors.gray[1]};
  padding: ${design.spacing[2]};
  border-radius: ${design.fontSizes[1]};
  border: 1px solid ${design.colors.gray[7]};
  z-index: 10;
  ${(props) => `
    ${props.side}: calc(100% - ${design.spacing[1]});
    margin-left: -20%;
  `};
  .closeButton {
    position: absolute;
    cursor: pointer;
    top: ${design.spacing[1]};
    right: ${design.spacing[1]};
    background: 0;
    border: 0;
  }
`;

const Popover = ({ children, buttonText, isTextButton, side, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  let sidePosition;

  switch (side) {
    case 'left':
      sidePosition = 'right';
      break;
    case 'right':
      sidePosition = 'left';
      break;
    case 'top':
      sidePosition = 'bottom';
      break;
    case 'bottom':
    default:
      sidePosition = 'top';
      break;
  }

  return (
    <>
      <PopoverButton
        type="button"
        onClick={isOpen ? null : handleOpen}
        isOpen={isOpen}
        isText={isTextButton}
      >
        {buttonText}
      </PopoverButton>
      {isOpen && (
        <StyledPopover side={sidePosition} {...rest}>
          <button type="button" onClick={handleClose} className="closeButton">
            <span className="sr-only">Close Popover</span>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {children}
        </StyledPopover>
      )}
    </>
  );
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  side: PropTypes.string,
};

Popover.defaultProps = {
  isOpen: false,
  side: 'top',
};

export default Popover;
