import styled from 'styled-components';
import { design } from 'utils';

export const SummaryTable = styled.div`
  table {
    border: 0;
    width: 100%;
    margin-left: ${design.spacing[1]};
    th {
      font-weight: bold;
      color: ${design.colors.secondary};
    }
    th,
    td {
      padding: ${design.spacing[1]};
    }
    td {
      border-top: 1px solid ${design.colors.secondary};
      text-align: center;
    }
  }
  @media screen and (max-width: ${design.breakpoints[4]}) {
    .hide-sm {
      display: none;
    }
  }
`;
