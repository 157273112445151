export const createGoalieStatsLeague = (games) => {
  const goaliesWithStats = {};
  games.forEach((game) => {
    if (
      (game.awayScore || game.awayScore === 0) &&
      (game.homeScore || game.homeScore === 0)
    ) {
      const {
        awayScore,
        awayShots,
        awayTeam: { abbreviation: awayName },
        home,
        homeScore,
        homeShots,
        homeTeam: { abbreviation: homeName },
        eng,
        ot,
      } = game;
      game.goalieAttendance.forEach((goalie) => {
        if (![game.awayGoalie, game.homeGoalie].includes(goalie.player.id)) {
          return;
        }
        const { teamId, player } = goalie;
        const isHome = teamId === home;
        const homeWinner = homeScore > awayScore;
        const isWinner = (isHome && homeWinner) || (!isHome && !homeWinner);
        const shutout = isHome ? awayScore === 0 : homeScore === 0;
        const homeEng = eng.filter(goal => goal.teamId === home).length;
        const awayEng = eng.filter(goal => goal.teamId !== home).length;
        const homeGoals = (homeWinner && ot ? homeScore - 1 : homeScore) - homeEng;
        const awayGoals = (!homeWinner && ot ? awayScore - 1 : awayScore) - awayEng;
        if (!goaliesWithStats[teamId]) {
          goaliesWithStats[teamId] = {
            [player.id]: {
              ...player,
              team: isHome ? homeName : awayName,
              gamesPlayed: 1,
              wins: isWinner ? 1 : 0,
              losses: !isWinner && !ot ? 1 : 0,
              otl: !isWinner && ot ? 1 : 0,
              shutouts: shutout ? 1 : 0,
              goalsAgainst: isHome ? awayGoals : homeGoals,
              shotsAgainst: isHome ? awayShots - awayEng : homeShots - homeEng,
            },
          };
        } else if (!goaliesWithStats[teamId][player.id]) {
          goaliesWithStats[teamId][player.id] = {
            ...player,
            team: isHome ? homeName : awayName,
            gamesPlayed: 1,
            wins: isWinner ? 1 : 0,
            losses: !isWinner && !ot ? 1 : 0,
            otl: !isWinner && ot ? 1 : 0,
            shutouts: shutout ? 1 : 0,
            goalsAgainst: isHome ? awayGoals : homeGoals,
            shotsAgainst: isHome ? awayShots - awayEng : homeShots - homeEng,
          };
        } else {
          const {
            gamesPlayed,
            goalsAgainst,
            losses,
            otl,
            shotsAgainst,
            shutouts,
            wins,
          } = goaliesWithStats[teamId][player.id];
          goaliesWithStats[teamId][player.id] = {
            ...goaliesWithStats[teamId][player.id],
            gamesPlayed: gamesPlayed + 1,
            wins: isWinner ? wins + 1 : wins,
            losses: !isWinner && !ot ? losses + 1 : losses,
            otl: !isWinner && ot ? otl + 1 : otl,
            shutouts: shutout ? shutouts + 1 : shutouts,
            goalsAgainst: isHome
              ? goalsAgainst + awayGoals
              : goalsAgainst + homeGoals,
            shotsAgainst: isHome
              ? shotsAgainst + awayShots - awayEng
              : shotsAgainst + homeShots - homeEng,
          };
        }
      });
    }
  });
  return goaliesWithStats;
};
