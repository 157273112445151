import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiTable from '@material-ui/core/Table';
import MuiTableContainer from '@material-ui/core/TableContainer';
import MuiTablePagination from '@material-ui/core/TablePagination';
import { TableBody, TableHead, TableToolbar } from 'Components';
import { design } from 'utils';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    border: `2px solid ${design.colors.secondary}`,
    '& tbody .MuiTableRow-root': {
      '&:nth-child(2n+1)': {
        backgroundColor: design.colors.gray[1],
      },
      '&:hover': {
        backgroundColor: design.colors.tertiary,
      },
    },
    '& + &': {
      marginTop: design.spacing[4],
    },
    '& .MuiTableCell-root': {
      fontSize: design.fontSizes[2],
      whiteSpace: 'nowrap',
      padding: `${design.spacing[1]}`,
    },
    '& th': {
      fontWeight: 'bold',
      color: design.colors.white,
      backgroundColor: design.colors.secondary,
    },
    '& th .MuiTableSortLabel-active': {
      color: design.colors.tertiary,
    },
    '& th .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active svg':
      {
        color: design.colors.tertiary,
      },
    '& .MuiButtonBase-root.MuiTableSortLabel-root:hover': {
      color: design.colors.tertiary,
    },
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const rowsPerPageOptions = [10, 20, 50, 100];

const Table = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const {
    defaultOrder,
    defaultOrderBy,
    defaultRowsPerPage,
    enablePagination,
    enableSelection,
    enableToolbar,
    headCells,
    rowLinks,
    rows,
    size,
    tableTitle,
  } = props;

  useEffect(() => {
    if (defaultOrder) {
      setOrder(defaultOrder);
    }
    if (defaultOrderBy) {
      setOrderBy(defaultOrderBy);
    }
    if (defaultRowsPerPage) {
      setRowsPerPage(defaultRowsPerPage);
    }
  }, [defaultOrder, defaultOrderBy, defaultRowsPerPage]);

  const classes = useStyles();

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowCount = rows.length;

  return (
    <div className={classes.root}>
      {enableToolbar && (
        <TableToolbar numSelected={selected.length} tableTitle={tableTitle} />
      )}
      <MuiTableContainer>
        <MuiTable
          className={classes.table}
          aria-labelledby="tableTitle"
          size={size}
          aria-label="enhanced table"
        >
          <TableHead
            classes={classes}
            headCells={headCells}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={rowCount}
          />
          <TableBody
            enableSelection={enableSelection}
            order={order}
            orderBy={orderBy}
            page={page}
            rowLinks={rowLinks}
            rowsPerPage={rowsPerPage}
            selected={selected}
            setSelected={setSelected}
            rows={rows}
          />
        </MuiTable>
      </MuiTableContainer>
      {enablePagination && (
        <MuiTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  defaultOrder: PropTypes.string,
  defaultOrderBy: PropTypes.string,
  defaultRowsPerPage: PropTypes.oneOf(rowsPerPageOptions),
  enablePagination: PropTypes.bool,
  enableSelection: PropTypes.bool,
  enableToolbar: PropTypes.bool,
  headCells: PropTypes.array.isRequired,
  rowLinks: PropTypes.object,
  rows: PropTypes.array.isRequired,
  size: PropTypes.string,
  tableTitle: PropTypes.string,
};

Table.defaultProps = {
  defaultOrder: null,
  defaultOrderBy: null,
  defaultRowsPerPage: null,
  enablePagination: true,
  enableSelection: false,
  enableToolbar: false,
  rowLinks: {},
  size: 'medium',
  tableTitle: null,
};

export default Table;
