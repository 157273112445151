import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { design } from 'utils';

const StyledLink = styled(Link)`
  width: 100%;
  max-width: 150px;
  display: block;
  text-align: center;
  font-weight: bold;
  margin: ${design.spacing[6]} ${design.spacing[8]};
  svg {
    width: 100% !important;
    height: 100%;
    max-height: 150px;
    color: ${design.colors.black};
    fill: currentColor;
    margin-bottom: ${design.spacing[3]};
  }
  &:hover {
    svg {
      color: ${design.colors.primary};
    }
  }
`;

const ArchiveLink = ({ icon, link, Svg, text }) => {
  return (
    <StyledLink to={link}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {Svg && <Svg />}
      {text && <p>{text}</p>}
    </StyledLink>
  );
};

ArchiveLink.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  link: PropTypes.string.isRequired,
  Svg: PropTypes.func,
  text: PropTypes.string,
};
ArchiveLink.defaultProps = {
  icon: null,
  Svg: null,
  text: null,
};

export default ArchiveLink;
