import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { EditButton, Input } from 'styles';
import { SET_AWAY_SHOTS, SET_HOME_SHOTS } from 'services/mutations';
import { design } from 'utils';

const ShotsRow = styled.h3`
  align-items: center;
  display: grid;
  font-size: ${design.fontSizes[2.5]};
  grid-template-columns: 1fr 100px 1fr;
`;
const ScoreInput = styled(Input)`
  width: 50px;
`;

const GameShotsHeading = ({ gameData, isAdmin, refetchGameData }) => {
  const [awayTeamShots, setAwayTeamShots] = useState(0);
  const [editAwayShots, setEditAwayShots] = useState(false);
  const [editHomeShots, setEditHomeShots] = useState(false);
  const [homeTeamShots, setHomeTeamShots] = useState(0);

  const [setAwayShots] = useMutation(SET_AWAY_SHOTS);
  const [setHomeShots] = useMutation(SET_HOME_SHOTS);

  const { awayShots, id: gameId, homeShots } = gameData;

  useEffect(() => {
    if (awayShots && awayTeamShots === 0) {
      setAwayTeamShots(awayShots);
    }
    if (homeShots && homeTeamShots === 0) {
      setHomeTeamShots(homeShots);
    }
  }, [awayShots, awayTeamShots, homeShots, homeTeamShots]);

  const handleEdit = (team) => () => {
    if (team === 'away') {
      setEditAwayShots(true);
    }
    if (team === 'home') {
      setEditHomeShots(true);
    }
  };
  const handleInput = (team) => (e) => {
    const { value } = e.target;
    if (team === 'away') {
      setAwayTeamShots(value);
    }
    if (team === 'home') {
      setHomeTeamShots(value);
    }
  };
  const submitScore = (team) => (e) => {
    const score = Number(e.target.value);
    if (team === 'away') {
      setAwayShots({ variables: { gameId, awayShots: score } }).then(() =>
        refetchGameData()
      );
      setEditAwayShots(false);
    }
    if (team === 'home') {
      setHomeShots({ variables: { gameId, homeShots: score } }).then(() =>
        refetchGameData()
      );
      setEditHomeShots(false);
    }
  };
  return (
    <ShotsRow>
      <p>
        {editHomeShots ? (
          <ScoreInput
            type="number"
            value={homeTeamShots}
            onChange={handleInput('home')}
            onBlur={submitScore('home')}
          />
        ) : isAdmin ? (
          <EditButton onClick={handleEdit('home')}>
            {homeShots || 0}
            <FontAwesomeIcon icon={faEdit} />
          </EditButton>
        ) : (
          homeShots || 0
        )}
      </p>
      <span>Shots</span>
      <p>
        {editAwayShots ? (
          <ScoreInput
            type="number"
            value={awayTeamShots}
            onChange={handleInput('away')}
            onBlur={submitScore('away')}
          />
        ) : isAdmin ? (
          <EditButton onClick={handleEdit('away')}>
            <FontAwesomeIcon icon={faEdit} />
            {awayShots || 0}
          </EditButton>
        ) : (
          awayShots || 0
        )}
      </p>
    </ShotsRow>
  );
};

export default GameShotsHeading;
