import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import { client } from 'environment/apollo';
import { LoginPage } from 'Components';
import Routes from 'Routes';

require('dotenv').config();

const { REACT_APP_AUTH_COOKIE, REACT_APP_AUTH_TOKEN } = process.env;

const App = () => {
  const authCookie = Cookies.get(REACT_APP_AUTH_COOKIE);

  if (!authCookie || authCookie !== REACT_APP_AUTH_TOKEN) {
    return <LoginPage />;
  }

  return (
    <>
      <Helmet>
        <title>GBH Players Club</title>
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Helmet>
      <Router>
        <ApolloProvider client={client}>
          <Routes />
        </ApolloProvider>
      </Router>
    </>
  );
};

export default App;
