import React from 'react';
import PropTypes from 'prop-types';
import { GoalieGameLogTable, SkaterGameLogTable } from 'Components';

const PlayerGameLog = ({ gamesPlayed, playerId, seasonId, type }) => {
  const showSkaterLog = gamesPlayed.length > 0 && type === 'skater';
  const showGoalieLog = gamesPlayed.length > 0 && type === 'goalie';

  return (
    <section>
      {showSkaterLog > 0 && (
        <SkaterGameLogTable games={gamesPlayed} seasonId={seasonId} />
      )}
      {showGoalieLog > 0 && (
        <GoalieGameLogTable
          games={gamesPlayed}
          playerId={playerId}
          seasonId={seasonId}
        />
      )}
    </section>
  );
};

PlayerGameLog.propTypes = {
  gamesPlayed: PropTypes.array,
  playerId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};
PlayerGameLog.defaultProps = {
  gamesPlayed: [],
};

export default PlayerGameLog;
