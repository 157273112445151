export const processAttendance = ({
  attendance,
  homeTeamId,
  awayTeamId,
  homeGoalie,
  awayGoalie,
}) => {
  const awayTeamPlayers = {};
  const homeTeamPlayers = {};
  attendance.forEach((player) => {
    if (player.teamId === homeTeamId) {
      homeTeamPlayers[player.player.id] = {
        ...player.player,
        teamId: player.teamId,
        goals: 0,
        assists: 0,
        pim: 0,
        isGoalie: player.player.id === homeGoalie,
      };
    }
    if (player.teamId === awayTeamId) {
      awayTeamPlayers[player.player.id] = {
        ...player.player,
        teamId: player.teamId,
        goals: 0,
        assists: 0,
        pim: 0,
        isGoalie: player.player.id === awayGoalie,
      };
    }
  });
  const awayTeamGoalie = Object.values(awayTeamPlayers).filter(
    (player) => player.id === awayGoalie
  );
  const homeTeamGoalie = Object.values(homeTeamPlayers).filter(
    (player) => player.id === homeGoalie
  );
  const awayTeamAttendance = Object.entries(awayTeamPlayers).length;
  const homeTeamAttendance = Object.entries(homeTeamPlayers).length;
  return {
    awayTeamAttendance,
    awayTeamGoalie,
    awayTeamPlayers,
    homeTeamAttendance,
    homeTeamGoalie,
    homeTeamPlayers,
  };
};
