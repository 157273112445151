import React from 'react';
import { useQuery } from '@apollo/client';
import { Loading, ScheduleTable } from 'Components';
import { GET_SCHEDULE_BY_SEASON_ID } from 'services/queries';
import { CURRENT_SEASON } from 'utils';

const SchedulePage = () => {
  const { loading, error, data } = useQuery(GET_SCHEDULE_BY_SEASON_ID, {
    variables: { seasonId: CURRENT_SEASON },
  });

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  const gamesByWeek = {};
  data.schedule.forEach((game) => {
    if (gamesByWeek[game.date]) {
      gamesByWeek[game.date].push(game);
    } else {
      gamesByWeek[game.date] = [game];
    }
  });

  return (
    <>
      <h1>Admin Dashboard</h1>
      <h2>Enter Scores</h2>
      <ScheduleTable
        gamesByWeek={gamesByWeek}
        seasonId={CURRENT_SEASON}
        showEdit
      />
    </>
  );
};

export default SchedulePage;
