import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ColorBlock } from 'Components';
import { design } from 'utils';

const NameBlock = styled.p`
  display: inline-block;
  ${(props) =>
    !props.wrap &&
    `
  white-space: nowrap;
  `}
`;

const TeamName = (props) => {
  const { name, champion, color, colorSecondary, wrap } = props;
  return (
    <NameBlock wrap={wrap}>
      {color && <ColorBlock color={color} colorSecondary={colorSecondary} />}
      {name}
      {champion && ` ${design.trophy}`}
    </NameBlock>
  );
};

TeamName.propTypes = {
  champion: PropTypes.bool,
  color: PropTypes.string,
  colorSecondary: PropTypes.string,
  name: PropTypes.string,
  wrap: PropTypes.string,
};

TeamName.defaultProps = {
  champion: false,
  color: null,
  colorSecondary: null,
  teamName: '',
  wrap: null,
};

export default TeamName;
