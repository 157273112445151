import styled from 'styled-components';
import { design } from 'utils';

export const AddButton = styled.button`
  background-color: ${design.colors.primary};
  border: 1px solid ${design.colors.primary};
  border-radius: ${design.radius[0]};
  color: ${design.colors.white};
  cursor: pointer;
  margin-left: auto;
  padding: ${design.spacing[0.5]} ${design.spacing[1]};
  position: relative;
  z-index: 1;
  &:hover {
    background-color: ${design.colors.secondary};
    border-color: ${design.colors.secondary};
    color: ${design.colors.white};
  }
`;
export const AdminSectionWrapper = styled.div`
  margin-top: ${design.spacing[2]};
`;
export const AttendanceInner = styled.div`
  display: flex;
  flex-direction: column;
`;
export const AttendanceList = styled.ul`
  margin-bottom: auto;
  li {
    padding: ${design.spacing[1]};
    &:nth-child(2n) {
      background-color: ${design.colors.tertiary};
    }
  }
  label {
    display: inline-grid;
    grid-template-columns: 40px 60px 1fr;
    gap: ${design.spacing[1]};
    width: 100%;
    align-items: center;
    span {
      text-align: center;
    }
  }
`;
export const AttendancePlayerName = styled.p`
  display: flex;
  align-items: center;
  > button {
    margin-left: auto;
  }
  span button {
    margin-left: ${design.spacing[0.5]};
  }
`;
export const AttendanceWrap = styled.div`
  display: grid;
  gap: 0 ${design.spacing[8]};
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${design.spacing[4]};
`;
export const ButtonWrap = styled.div`
  text-align: center;
  margin: ${design.spacing[3]} 0 ${design.spacing[1]};
  width: 100%;
  grid-column: 1 / 3;
`;
export const Caption = styled.span`
  text-transform: uppercase;
  color: ${design.colors.gray[7]};
  font-size: ${design.fontSizes[1]};
  margin-left: ${design.spacing[1]};
`;
export const CenteredWrap = styled.div`
  text-align: center;
  margin: ${design.spacing[2]} 0;
`;
export const CheckboxWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const DeleteButton = styled.button`
  background: 0;
  border: 0;
  color: ${design.colors.gray[5]};
  cursor: pointer;
  font-size: ${design.fontSizes[2.5]};
  transition: color 200ms linear;
  &:hover {
    color: ${design.colors.emphasis};
  }
`;
export const ErrorText = styled.p`
  color: ${design.colors.error};
  font-weight: bold;
  font-size: ${design.fontSizes[3]};
`;
export const GoalieButton = styled.button`
  border: 0;
  background: 0;
  cursor: pointer;
  display: block;
  img {
    height: 20px;
    display: block;
  }
  ${(props) =>
    props.removeButton &&
    `
  transition: opacity 200ms linear;
  &:hover {
    opacity: 0.5;
  }
  `}
  ${(props) =>
    props.setButton &&
    `
  img {
    transition: opacity 200ms linear, filter 200ms linear;
    filter: grayscale(1);
    opacity: 0.5;
  }
  &:hover img {
    filter: grayscale(0);
    opacity: 1;
  }
  `}
`;
export const GoalInfoWrap = styled.div`
  align-items: center;
  display: grid;
  gap: ${design.spacing[2]};
  grid-template-columns: 200px 1fr 200px;
  margin: ${design.spacing[2]} auto 0;
  input[type='text'] {
    margin-left: ${design.spacing[1]};
  }
  label {
    text-align: center;
  }
`;
export const GoalPlayersWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${design.spacing[2]};
  margin-bottom: ${design.spacing[1]};
  label,
  select {
    width: 100%;
  }
  label {
    display: flex;
    align-items: center;
  }
`;
export const PenaltyInfoWrap = styled.div`
  align-items: center;
  display: grid;
  gap: ${design.spacing[2]};
  grid-template-columns: 200px 1fr 200px;
  margin: ${design.spacing[2]} auto 0;
  max-width: ${design.breakpoints[4]};
  width: 100%;
  input[type='text'] {
    margin-left: ${design.spacing[1]};
  }
  label {
    text-align: center;
  }
`;
export const PenaltyPlayersWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${design.spacing[2]};
  margin: 0 auto ${design.spacing[1]};
  max-width: ${design.breakpoints[4]};
  width: 100%;
  label,
  select {
    width: 100%;
  }
  label {
    display: flex;
    align-items: center;
  }
`;
export const Req = styled.span`
  color: ${design.colors.emphasis};
`;
export const SuccessText = styled.p`
  color: ${design.colors.gbhpcHome};
  font-weight: bold;
  font-size: ${design.fontSizes[3]};
`;
export const SubHeading = styled.h4`
  background-color: ${design.colors.secondary};
  color: ${design.colors.white};
  padding: ${design.spacing[1]};
  margin-top: ${design.spacing[2]};
  text-align: center;
`;
export const SubList = styled.ul`
  width: 100%;
  border: 1px solid ${design.colors.gray[3]};
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-height: 150px;
  overflow: auto;
  li {
    padding: ${design.spacing[0.5]} ${design.spacing[4]} ${design.spacing[0.5]}
      ${design.spacing[1]};
    display: flex;
    align-items: center;
    &:nth-child(4n),
    &:nth-child(4n-1) {
      background-color: ${design.colors.tertiary};
    }
  }
`;
