import React from 'react';
import PropTypes from 'prop-types';
import { StatsBlockGoalie, StatsBlockSkater } from 'Components';

const StatsBlock = ({
  mergeStats,
  rowLinks,
  seasonId,
  showGoalieAssists,
  showGoaliePim,
  showGoalieTies,
  showJersey,
  showTeam,
  statType,
}) =>
  statType === 'skater' ? (
    <StatsBlockSkater
      mergeStats={mergeStats}
      rowLinks={rowLinks}
      seasonId={seasonId}
      showJersey={showJersey}
      showTeam={showTeam}
    />
  ) : (
    <StatsBlockGoalie
      mergeStats={mergeStats}
      rowLinks={rowLinks}
      seasonId={seasonId}
      showAssists={showGoalieAssists}
      showJersey={showJersey}
      showPim={showGoaliePim}
      showTeam={showTeam}
      showTies={showGoalieTies}
    />
  );

StatsBlock.propTypes = {
  mergeStats: PropTypes.bool,
  rowLinks: PropTypes.object,
  seasonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showGoalieAssists: PropTypes.bool,
  showGoaliePim: PropTypes.bool,
  showGoalieTies: PropTypes.bool,
  showJersey: PropTypes.bool,
  showTeam: PropTypes.bool,
  statType: PropTypes.oneOf(['goalie', 'skater']),
};

StatsBlock.defaultProps = {
  mergeStats: false,
  seasonId: null,
  showGoalieAssists: false,
  showGoaliePim: false,
  showGoalieTies: false,
  showJersey: false,
  showTeam: false,
  statType: 'skater',
};

export default StatsBlock;
