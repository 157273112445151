export const goalieSeasonsHeadCells = [
  { id: 'seasonId', numeric: false, label: 'Season' },
  { id: 'team', numeric: false, label: 'Team' },
  { id: 'gamesPlayed', numeric: true, label: 'GP' },
  { id: 'win', numeric: true, label: 'W' },
  { id: 'loss', numeric: true, label: 'L' },
  { id: 'tie', numeric: true, label: 'T' },
  { id: 'otl', numeric: true, label: 'OTL' },
  { id: 'sol', numeric: true, label: 'SOL' },
  { id: 'gaa', numeric: true, label: 'GAA' },
  { id: 'svp', numeric: true, label: 'SV%' },
  { id: 'shutouts', numeric: true, label: 'SO' },
  { id: 'goalsAgainst', numeric: true, label: 'GA' },
  { id: 'shotsAgainst', numeric: true, label: 'SA' },
  { id: 'saves', numeric: true, label: 'SV' },
  { id: 'assists', numeric: true, label: 'A' },
  { id: 'pim', numeric: true, label: 'PIM' },
  { id: 'league', numeric: false, label: 'League' },
];