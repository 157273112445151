import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PlayerGameLog, PlayerStatSummary, StatChart, Table } from 'Components';
import { ScrollWrapper } from 'styles';
import { design } from 'utils';

const StatWrap = styled.section`
  margin: ${design.spacing[2]} auto;
  width: 80%;
`;

const PlayerPageContent = ({
  careerHeadCells,
  careerStatsBySeason,
  careerTotals,
  gamesPlayed,
  playerId,
  seasonId,
  seasonStats,
  type,
}) => {
  const careerStatsRowLinks = {
    season: {
      page: '/archives/season',
      param: 'seasonId',
    },
  };
  return (
    <>
      {gamesPlayed.length > 0 && (
        <ScrollWrapper>
          <h2>Season Stats</h2>
          <StatWrap>
            <PlayerStatSummary stats={seasonStats} type={type} />
          </StatWrap>
          <PlayerGameLog
            gamesPlayed={gamesPlayed}
            playerId={playerId}
            seasonId={seasonId}
            type={type}
          />
        </ScrollWrapper>
      )}
      <StatWrap>
        <h2>Career Stats</h2>
        {careerTotals.gamesPlayed > 0 && (
          <PlayerStatSummary stats={careerTotals} type={type} />
        )}
      </StatWrap>
      {careerStatsBySeason.length > 0 && (
        <Table
          defaultOrder="desc"
          defaultOrderBy="seasonId"
          headCells={careerHeadCells}
          rows={careerStatsBySeason}
          rowLinks={careerStatsRowLinks}
          size="small"
          tableTitle="Skater Statistics"
        />
      )}
      <StatChart statData={careerStatsBySeason} type={type} />
    </>
  );
};

PlayerPageContent.propTypes = {
  careerHeadCells: PropTypes.array.isRequired,
  careerStatsBySeason: PropTypes.array.isRequired,
  careerTotals: PropTypes.object.isRequired,
  gamesPlayed: PropTypes.array.isRequired,
  playerId: PropTypes.number.isRequired,
  seasonId: PropTypes.number.isRequired,
  seasonStats: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

export default PlayerPageContent;
