import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import chartTrendline from 'chartjs-plugin-trendline';
import { Line } from 'react-chartjs-2';
import { StatChartWrapper, Toggle } from 'styles';
import { design } from 'utils';

ChartJS.register(
  chartTrendline,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StatChartSkater = ({ statData }) => {
  const [perGame, setPerGame] = useState(true);
  const labels = statData.map((stat) => stat.season);
  const goals = statData.map((stat) => stat.goals);
  const assists = statData.map((stat) => stat.assists);
  const points = statData.map((stat) => stat.points);
  const pim = statData.map((stat) => stat.pim);

  const goalsPerGame = statData.map((stat) => stat.goals / stat.gamesPlayed);
  const assistsPerGame = statData.map(
    (stat) => stat.assists / stat.gamesPlayed
  );
  const pointsPerGame = statData.map((stat) => stat.points / stat.gamesPlayed);
  const pimPerGame = statData.map((stat) => stat.pim / stat.gamesPlayed);

  const statsTotal = [
    {
      label: 'Goals',
      backgroundColor: 'rgb(38, 117, 173)',
      borderColor: 'rgb(38, 117, 173)',
      data: goals,
      trendlineLinear: {
        style: 'rgba(38, 117, 173, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Assists',
      backgroundColor: 'rgb(219, 192, 13)',
      borderColor: 'rgb(219, 192, 13)',
      data: assists,
      trendlineLinear: {
        style: 'rgba(219, 192, 13, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Points',
      backgroundColor: design.colors.gbhpcHome,
      borderColor: design.colors.gbhpcHome,
      data: points,
      trendlineLinear: {
        style: 'rgba(59, 146, 18, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'PIM',
      backgroundColor: 'rgb(173, 23, 45)',
      borderColor: 'rgb(173, 23, 45)',
      data: pim,
      trendlineLinear: {
        style: 'rgba(173, 23, 45, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
  ];

  const statsPerGame = [
    {
      label: 'Goals',
      backgroundColor: 'rgb(38, 117, 173)',
      borderColor: 'rgb(38, 117, 173)',
      data: goalsPerGame,
      trendlineLinear: {
        style: 'rgba(38, 117, 173, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Assists',
      backgroundColor: 'rgb(219, 192, 13)',
      borderColor: 'rgb(219, 192, 13)',
      data: assistsPerGame,
      trendlineLinear: {
        style: 'rgba(219, 192, 13, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'Points',
      backgroundColor: design.colors.gbhpcHome,
      borderColor: design.colors.gbhpcHome,
      data: pointsPerGame,
      trendlineLinear: {
        style: 'rgba(59, 146, 18, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
    {
      label: 'PIM',
      backgroundColor: 'rgb(173, 23, 45)',
      borderColor: 'rgb(173, 23, 45)',
      data: pimPerGame,
      trendlineLinear: {
        style: 'rgba(173, 23, 45, .6)',
        lineStyle: 'dotted',
        width: 2,
      },
    },
  ];

  const data = {
    labels,
    datasets: perGame ? statsPerGame : statsTotal,
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: true,
    },
    plugins: {
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const togglePerGame = () => setPerGame(!perGame);
  return (
    <StatChartWrapper>
      <h2>Stats by Season</h2>
      <Toggle>
        <button type="button" onClick={togglePerGame} disabled={perGame}>
          Per Game Averages
        </button>
        <button type="button" onClick={togglePerGame} disabled={!perGame}>
          Stat Totals
        </button>
      </Toggle>
      <Line options={options} data={data} />
    </StatChartWrapper>
  );
};

export default StatChartSkater;
