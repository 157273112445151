import React from 'react';
import PropTypes from 'prop-types';
import { ScheduleBody } from 'Components';
import { StyledTable, ScrollWrapper } from 'styles';

const ScheduleTable = ({ gamesByWeek, seasonId, showEdit }) => {
  return (
    <ScrollWrapper>
      <StyledTable>
        <thead>
          <tr>
            <th>Time</th>
            <th colSpan={2}>Home</th>
            <th></th>
            <th colSpan={2}>Away</th>
            <th></th>
            <th></th>
            <th>Location</th>
            {showEdit && <th></th>}
          </tr>
        </thead>
        {Object.entries(gamesByWeek).map(([date, games]) => (
          <ScheduleBody
            date={date}
            games={games}
            key={date}
            seasonId={seasonId}
            showEdit={showEdit}
          />
        ))}
      </StyledTable>
    </ScrollWrapper>
  );
};

ScheduleTable.propTypes = {
  gamesByWeek: PropTypes.object.isRequired,
  seasonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  showEdit: PropTypes.bool,
};

ScheduleTable.defaultProps = {
  showEdit: false,
};

export default ScheduleTable;
