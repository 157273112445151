export const design = {
  colors: {
    primary: '#006B46',
    secondary: '#005234',
    tertiary: '#ebf2ef',
    white: '#fff',
    black: '#010101',
    gray: {
      1: '#f4f4f4',
      2: '#d3dce0',
      3: '#a2aaad',
      5: '#73787a',
      7: '#434647',
      8: '#2c2e2e',
      9: '#131414',
    },
    gbhpcHome: '#3B9212',
    gbhpcAway: '#010101',
    error: '#d60f0f',
    emphasis: '#d60f0f',
  },
  spacing: {
    0: 0,
    0.25: '.125rem',
    0.5: '.25rem',
    1: '.5rem',
    1.5: '.75rem',
    2: '1rem',
    3: '1.5rem',
    4: '2rem',
    5: '2.5rem',
    6: '3rem',
    7: '3.5rem',
    8: '4rem',
  },
  fontSizes: {
    1: '.875rem',
    2: '1rem',
    2.5: '1.25rem',
    3: '1.5rem',
    4: '2rem',
    5: '2.5rem',
    6: '3rem',
    7: '3.5rem',
    8: '4rem',
  },
  breakpoints: {
    1: '360px',
    2: '480px',
    3: '600px',
    4: '768px',
    5: '992px',
    7: '1024px',
    10: '1280px',
    12: '1440px',
    14: '1600px',
    16: '1920px',
  },
  radius: {
    0: '.25rem',
    1: '.5rem',
  },
  trophy: `🏆`,
  shadow: {
    0: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    1: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    2: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  },
};

// Blue color set:
// colors: {
// primary: '#015cab',
// secondary: '#06345c',
// tertiary: '#c2daef',
// white: '#fff',
// black: '#000',
// },
