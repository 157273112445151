// List of words to _not_ capitalize for title case
const nonCapitalized = [
  'a',
  'after',
  'along',
  'an',
  'and',
  'around',
  'at',
  'but',
  'by',
  'for',
  'from',
  'nor',
  'of',
  'or',
  'on',
  'so',
  'to',
  'the',
  'with',
  'without',
  'yet',
];

// Converts a camelCase string to Title Case, or to ALL CAPS
export const makeTitle = ({ title, allCaps = false, separator = null }) => {
  if (title === null || title === '') {
    return 'None';
  }
  if (typeof title !== 'string') {
    return String(title);
  }
  // Split with a separator if we have one
  let titleCase = title;
  if (separator && titleCase.includes(separator)) {
    titleCase = titleCase.split(separator).join(' ').toLowerCase();
  } else {
    // Split at each capital letter for camelCase strings
    titleCase = titleCase.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  }
  // Capitalize everything if necessary, or just capitalize the first letter
  if (allCaps) {
    return titleCase.toUpperCase();
  } else {
    // Can't do this all inline because we always need to capitalize the last word, and need to know how long the array is
    const titleArr = titleCase.split(' ');
    return titleArr
      .map((word, i) =>
        // Ignore words that aren't actually capitalized in title case, unless they're the first word
        nonCapitalized.includes(word) && i > 0 && i < titleArr.length - 1
          ? word
          : word.replace(/^\w/, (c) => c.toUpperCase())
      )
      .join(' ');
  }
};
