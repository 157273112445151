export const getGoalieSingleSeasonHeadCells = ({
  showAssists = false,
  showJersey = false,
  showPim = false,
  showTeam = false,
  showTies = false,
}) => {
  const goalieHeadCells = [
    { id: 'lastName', numeric: false, label: 'Last Name' },
    { id: 'firstName', numeric: false, label: 'First Name' },
    { id: 'gamesPlayed', numeric: true, label: 'GP' },
    { id: 'wins', numeric: true, label: 'W' },
    { id: 'losses', numeric: true, label: 'L' },
    { id: 'otl', numeric: true, label: 'OTL' },
    { id: 'gaa', numeric: true, label: 'GAA' },
    { id: 'svp', numeric: true, label: 'SV%' },
    { id: 'shutouts', numeric: true, label: 'SO' },
    { id: 'goalsAgainst', numeric: true, label: 'GA' },
    { id: 'shotsAgainst', numeric: true, label: 'SA' },
    { id: 'saves', numeric: true, label: 'SV' },
  ];
  if (showTies) {
    goalieHeadCells.splice(5, 0, { id: 'tie', numeric: true, label: 'T' });
  }
  if (showJersey) {
    goalieHeadCells.splice(2, 0, {
      id: 'jersey',
      numeric: false,
      label: 'No.',
    });
  }
  if (showTeam) {
    goalieHeadCells.splice(2, 0, { id: 'team', numeric: false, label: 'Team' });
  }
  if (showAssists) {
    goalieHeadCells.push({ id: 'assists', numeric: true, label: 'A' });
  }
  if (showPim) {
    goalieHeadCells.push({ id: 'pim', numeric: true, label: 'PIM' });
  }
  return goalieHeadCells;
};
