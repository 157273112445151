import React from 'react';
import { Link } from 'react-router-dom';
import { TeamName } from 'Components';

function createData(
  id,
  seasonId,
  teamId,
  season,
  team,
  gamesPlayed,
  goals,
  assists,
  points,
  pim,
  gpg,
  apg,
  ppg,
  pimpg,
  league
) {
  return {
    id,
    seasonId,
    teamId,
    season,
    team,
    gamesPlayed: Number(gamesPlayed),
    goals: Number(goals),
    assists: Number(assists),
    points: Number(points),
    pim: Number(pim),
    gpg: Number(gpg),
    apg: Number(apg),
    ppg: Number(ppg),
    pimpg: Number(pimpg),
    league,
  };
}

export const createSkaterStatsCareerSeasons = (skaterStats) =>
  skaterStats
    .map((stat) => {
      const {
        id,
        gamesPlayed,
        goals,
        assists,
        pim,
        seasonBySeason: { id: seasonId, year, season, league },
        teamByTeam: { color, colorSecondary, name: team, id: teamId, champion },
      } = stat;

      const teamLink =
        league === 'GBHPC'
          ? `/team/${seasonId}/${teamId}`
          : `/archives/team/${teamId}`;

      return createData(
        id,
        seasonId,
        teamId,
        `${year} ${season}`,
        <Link to={teamLink}>
          <TeamName
            name={team}
            color={color}
            colorSecondary={colorSecondary}
            champion={champion}
          />
        </Link>,
        gamesPlayed,
        goals,
        assists,
        goals + assists,
        pim,
        gamesPlayed ? (goals / gamesPlayed).toFixed(2) : 0,
        gamesPlayed ? (assists / gamesPlayed).toFixed(2) : 0,
        gamesPlayed ? ((goals + assists) / gamesPlayed).toFixed(2) : 0,
        gamesPlayed ? (pim / gamesPlayed).toFixed(2) : 0,
        league
      );
    })
    .sort((a, b) => {
      if (a.seasonId > b.seasonId) {
        return 1;
      }
      if (a.seasonId < b.seasonId) {
        return -1;
      }
      return 0;
    });

export const createSkaterStatsCareerTotals = (skaterStats) =>
  skaterStats.reduce(
    (total, current) => {
      total.gamesPlayed += current.gamesPlayed;
      total.goals += current.goals;
      total.assists += current.assists;
      total.pim += current.pim;
      if (current.teamByTeam.champion && current.gamesPlayed > 3) {
        total.champion.push(1);
      }
      return total;
    },
    { gamesPlayed: 0, goals: 0, assists: 0, pim: 0, champion: [] }
  );
