import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { design } from 'utils';

const sharedStyles = `
  padding: ${design.spacing[1]} ${design.spacing[2]};
  border-radius: ${design.radius[0]};
  border: 2px solid ${design.colors.secondary};
  display: inline-block;
  transition: background-color 0.2s, color 0.2s;
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    &:not(:disabled) {
      cursor: pointer;
    }
  }
  &:disabled {
    border-color: ${design.colors.gray[3]};
  }
`;
const buttonStyles = `
  background-color: ${design.colors.secondary};
  color: ${design.colors.white};
  &:active,
  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: ${design.colors.white};
      color: ${design.colors.secondary};
    }
  }
  &:disabled {
    background-color: ${design.colors.gray[3]};
  }
`;
const invertedButtonStyles = `
  background-color: ${design.colors.white};
  color: ${design.colors.secondary};
  &:active,
  &:focus,
  &:hover {
    &:not(:disabled) {
      background-color: ${design.colors.secondary};
      color: ${design.colors.white};
    }
  }
  &:disabled {
    color: ${design.colors.gray[3]};
  }
`;
const StyledButton = styled.button`
  ${sharedStyles}
  ${(props) => (props.invert ? invertedButtonStyles : buttonStyles)}
`;
const StyledLink = styled.a`
  ${sharedStyles}
  ${(props) => (props.invert ? invertedButtonStyles : buttonStyles)}
`;

export const EmptyButton = styled.button`
  background: 0;
  border: 0;
  cursor: pointer;
`;
export const EditButton = styled(EmptyButton)`
  align-items: flex-start;
  display: flex;
  font-size: inherit;
  justify-content: center;
  margin: auto;
  ${(props) =>
    props.$isWinner &&
    `
    font-weight: bold;
  `}
  svg {
    font-size: ${design.fontSizes[1]};
    margin: ${design.spacing[0.25]} ${design.spacing[0.5]};
    opacity: 0;
    transition: opacity 200ms linear;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

export const Button = ({
  children,
  disabled,
  href,
  invert,
  onClick,
  ...rest
}) => {
  if (href) {
    return (
      <StyledLink
        href={href}
        invert={invert}
        rel="noreferrer noopener"
        target="_blank"
        {...rest}
      >
        {children}
      </StyledLink>
    );
  }
  return (
    <StyledButton
      invert={invert}
      onClick={onClick}
      {...rest}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  invert: PropTypes.bool,
  onClick: PropTypes.func,
};
Button.defaultProps = {
  href: null,
  invert: false,
  onClick() {},
};
