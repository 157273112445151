import React from 'react';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { LoadingPage, PageContainer, Table, TeamName } from 'Components';
import { GET_ARCHIVE_SEASONS } from 'services/queries';
import { makeTitle } from 'utils';

function createData(
  id,
  teamId,
  captainId,
  season,
  league,
  champion,
  champDiv,
  champCapt
) {
  return {
    id,
    teamId,
    captainId,
    season,
    league,
    champion,
    champDiv,
    champCapt,
  };
}

const headCells = [
  { id: 'id', numeric: false, label: 'Season' },
  { id: 'league', numeric: false, label: 'League' },
  { id: 'champion', numeric: false, label: 'Champion' },
  { id: 'champDiv', numeric: false, label: 'Division' },
  { id: 'champCapt', numeric: false, label: 'Captain' },
];

const ArchiveSeasons = () => {
  const { data, error, loading } = useQuery(GET_ARCHIVE_SEASONS);

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const { seasons } = data;

  const statData = seasons.slice(0, seasons.length - 1).map((season) => {
    const { id, league, season: seasonName, teams, year } = season;
    let captainPlayer = '',
      color = '',
      colorSecondary = '',
      division = '',
      name = '',
      teamId;
    if (teams?.length) {
      captainPlayer = teams[0].captainPlayer;
      color = teams[0].color;
      colorSecondary = teams[0].colorSecondary;
      division = teams[0].division;
      name = teams[0].name;
      teamId = teams[0].id;
    }
    return createData(
      id,
      teamId,
      captainPlayer?.id,
      `${year} ${seasonName}`,
      league,
      <TeamName name={name} color={color} colorSecondary={colorSecondary} />,
      division ? makeTitle({ title: division }) : '',
      captainPlayer
        ? `${captainPlayer.firstName} ${captainPlayer.lastName}`
        : ''
    );
  });

  const rowLinks = {
    season: {
      page: '/archives/season',
      param: 'id',
    },
    champion: {
      page: '/archives/team',
      param: 'teamId',
      alt: {
        condition: {
          league: 'GBHPC',
        },
        page: '/team',
        param: ['id', 'teamId'],
      },
    },
    champCapt: {
      page: '/player',
      param: 'captainId',
    },
  };
  const defaultRowsPerPage = 50;

  return (
    <PageContainer pageHeading={<h1>Past Seasons</h1>}>
      <Helmet>
        <title>Archives - Seasons | GBH Players Club</title>
      </Helmet>
      <Table
        defaultOrder="desc"
        defaultOrderBy="id"
        defaultRowsPerPage={defaultRowsPerPage}
        enablePagination={seasons.length >= defaultRowsPerPage}
        headCells={headCells}
        rowLinks={rowLinks}
        rows={statData}
        size="small"
        tableTitle="Past Seasons"
      />
    </PageContainer>
  );
};

export default ArchiveSeasons;
