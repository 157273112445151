import React from 'react';
import { StatsCurrentSeasonGoalie, StatsCurrentSeasonSkater } from 'Components';

const StatsCurrentSeason = ({ statType }) =>
  statType === 'skater' ? (
    <StatsCurrentSeasonSkater />
  ) : (
    <StatsCurrentSeasonGoalie />
  );

export default StatsCurrentSeason;
