import React from 'react';
import { Link } from 'react-router-dom';
import { CenterTd, StyledTable, TableCaption } from 'styles';
import { formatTime } from 'utils';

const SkaterGameLogTable = ({ games, seasonId }) => (
  <>
    <StyledTable solidHeader>
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Team</th>
          <th>Opponent</th>
          <th>Goals</th>
          <th>Assists</th>
          <th>Points</th>
          <th>PIM</th>
        </tr>
      </thead>
      <tbody>
        {games.map(({ teamId, schedule }) => {
          const goals = schedule.goals_aggregate.aggregate.count;
          const primary = schedule.primaryAssists.aggregate.count;
          const secondary = schedule.secondaryAssists.aggregate.count;
          const assists = primary + secondary;
          return (
            <tr key={schedule.id}>
              <td>
                <Link to={`/game/${schedule.id}`}>
                  {schedule.date}
                  {schedule.playoffs ? '*' : ''}
                </Link>
              </td>
              <td>{formatTime(schedule.time)}</td>
              <td>
                {schedule.homeTeam.id === teamId ? (
                  <Link to={`/team/${seasonId}/${schedule.homeTeam.id}`}>
                    {schedule.homeTeam.name}
                  </Link>
                ) : (
                  <Link to={`/team/${seasonId}/${schedule.awayTeam.id}`}>
                    {schedule.awayTeam.name}
                  </Link>
                )}
              </td>
              <td>
                {schedule.homeTeam.id === teamId ? (
                  <Link to={`/team/${seasonId}/${schedule.awayTeam.id}`}>
                    {schedule.awayTeam.name}
                  </Link>
                ) : (
                  <Link to={`/team/${seasonId}/${schedule.homeTeam.id}`}>
                    {schedule.homeTeam.name}
                  </Link>
                )}
              </td>
              <CenterTd>{goals}</CenterTd>
              <CenterTd>{assists}</CenterTd>
              <CenterTd>{goals + assists}</CenterTd>
              <CenterTd>
                {schedule.penalties_aggregate.aggregate.sum.minutes || 0}
              </CenterTd>
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
    <TableCaption>* Playoffs</TableCaption>
  </>
);

export default SkaterGameLogTable;
