import React from 'react';
import { Loading, PageContainer } from 'Components';

const LoadingPage = () => {
  return (
    <PageContainer center vcenter>
      <Loading />
    </PageContainer>
  );
};

export default LoadingPage;
