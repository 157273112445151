import styled from "styled-components";
import { design } from "utils";

export const HeadWrap = styled.div`
  position: relative;
  select {
    margin-top: ${design.spacing[2]};
    @media screen and (min-width: ${design.breakpoints[4]}) {
      height: 100%;
      position: absolute;
      margin: 0;
      right: 0;
      top: 0;
    }
  }
`;

export const HeadWrapGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin-bottom: ${design.spacing[2]};
  gap: ${design.spacing[2]};
  ${props => props.styles}
  h1 {
    order: -1;
    margin: 0;
  }
  div:first-child {
    order: 2;
    margin: auto;
  }
  @media screen and (min-width: ${design.breakpoints[4]}) {
    grid-template-columns: 1fr auto 1fr;
    align-items: stretch;
    h1 {
      align-self: center;
      order: 0;
    }
    select {
      height: 100%;
    }
    div:first-child {
      order: 0;
      margin: 0;
    }
    div:last-child {
      text-align: right;
    }
  }
`;

export const StatChartWrapper = styled.section`
  margin-top: ${design.spacing[4]};
  div:nth-child(2) {
    margin: 0 auto ${design.spacing[2]};
  }
`;
