import React from 'react';
import { Link } from 'react-router-dom';
import { CenterTd, StyledTable, TableCaption } from 'styles';
import { formatTime } from 'utils';

const GoalieGameLogTable = ({ games, playerId, seasonId }) => (
  <>
    <StyledTable solidHeader>
      <thead>
        <tr>
          <th>Date</th>
          <th>Time</th>
          <th>Team</th>
          <th>Opponent</th>
          <th>Result</th>
          <th>GA</th>
          <th>SA</th>
          <th>SV</th>
          <th>SV%</th>
          <th>A</th>
          <th>PIM</th>
        </tr>
      </thead>
      <tbody>
        {games.map(({ teamId, schedule }) => {
          const {
            awayScore,
            awayShots,
            awayTeam,
            date,
            eng,
            homeGoalie,
            homeScore,
            homeShots,
            homeTeam,
            id,
            ot,
            penalties_aggregate,
            playoffs,
            primaryAssists,
            secondaryAssists,
            time,
          } = schedule;
          const isHome = homeGoalie === playerId;
          const currentTeam = isHome ? homeTeam.id : awayTeam.id;
          const homeWinner = homeScore > awayScore;
          const result =
            isHome && homeWinner ? 'W' : !isHome && !homeWinner ? 'W' : 'L';
          let shotsAgainst = isHome ? awayShots : homeShots;
          let goalsAgainst = isHome ? awayScore : homeScore;
          if (ot === 'SO' && result === 'L') {
            goalsAgainst -= 1;
          }
          const emptyNetters = eng.filter(
            (goal) => goal.teamId !== currentTeam
          ).length;
          goalsAgainst -= emptyNetters;
          shotsAgainst -= emptyNetters;
          const primary = primaryAssists.aggregate.count;
          const secondary = secondaryAssists.aggregate.count;
          const assists = primary + secondary;

          let svp = (shotsAgainst - goalsAgainst) / shotsAgainst;
          svp = svp === 1 ? svp.toFixed(3) : svp.toFixed(3).slice(1);
          return (
            <tr key={id}>
              <td>
                <Link to={`/game/${id}`}>
                  {date}
                  {playoffs ? '*' : ''}
                </Link>
              </td>
              <td>{formatTime(time)}</td>
              <td>
                {homeTeam.id === teamId ? (
                  <Link to={`/team/${seasonId}/${homeTeam.id}`}>
                    {homeTeam.name}
                  </Link>
                ) : (
                  <Link to={`/team/${seasonId}/${awayTeam.id}`}>
                    {awayTeam.name}
                  </Link>
                )}
              </td>
              <td>
                {homeTeam.id === teamId ? (
                  <Link to={`/team/${seasonId}/${awayTeam.id}`}>
                    {awayTeam.name}
                  </Link>
                ) : (
                  <Link to={`/team/${seasonId}/${homeTeam.id}`}>
                    {homeTeam.name}
                  </Link>
                )}
              </td>
              <CenterTd>
                {ot}
                {result}
              </CenterTd>
              <CenterTd>{goalsAgainst}</CenterTd>
              <CenterTd>{shotsAgainst}</CenterTd>
              <CenterTd>{shotsAgainst - goalsAgainst}</CenterTd>
              <CenterTd>{svp}</CenterTd>
              <CenterTd>{assists}</CenterTd>
              <CenterTd>
                {penalties_aggregate.aggregate.sum.minutes || 0}
              </CenterTd>
            </tr>
          );
        })}
      </tbody>
    </StyledTable>
    <TableCaption>* Playoffs</TableCaption>
  </>
);

export default GoalieGameLogTable;
