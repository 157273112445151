export const sortPlayersByName = (playerA, playerB) => {
  if (
    playerA.lastName.toLowerCase() >
    playerB.lastName.toLowerCase()
  ) {
    return 1;
  }
  if (
    playerA.lastName.toLowerCase() <
    playerB.lastName.toLowerCase()
  ) {
    return -1;
  }
  if (
    playerA.firstName.toLowerCase() >
    playerB.firstName.toLowerCase()
  ) {
    return 1;
  }
  if (
    playerA.firstName.toLowerCase() <
    playerB.firstName.toLowerCase()
  ) {
    return -1;
  }
  return 0;
};
