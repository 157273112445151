import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Loading, Table, TeamName } from 'Components';
import { GET_STATS } from 'services/queries';
import { getSkaterSingleSeasonHeadCells } from 'tableHeadCells';
import {
  createSeasonStatData,
  createStatTotalData,
  createStatTotalDataWithTeam,
} from 'utils';


export const baseRowLinks = {
  firstName: {
    page: '/player',
    param: 'id',
  },
  lastName: {
    page: '/player',
    param: 'id',
  },
  team: {
    page: '/archives/team',
    param: ['teamId'],
  },
};

const StatsBlockSkater = ({
  rowLinks,
  mergeStats,
  seasonId,
  showJersey,
  showTeam,
}) => {
  const { loading, error, data } = useQuery(GET_STATS, {
    variables: { seasonId },
  });

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  const headCells = getSkaterSingleSeasonHeadCells({
    showJersey,
    showTeam,
  });

  const statData = data.careerStats
    .map((stat) => {
      const {
        id,
        gamesPlayed,
        goals,
        assists,
        pim,
        playerByPlayer: { firstName, lastName, position, id: playerId },
        teamByTeam: {
          name: team,
          id: teamId,
          color,
          colorSecondary,
          abbreviation,
        },
      } = stat;
      const teamNameArr = team.split(' ');
      const teamName =
        teamNameArr[0] === 'Team'
          ? teamNameArr[1].slice(0, 3).toUpperCase()
          : teamNameArr[0].slice(0, 3).toUpperCase();
      const teamAbbreviation = abbreviation ? abbreviation : teamName;
      return createSeasonStatData(
        {
          id,
          playerId,
          seasonId,
          teamId,
        },
        {
          lastName,
          firstName,
          position,
          team: (
            <TeamName
              name={teamAbbreviation}
              color={color}
              colorSecondary={colorSecondary}
              wrap="true"
            />
          ),
          gamesPlayed,
          goals,
          assists,
          points: goals + assists,
          pim,
          gpg: gamesPlayed ? (goals / gamesPlayed).toFixed(2) : 0,
          apg: gamesPlayed ? (assists / gamesPlayed).toFixed(2) : 0,
          ppg: gamesPlayed ? ((goals + assists) / gamesPlayed).toFixed(2) : 0,
          pimpg: gamesPlayed ? (pim / gamesPlayed).toFixed(2) : 0,
        }
      );
    })
    .sort((statA, statB) => statA.playerId - statB.playerId);

  const statsByPlayer = {};
  statData.forEach((stat) => {
    if (statsByPlayer[stat.playerId]) {
      statsByPlayer[stat.playerId].push(stat);
    } else {
      statsByPlayer[stat.playerId] = [stat];
    }
  });
  let statTotals = statData;
  if (mergeStats) {
    statTotals = Object.keys(statsByPlayer).map((player) => {
      const { firstName, lastName, position, team, teamId } =
        statsByPlayer[player][0];
      const totals = statsByPlayer[player].reduce(
        (current, total) => {
          return {
            gamesPlayed: current.gamesPlayed + total.gamesPlayed,
            goals: current.goals + total.goals,
            assists: current.assists + total.assists,
            pim: current.pim + total.pim,
          };
        },
        { gamesPlayed: 0, goals: 0, assists: 0, pim: 0 }
      );
      return {
        playerId: player,
        firstName,
        lastName,
        position,
        seasonId,
        team,
        teamId,
        stats: totals,
      };
    });
  }

  const totalsData = statTotals.map((stat) => {
    const { playerId, teamId, firstName, lastName, position, team, stats } =
      stat;
    let gamesPlayed, goals, assists, pim;
    if (stats) {
      ({ gamesPlayed, goals, assists, pim } = stats);
    } else {
      ({ gamesPlayed, goals, assists, pim } = stat);
    }
    if (showTeam) {
      return createStatTotalDataWithTeam(
        playerId,
        seasonId,
        teamId,
        lastName,
        firstName,
        position,
        team,
        gamesPlayed,
        goals,
        assists,
        goals + assists,
        pim,
        (goals / gamesPlayed).toFixed(2),
        (assists / gamesPlayed).toFixed(2),
        ((goals + assists) / gamesPlayed).toFixed(2),
        (pim / gamesPlayed).toFixed(2)
      );
    }
    return createStatTotalData(
      playerId,
      lastName,
      firstName,
      position,
      gamesPlayed,
      goals,
      assists,
      goals + assists,
      pim,
      (goals / gamesPlayed).toFixed(2),
      (assists / gamesPlayed).toFixed(2),
      ((goals + assists) / gamesPlayed).toFixed(2),
      (pim / gamesPlayed).toFixed(2)
    );
  });

  return (
    <Table
      defaultOrder="desc"
      defaultOrderBy="points"
      enablePagination
      headCells={headCells}
      rowLinks={rowLinks}
      rows={totalsData}
      size="small"
    />
  );
};

StatsBlockSkater.propTypes = {
  mergeStats: PropTypes.bool,
  rowLinks: PropTypes.object,
  seasonId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showJersey: PropTypes.bool,
  showTeam: PropTypes.bool,
};

StatsBlockSkater.defaultProps = {
  mergeStats: false,
  rowLinks: baseRowLinks,
  seasonId: null,
  showJersey: false,
  showTeam: false,
};

export default StatsBlockSkater;
