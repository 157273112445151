import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { AdminAttendanceList, Loading } from 'Components';
import { AttendanceWrap } from 'styles';
import { GET_ADMIN_ATTENDANCE_DATA } from 'services/queries';
import { DELETE_ATTENDANCE, INSERT_ATTENDANCE } from 'services/mutations';
import { sortPlayersByName, sortPlayersByNumberNested } from 'utils';

const AdminAttendance = ({ gameData, setAttendance }) => {
  const [selectedAttendance, setSelectedAttendance] = useState([]);
  const {
    awayTeam: { id: awayTeamId },
    id: gameId,
    homeTeam: { id: homeTeamId },
    seasonId,
  } = gameData;

  const {
    loading,
    error,
    data: attendanceData,
    refetch,
  } = useQuery(GET_ADMIN_ATTENDANCE_DATA, {
    variables: {
      gameId,
      seasonId,
      subSeasons: [seasonId, seasonId - 1, seasonId - 2],
    },
  });

  const [
    insertAttendance,
    { data: setAttendData, loading: setAttendLoading, error: setAttendError },
  ] = useMutation(INSERT_ATTENDANCE);

  const [
    deleteAttendance,
    { loading: delAttendLoading, error: delAttendError },
  ] = useMutation(DELETE_ATTENDANCE);

  useEffect(() => {
    if (attendanceData) {
      setAttendance(attendanceData);
    }
  }, [attendanceData, setAttendance]);

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;
  if (setAttendError || delAttendError) {
    const mutationError = setAttendError ? setAttendError : delAttendError;
    console.log(mutationError);
  }

  const refetchAttendance = () => {
    refetch({ gameId, seasonId });
  };

  const handleRemove = (attendanceId) => (_e) => {
    setSelectedAttendance([]);
    deleteAttendance({ variables: { id: attendanceId } }).then(() =>
      refetchAttendance()
    );
  };

  const handleAddSub = (playerId, teamId) => () => {
    insertAttendance({
      variables: { attendance: [{ gameId, playerId, teamId }] },
    }).then(() => refetchAttendance());
  };

  const homeTeam = attendanceData.players
    .filter(
      (player) =>
        player.rosters[0]?.teamId && player.rosters[0].teamId === homeTeamId
    )
    .sort(sortPlayersByNumberNested);
  const awayTeam = attendanceData.players
    .filter(
      (player) =>
        player.rosters[0]?.teamId && player.rosters[0].teamId === awayTeamId
    )
    .sort(sortPlayersByNumberNested);
  const availableSubs = attendanceData.players.filter(
    (player) =>
      !player.rosters.length ||
      (player.rosters &&
        player.rosters[0]?.teamId &&
        player.rosters[0].teamId !== awayTeamId &&
        player.rosters &&
        player.rosters[0]?.teamId &&
        player.rosters[0].teamId !== homeTeamId)
  );
  const subSkaters = availableSubs.filter(
    (player) =>
      ['FORWARD', 'DEFENSE'].includes(player.position) ||
      ['FORWARD', 'DEFENSE'].includes(player.positionSecondary)
  );
  const subGoalies = availableSubs.filter(
    (player) =>
      player.position === 'GOALIE' || player.positionSecondary === 'GOALIE'
  );

  // Assign subs to home or away teams
  subSkaters.forEach((player) => {
    const skater = attendanceData.attendance.find(
      (sub) => player.id === sub.playerId
    );
    if (skater) {
      const subSkaterData = {
        ...attendanceData.players.find((pl) => pl.id === skater.playerId),
      };
      subSkaterData.sub = true;
      if (skater.teamId === homeTeamId && skater.playerId !== gameData.homeGoalie) {
        homeTeam.push(subSkaterData);
      } else if (skater.teamId === awayTeamId && skater.playerId !== gameData.awayGoalie) {
        awayTeam.push(subSkaterData);
      }
    }
  });

  subGoalies.forEach((goalie) => {
    const goalieSub = attendanceData.attendance.find(
      (player) =>
        goalie.id === player.playerId &&
        !attendanceData.players.find((roster) => roster.playerId === goalie.id)
    );

    if (goalieSub) {
      const { teamId } = goalieSub;
      const subGoalieData = { ...goalie, teamId, sub: true };
      if (teamId === homeTeamId) {
        homeTeam.push(subGoalieData);
      } else if (teamId === awayTeamId) {
        awayTeam.push(subGoalieData);
      }
    }
  });

  // Remaining subs go in a separate list so we can add them to teams without duplicates
  const unassignedSubs = subSkaters
    .filter(
      (sub) => !attendanceData.attendance.find((att) => sub.id === att.playerId)
    )
    .map((att) => att)
    .sort(sortPlayersByName);

  return (
    <AttendanceWrap>
      {homeTeam.length > 0 &&
        awayTeam.length > 0 &&
        [homeTeam, awayTeam].map((roster, i) => {
          const isHome = i === 0;
          const teamId = isHome ? homeTeamId : awayTeamId;
          const goalieId = isHome
            ? gameData.homeGoalie
            : gameData.awayGoalie || null;
          return (
            <AdminAttendanceList
              attendanceData={attendanceData}
              delAttendLoading={delAttendLoading}
              gameId={gameId}
              goalieId={goalieId}
              handleAddSub={handleAddSub}
              handleRemove={handleRemove}
              insertAttendance={insertAttendance}
              isHome={isHome}
              key={teamId}
              refetchAttendance={refetchAttendance}
              roster={roster}
              selectedAttendance={selectedAttendance}
              setAttendData={setAttendData}
              setAttendError={setAttendError}
              setAttendLoading={setAttendLoading}
              setSelectedAttendance={setSelectedAttendance}
              subGoalies={[...subGoalies].sort(sortPlayersByName)}
              teamId={teamId}
              unassignedSubs={unassignedSubs}
            />
          );
        })}
    </AttendanceWrap>
  );
};

export default AdminAttendance;
