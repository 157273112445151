import React from 'react';
import { GoalsBody } from 'Components';
import { StyledTable } from 'styles';

const GoalsTable = ({
  goalsByPeriod,
  awayTeamAbbr,
  awayTeamId,
  homeTeamAbbr,
  homeTeamId,
}) => {
  let periodStartScoreAway = 0;
  let periodStartScoreHome = 0;
  return (
    <StyledTable largeMargins>
      <thead>
        <tr>
          <th>Time</th>
          <th>Score</th>
          <th>Goal</th>
          <th>Assist #1</th>
          <th>Assist #2</th>
        </tr>
      </thead>
      {Object.entries(goalsByPeriod).map(([period, periodGoals]) => {
        if (period > 1 && goalsByPeriod[period - 1]) {
          periodStartScoreAway += goalsByPeriod[period - 1].filter(
            (goal) => goal.teamId === awayTeamId
          ).length;
          periodStartScoreHome += goalsByPeriod[period - 1]?.filter(
            (goal) => goal.teamId === homeTeamId
          ).length;
        }
        return (
          <GoalsBody
            key={period}
            awayTeamAbbr={awayTeamAbbr}
            periodGoals={periodGoals}
            homeTeamAbbr={homeTeamAbbr}
            homeTeamId={homeTeamId}
            period={period}
            periodStartScoreAway={periodStartScoreAway}
            periodStartScoreHome={periodStartScoreHome}
          />
        );
      })}
    </StyledTable>
  );
};

export default GoalsTable;
