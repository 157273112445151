export const sortStandings = (a, b) => {
  if (a.pts > b.pts) {
    return -1;
  }
  if (a.pts < b.pts) {
    return 1;
  }
  if (a.vs[b.teamId] > b.vs[a.teamId]) {
    return -1;
  }
  if (a.vs[b.teamId] < b.vs[a.teamId]) {
    return 1;
  }
  if (a.wins > b.wins) {
    return -1;
  }
  if (a.wins < b.wins) {
    return 1;
  }
  if (a.goalsFor > b.goalsFor) {
    return -1;
  }
  if (a.goalsFor < b.goalsFor) {
    return 1;
  }
  if (a.goalsAgainst < b.goalsAgainst) {
    return -1;
  }
  if (a.goalsAgainst > b.goalsAgainst) {
    return 1;
  }
  return 0;
}