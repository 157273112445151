export const createSkaterStatsSeason = (skaterStats) => skaterStats.reduce(
  (total, current) => {
    total.gamesPlayed += 1;
    total.goals += current.schedule.goals_aggregate.aggregate.count;
    total.assists += current.schedule.primaryAssists.aggregate.count;
    total.assists += current.schedule.secondaryAssists.aggregate.count;
    total.pim +=
      current.schedule.penalties_aggregate.aggregate.sum.minutes || 0;
    return total;
  },
  { gamesPlayed: 0, goals: 0, assists: 0, pim: 0 }
);