import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'styles';

const ArchiveDropdown = () => {
  return (
    <Dropdown right>
      <Link to="/archives">Archives</Link>
      <ul>
        <li>
          <Link to="/archives/stats">Career Stats</Link>
        </li>
        <li>
          <Link to="/archives/seasons">Past Seasons</Link>
        </li>
        <li>
          <Link to="/archives/teams">Historical Teams</Link>
        </li>
      </ul>
    </Dropdown>
  );
};

export default ArchiveDropdown;
