import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Caret } from 'Components';
import { design } from 'utils';

const AccordionHeading = styled.button`
  background-color: ${design.colors.secondary};
  border: 0;
  color: ${design.colors.white};
  cursor: pointer;
  display: block;
  font-size: ${design.fontSizes[2.5]};
  margin: auto;
  padding: 0;
  position: relative;
  text-align: center;
  width: 100%;
  h2 {
    margin: 0;
    padding: ${design.spacing[1.5]};
    svg {
      position: absolute;
      right: ${design.spacing[2]};
      top: 50%;
      transform: translateY(-50%);
      ${(props) =>
        props.isCollapsed &&
        `
        transform: translateY(-50%) rotate(180deg);
      `}
    }
  }
`;

const AccordionContent = styled.div`
  height: auto;
  max-height: 2000px;
  overflow: hidden;
  will-change: height;
  transition: max-height 500ms ease-in-out;
  ${(props) =>
    props.isCollapsed &&
    `
    max-height: 0;
  `}
`;

const Accordion = ({ children, collapsed, title }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (collapsed) {
      setIsCollapsed(true);
    }
  }, []); // eslint-disable-line

  const handleToggleAccordion = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <section>
      <AccordionHeading
        onClick={handleToggleAccordion}
        isCollapsed={isCollapsed}
      >
        <h2>
          {title}
          <Caret height={design.fontSizes[2.5]} width={design.fontSizes[2.5]} />
        </h2>
      </AccordionHeading>
      <AccordionContent isCollapsed={isCollapsed}>{children}</AccordionContent>
    </section>
  );
};

export default Accordion;
