import React from 'react';

const HockeySticks = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 345.822 345.822"
    style={{ enableBackground: 'new 0 0 345.822 345.822' }}
  >
    <path
      d="M314.009,295.324H263.84c-2.263,0-4.381-1.211-5.566-3.225l-47.991-79.486L324.318,19.527
    c0.877-1.485,1.128-3.256,0.698-4.926c-0.43-1.669-1.505-3.1-2.99-3.977l-16.46-9.72c-3.093-1.826-7.078-0.799-8.902,2.292
    l-105.236,178.19l-4.102-6.794c-0.621-1.028-1.515-1.865-2.583-2.416l-12.583-6.496L78.051,5.553
    c-0.874-1.487-2.303-2.565-3.972-2.999c-1.668-0.432-3.441-0.185-4.929,0.69L50.814,14.036c-3.092,1.82-4.125,5.802-2.307,8.896
    l94.196,160.275l0.243,13.878c0.02,1.143,0.341,2.26,0.93,3.239l18.36,30.496l-31.822,53.877
    c-4.849,8.212-13.786,13.314-23.323,13.314h-78.25c-7.516,0-13.63,6.11-13.63,13.62v20.21c0,7.516,6.114,13.63,13.63,13.63h82.67
    c12.528,0,24.267-6.704,30.637-17.494l38.916-65.884l42.587,70.737c4.716,8.014,13.423,12.993,22.722,12.993h67.638
    c9.154,0,16.602-7.448,16.602-16.602v-17.294C330.611,302.771,323.164,295.324,314.009,295.324z M249.976,119.84l-4.246,7.19
    l-4.922-3.691l4.246-7.19L249.976,119.84z M251.705,104.887l4.451-7.537l4.922,3.692l-4.451,7.536L251.705,104.887z M304.552,15.403
    l5.266,3.109l-42.09,71.267l-4.922-3.691L304.552,15.403z M234.156,134.6l4.922,3.691l-36.428,61.681l-3.59-5.946L234.156,134.6z
    M63.006,21.945l7.132-4.197l87.044,148.105l-6.846,4.684L63.006,21.945z M59.611,311.011v21.46h-9v-21.46H59.611z M28.211,331.841
    v-20.21c0-0.342,0.283-0.62,0.63-0.62h8.77v21.46h-8.77C28.494,332.471,28.211,332.188,28.211,331.841z M130.953,321.367
    c-4.043,6.849-11.493,11.104-19.442,11.104h-38.9v-21.46h34.48c14.115,0,27.341-7.55,34.517-19.704l28.249-47.829l3.586,5.956
    L130.953,321.367z M234.821,326.18l-78.908-131.067l-0.219-12.49l9.006-6.162l12.446,6.425l69.96,115.871
    c3.474,5.902,9.885,9.568,16.733,9.568h28.192v24.498h-45.66C241.658,332.822,237.245,330.298,234.821,326.18z M317.611,329.22
    c0,1.986-1.616,3.602-3.602,3.602h-8.978v-24.498h8.978c1.986,0,3.602,1.616,3.602,3.602V329.22z"
    />
  </svg>
);

export default HockeySticks;
