import React from 'react';
import { useQuery } from '@apollo/client';
import { Loading, Table, TeamName } from 'Components';
import { GET_CURRENT_SEASON_STAT_DATA } from 'services/queries';
import { CURRENT_SEASON, createSeasonStatData } from 'utils';
import { getSkaterSingleSeasonHeadCells } from 'tableHeadCells';

const skaterHeadCells = getSkaterSingleSeasonHeadCells({
  showJersey: true,
  showTeam: true,
});

const StatsCurrentSeasonSkater = () => {
  const { loading, error, data } = useQuery(GET_CURRENT_SEASON_STAT_DATA, {
    variables: {
      seasonId: CURRENT_SEASON,
    },
  });

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  // Set up stat data
  const playerData = data.teams
    .map((team) => {
      const {
        id: teamId,
        name: teamName,
        color,
        colorSecondary,
        abbreviation,
        rosters,
      } = team;

      const teamRoster = rosters.reduce(
        (total, current) => [...total, current.players[0]],
        []
      );

      return teamRoster
        .filter((player) => player.position !== 'GOALIE')
        .map((player) => {
          const {
            id,
            firstName,
            lastName,
            jersey,
            jerseySecondary,
            position,
            positionSecondary,
            attendance_aggregate: {
              aggregate: { count: gamesPlayed },
            },
            goals_aggregate: {
              aggregate: { count: goals },
            },
            primaryAssist_aggregate: {
              aggregate: { count: primaryAssists },
            },
            secondaryAssist_aggregate: {
              aggregate: { count: secondaryAssists },
            },
            penalties_aggregate: {
              aggregate: {
                sum: { minutes: pim },
              },
            },
          } = player;

          const positionString = `${position.slice(0, 1)}${
            positionSecondary?.length ? `/${positionSecondary.slice(0, 1)}` : ''
          }`;
          const assists = primaryAssists + secondaryAssists;

          return createSeasonStatData(
            {
              id,
              seasonId: CURRENT_SEASON,
              teamId,
            },
            {
              lastName,
              firstName,
              position: positionString,
              team: (
                <TeamName
                  name={abbreviation || teamName.slice(0, 3).toUpperCase()}
                  color={color}
                  colorSecondary={colorSecondary}
                  wrap="true"
                />
              ),
              jersey: `${jersey}${
                jerseySecondary ? ` / ${jerseySecondary}` : ''
              }`,
              gamesPlayed,
              goals,
              assists,
              points: goals + assists || 0,
              pim: pim || 0,
              gpg: gamesPlayed ? (goals / gamesPlayed).toFixed(2) : 0,
              apg: gamesPlayed ? (assists / gamesPlayed).toFixed(2) : 0,
              ppg: gamesPlayed
                ? ((goals + assists) / gamesPlayed).toFixed(2)
                : 0,
              pimpg: gamesPlayed ? (pim / gamesPlayed).toFixed(2) : 0,
            }
          );
        });
    })
    .flat();

  const playerRowLinks = {
    firstName: {
      page: '/player',
      param: 'id',
    },
    lastName: {
      page: '/player',
      param: 'id',
    },
    team: {
      page: '/team',
      param: ['seasonId', 'teamId'],
    },
  };

  return (
    <Table
      defaultOrder="desc"
      defaultOrderBy="points"
      headCells={skaterHeadCells}
      rowLinks={playerRowLinks}
      rows={playerData}
      size="small"
      tableTitle="Leaders"
    />
  );
};

export default StatsCurrentSeasonSkater;
