import React from 'react';
import PropTypes from 'prop-types';
import { GoalieStatSummary, SkaterStatSummary } from 'Components';

const PlayerStatSummary = ({ stats, type }) => {
  const showSkaterStats = Object.keys(stats).length > 0 && type === 'skater';
  const showGoalieStats = Object.keys(stats).length > 0 && type === 'goalie';
  return (
    <section>
      {showSkaterStats && (
        <SkaterStatSummary stats={stats} />
      )}
      {showGoalieStats > 0 && (
        <GoalieStatSummary stats={stats} />
      )}
    </section>
  );
};

PlayerStatSummary.propTypes = {
  stats: PropTypes.object,
  type: PropTypes.string.isRequired
}
PlayerStatSummary.defaultProps = {
  stats: {},
}

export default PlayerStatSummary;
