import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { RowLink } from 'Components';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TableBody = (props) => {
  const {
    enableSelection,
    order,
    orderBy,
    page,
    rowLinks,
    rows,
    rowsPerPage,
    selected,
    setSelected,
  } = props;

  const handleClick = (_event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <MuiTableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <MuiTableRow
              aria-checked={isItemSelected}
              hover
              key={`${row.id}${row.teamId ? row.teamId : ''}`}
              onClick={enableSelection ? (e) => handleClick(e, row.id) : null}
              role={enableSelection ? 'checkbox' : null}
              selected={isItemSelected}
              tabIndex={-1}
            >
              {enableSelection && (
                <MuiTableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </MuiTableCell>
              )}
              {Object.entries(row).map(([key, value]) => {
                const rowKey = `${key}-${row.id}`;

                const perGame = key.slice(-2) === 'pg';
                const numeric =
                  typeof value === 'number' ||
                  perGame ||
                  ['gaa', 'svp'].includes(key);
                const isId = key === 'id' || key.slice(-2) === 'Id';
                if (!isId) {
                  let rowLink = rowLinks[key];
                  const alt = rowLinks[key]?.alt;
                  if (alt) {
                    const [condition, term] = Object.entries(alt.condition)[0];
                    if(row[condition] === term) {
                      rowLink = alt;
                    }
                  }
                  return (
                    <MuiTableCell
                      key={rowKey}
                      align={numeric ? 'right' : 'left'}
                    >
                      <RowLink
                        rowLink={rowLink}
                        rowData={row}
                        linkText={perGame ? Number(value).toFixed(2) : value}
                      />
                    </MuiTableCell>
                  );
                }
                return <Fragment key={rowKey} />;
              })}
            </MuiTableRow>
          );
        })}
    </MuiTableBody>
  );
};

TableBody.propTypes = {
  enableSelection: PropTypes.bool,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  rowLink: PropTypes.object,
  rows: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
};

TableBody.defaultProps = {
  enableSelection: false,
  rowLink: {},
  selected: [],
  setSelected() {},
};

export default TableBody;
