import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { LoadingPage, TeamPage } from 'Components';
import { GET_TEAM_INFO } from 'services/queries';
import { createTeamSkaterData, createTeamGoalieData } from 'utils';
import {
  getGoalieSingleSeasonHeadCells,
  getSkaterSingleSeasonHeadCells,
} from 'tableHeadCells';

const TeamPageContainer = () => {
  let { teamId, seasonId } = useParams();
  teamId = Number(teamId);
  seasonId = Number(seasonId);

  const { loading, error, data } = useQuery(GET_TEAM_INFO, {
    variables: { teamId, seasonId },
  });
  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const {
    rosters,
    seasonBySeason: { schedules },
  } = data.teams[0];

  const roster = rosters.reduce(
    (total, current) => [...total, current.players[0]],
    []
  );

  const schedule = schedules.length
    ? schedules
        .filter((game) => game.home === teamId || game.away === teamId)
        .sort((a, b) => a.id - b.id)
    : [];

  const skaterData = createTeamSkaterData({ roster, schedule, teamId });
  const goalieData = createTeamGoalieData({ roster, schedule, teamId });

  const rowLinks = {
    firstName: {
      page: '/player',
      param: 'id',
    },
    lastName: {
      page: '/player',
      param: 'id',
    },
  };

  return (
    <TeamPage
      goalieData={goalieData}
      goalieHeadCells={getGoalieSingleSeasonHeadCells({
        showAssists: true,
        showJersey: true,
        showPim: true,
      })}
      goalieRowLinks={rowLinks}
      schedule={schedule}
      seasonId={seasonId}
      skaterData={skaterData}
      skaterHeadCells={getSkaterSingleSeasonHeadCells({
        showJersey: true,
      })}
      skaterRowLinks={rowLinks}
      team={data.teams[0]}
    />
  );
};

export default TeamPageContainer;
