import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AdminAttendance,
  AdminPenalties,
  AdminScore,
  GameHeading,
  GameSummary,
  Loading,
} from 'Components';
import { AdminGameContext } from 'services/context/AdminGameContext';
import { GET_SINGLE_GAME_INFO } from 'services/queries';

const AdminGame = () => {
  const [attendance, setAttendance] = useState();
  const { id } = useParams();
  const {
    loading,
    error,
    data,
    refetch: refetchGameData,
  } = useQuery(GET_SINGLE_GAME_INFO, {
    variables: { id },
  });

  if (loading) return <Loading />;
  if (error) return `error: ${error.message}`;

  const gameData = data.schedule[0];

  return (
    <AdminGameContext.Provider
      value={{
        attendance,
        gameData,
        gameId: id,
        isAdmin: true,
        refetchGameData,
      }}
    >
      <GameHeading
        gameData={gameData}
        isAdmin
        refetchGameData={refetchGameData}
      />
      <Accordion title="Attendance">
        <AdminAttendance gameData={gameData} setAttendance={setAttendance} />
      </Accordion>
      <Accordion title="Scoring">
        <AdminScore gameData={gameData} />
      </Accordion>
      <Accordion title="Penalties">
        <AdminPenalties
          attendance={attendance}
          gameId={id}
          gameData={gameData}
          refetchGameData={refetchGameData}
        />
      </Accordion>
      <Accordion title="Game Summary">
        <GameSummary
          gameData={gameData}
          isAdmin
          refetchGameData={refetchGameData}
        />
      </Accordion>
    </AdminGameContext.Provider>
  );
};

export default AdminGame;
