import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_ROSTERS, INSERT_ROSTERS } from 'services/mutations';
import styled from 'styled-components';
import { Button, Option, Select } from 'styles';
import { design } from 'utils';
import SeasonSelect from 'Components/Base/SeasonSelect';

const PlayerListWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px 1fr;
  gap: ${design.spacing[2]};
  select:last-child {
    height: 50vh;
    font-size: ${design.fontSizes[2]};
    width: 100%;
  }
  option {
    padding: ${design.spacing[0.5]};
    &:nth-child(2n + 1) {
      background-color: ${design.colors.tertiary};
    }
  }
  button {
    width: 100%;
    padding: ;
  }
`;
const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  button {
    margin-block: ${design.spacing[2]};
  }
`;
const SelectWrap = styled.div`
  text-align: center;
  select:first-child {
    margin-bottom: ${design.spacing[4]};
  }
`;

const AdminRosters = ({
  handleSelectSeason,
  players,
  refetchRosterData,
  seasonId,
  teams,
}) => {
  const [playersToAdd, setPlayersToAdd] = useState([]);
  const [playersToRemove, setPlayersToRemove] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(0);
  const [selectedTeamPlayers, setSelectedTeamPlayers] = useState([]);

  const [insertRosters] = useMutation(INSERT_ROSTERS);
  const [deleteRosters] = useMutation(DELETE_ROSTERS);

  useEffect(() => {
    if (selectedTeam === 0 && teams.length) {
      setSelectedTeam(teams[0].id);
    }
  }, [selectedTeam, teams]);

  useEffect(() => {
    if (selectedTeam !== 0 && teams.length) {
      const currentTeamPlayers = players.filter(
        (player) => player.rosters[0]?.teamId === selectedTeam
      );
      setSelectedTeamPlayers(currentTeamPlayers);
    }
  }, [players, selectedTeam, teams]);

  const availablePlayers = players.filter(
    (player) => player.rosters.length === 0
  );

  const handleChangeTeam = (e) => {
    setSelectedTeam(Number(e.target.value));
  };

  const handlePlayersToAdd = (e) => {
    const playerIds = [...e.target.selectedOptions].map((option) =>
      Number(option.value)
    );
    setPlayersToAdd(playerIds);
  };
  const handlePlayersToRemove = (e) => {
    const playerIdsToRemove = [...e.target.selectedOptions].map((option) =>
      Number(option.value)
    );
    setPlayersToRemove(playerIdsToRemove);
  };

  const handleInsertRosters = () => {
    const rosterPlayers = playersToAdd.map((playerId) => ({
      playerId,
      teamId: selectedTeam,
    }));
    insertRosters({ variables: { players: rosterPlayers } }).then(() => {
      refetchRosterData();
    });
  };
  const handleRemoveRosters = () => {
    deleteRosters({
      variables: { playerIds: playersToRemove, teamId: selectedTeam },
    }).then(() => {
      refetchRosterData();
    });
  };

  return (
    <div>
      <form>
        <PlayerListWrap>
          <SelectWrap>
            <SeasonSelect
              league="GBHPC"
              onChange={handleSelectSeason}
              value={seasonId}
            />
            <select multiple onChange={handlePlayersToAdd}>
              {availablePlayers.map((player) => (
                <option value={player.id} key={player.id}>
                  {player.firstName} {player.lastName}
                  {player.position === 'GOALIE' ||
                  player.positionSecondary === 'GOALIE'
                    ? ' (G)'
                    : ''}
                </option>
              ))}
            </select>
          </SelectWrap>
          <ButtonWrap>
            <Button type="button" onClick={handleInsertRosters}>
              Add →
            </Button>
            <Button type="button" invert onClick={handleRemoveRosters}>
              ← Remove
            </Button>
          </ButtonWrap>
          <SelectWrap>
            <Select
              id="teamId"
              defaultValue={selectedTeam}
              data-int
              onChange={handleChangeTeam}
              required
            >
              {teams.map((team) => (
                <Option value={Number(team.id)} key={team.id}>
                  {team.name} (
                  {
                    [...players].filter(
                      (player) => player.rosters[0]?.teamId === team.id
                    ).length
                  }
                  )
                </Option>
              ))}
            </Select>

            <select multiple onChange={handlePlayersToRemove}>
              {selectedTeamPlayers.map((player) => (
                <option value={player.id} key={player.id}>
                  {player.firstName} {player.lastName}
                  {player.position === 'GOALIE' ||
                  player.positionSecondary === 'GOALIE'
                    ? ' (G)'
                    : ''}
                </option>
              ))}
            </select>
          </SelectWrap>
        </PlayerListWrap>
      </form>
    </div>
  );
};

export default AdminRosters;
