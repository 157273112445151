import React from 'react';
import { useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  LoadingPage,
  PageContainer,
  ScheduleTable,
  SeasonSelect,
} from 'Components';
import { HeadWrap } from 'styles';
import { GET_SCHEDULE_BY_SEASON_ID } from 'services/queries';

const SchedulePage = () => {
  const history = useHistory();
  const { id } = useParams();
  const seasonId = Number(id);

  const { loading, error, data } = useQuery(GET_SCHEDULE_BY_SEASON_ID, {
    variables: { seasonId },
  });

  if (loading) return <LoadingPage />;
  if (error) return `error: ${error.message}`;

  const gamesByWeek = {};
  data.schedule.forEach((game) => {
    if (gamesByWeek[game.date]) {
      gamesByWeek[game.date].push(game);
    } else {
      gamesByWeek[game.date] = [game];
    }
  });

  const handleChangeSeason = (e) => {
    history.push(`/schedule/${e.target.value}`);
  };

  return (
    <PageContainer
      pageHeading={
        <HeadWrap>
          <h1>Schedule</h1>
          <SeasonSelect
            league="GBHPC"
            onChange={handleChangeSeason}
            value={seasonId}
          />
        </HeadWrap>
      }
    >
      <Helmet>
        <title>Schedule | GBH Players Club</title>
      </Helmet>
      <ScheduleTable seasonId={seasonId} gamesByWeek={gamesByWeek} />
    </PageContainer>
  );
};

export default SchedulePage;
