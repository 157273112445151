import { createSeasonStatData } from 'utils';
export const createTeamGoalieData = ({ roster, schedule, teamId }) =>
  roster
    .map((player) => {
      const {
        id: playerId,
        firstName,
        lastName,
        jersey,
        jerseySecondary,
      } = player;

      let gamesPlayed = 0;
      let wins = 0;
      let losses = 0;
      let otl = 0;
      let goalsAgainst = 0;
      let shotsAgainst = 0;
      let shutouts = 0;
      let assists = 0;
      let pim = 0;

      schedule.forEach((game) => {
        const {
          attendance,
          awayGoalie,
          awayScore,
          awayShots,
          goals,
          home,
          homeGoalie,
          homeScore,
          homeShots,
          ot,
          penalties,
        } = game;
        const isHome = teamId === home;
        const isGoalie = isHome
          ? homeGoalie === playerId
          : awayGoalie === playerId;
        const gamePlayed = attendance.find(
          (att) => att.teamId === teamId && att.playerId === playerId
        );
        if (!isGoalie || !gamePlayed) {
          return;
        }
        gamesPlayed += 1;

        const isWinner = isHome ? homeScore > awayScore : awayScore > homeScore;
        wins += isWinner ? 1 : 0;
        losses += !isWinner && !ot ? 1 : 0;
        otl += !isWinner && ot ? 1 : 0;

        const goalsFor = goals.filter((g) => g.teamId === teamId);
        const ga = goals.filter((g) => g.teamId !== teamId && !g.en);

        goalsAgainst += ga.length;
        shotsAgainst += isHome ? awayShots : homeShots;

        goalsFor.forEach((goal) => {
          if ([goal.primaryAssist, goal.secondaryAssist].includes(playerId)) {
            assists += 1;
          }
        });
        penalties.forEach((pen) => {
          if (pen.playerId === playerId) {
            pim += pen.minutes;
          }
        });
      });

      const saves = shotsAgainst - goalsAgainst;
      const gaa = goalsAgainst / gamesPlayed;
      let svp = saves / shotsAgainst;
      svp = svp === 1 ? svp.toFixed(3) : svp.toFixed(3).slice(1)

      return createSeasonStatData(
        { id: playerId },
        {
          lastName,
          firstName,
          jersey: jersey
            ? `${jersey}${jerseySecondary ? ` / ${jerseySecondary}` : ''}`
            : null,
          gamesPlayed,
          wins,
          losses,
          otl,
          gaa: gaa.toFixed(2),
          svp,
          shutouts,
          goalsAgainst,
          shotsAgainst,
          saves,
          assists,
          pim,
        }
      );
    })
    .filter((entry) => entry.gamesPlayed > 0);
