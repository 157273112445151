import React from 'react';
import { AdminGoals, GoalsTable } from 'Components';

const AdminScore = ({ gameData }) => {
  const {
    awayTeam: { id: awayTeamId, abbreviation: awayTeamAbbr },
    goals,
    homeTeam: { id: homeTeamId, abbreviation: homeTeamAbbr },
  } = gameData;

  const goalsByPeriod = {};

  goals.forEach((goal) => {
    if (Object.keys(goalsByPeriod).includes(String(goal.period))) {
      goalsByPeriod[goal.period].push(goal);
    } else {
      goalsByPeriod[goal.period] = [goal];
    }
  });
  return (
    <>
      <AdminGoals />
      <GoalsTable
        awayTeamAbbr={awayTeamAbbr}
        awayTeamId={awayTeamId}
        goalsByPeriod={goalsByPeriod}
        homeTeamAbbr={homeTeamAbbr}
        homeTeamId={homeTeamId}
      />
    </>
  );
};

export default AdminScore;
